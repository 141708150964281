import { styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';

export const LoadingPageContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  height: 100vh !important;
  background: ${STATIC_GRADIENTS.primaryGradient};

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  @media (min-width: ${SCREEN_SIZE.vhApp}) {
    width: 500px;
    margin: auto;
    height: 86vh !important;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 311px;
  color: ${STATIC_COLORS.base.whiteGray};

  & > * {
    text-align: center;
  }
`;
