import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { actionUpdateUserData, actionUploadUserPicture } from '@lib/core/users/slices/user';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { ToastMessageTypes } from '@lib/tools/toastMessage/utils/consts';

import { IToastType } from '@components/web/src/atoms/Toast/ToastMessage';

export interface IToastMessage {
  title?: ILocaleText;
  message: ILocaleText;
  type: IToastType;
}

export interface IToastMessageSlice {
  toastMessage: IToastMessage | null;
}

const initialState: IToastMessageSlice = {
  toastMessage: null,
};

const { ToastMessages } = localeCommon;

export const toastMessageSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionUploadUserPicture.fulfilled, state => {
      state.toastMessage = {
        message: ToastMessages.profilePictureSuccess,
        type: ToastMessageTypes.success,
      };
    });
    builder.addCase(actionUploadUserPicture.rejected, state => {
      state.toastMessage = {
        message: ToastMessages.profilePictureFailed,
        type: ToastMessageTypes.error,
      };
    });
    builder.addCase(actionUpdateUserData.fulfilled, state => {
      state.toastMessage = {
        message: ToastMessages.userDataSuccess,
        type: ToastMessageTypes.success,
      };
    });
    builder.addCase(actionUpdateUserData.rejected, state => {
      state.toastMessage = {
        message: ToastMessages.userDataFailed,
        type: ToastMessageTypes.error,
      };
    });
  },
  initialState,
  name: 'toastMessage',
  reducers: {
    actionAddToastMessage: (state, action: PayloadAction<IToastMessage>) => {
      state.toastMessage = action.payload;
    },
    actionClearToastMessage: state => {
      state.toastMessage = null;
    },
  },
});

export const { actionClearToastMessage, actionAddToastMessage } = toastMessageSlice.actions;

export default toastMessageSlice.reducer;
