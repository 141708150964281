import { FC } from 'react';

import { STATIC_COLORS } from '@components/web/src/foundations';
import * as S from '@components/web/src/organisms/CharacterScroller/styles';

export type TCharacterScrollerItem = {
  identifier: string;
  image: string;
  name: string;
  productCategory: string;
};

interface IProps {
  character: TCharacterScrollerItem;
  isActive: boolean;
}

export const CharacterScrollerItem: FC<IProps> = ({ character, isActive }) => {
  const { image, productCategory } = character;

  return (
    <S.CharacterScrollerItemContainer $isActive={isActive} data-testid="CharacterScrollerItem">
      <img alt="icon" src={image} />
      <S.BackgroundIcon $backgroundColor={STATIC_COLORS.productColors[productCategory][50]} $isActive={isActive} />
    </S.CharacterScrollerItemContainer>
  );
};
