import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const SelectionContainer = styled.label<{ $isInvalid: boolean }>`
  width: 100%;
  margin: 0;
  border-color: ${STATIC_COLORS.gray[300]};
  ${({ $isInvalid }) =>
    $isInvalid &&
    `border-color: ${STATIC_COLORS.base.red};
  `}
`;

export const TitleText = styled(Text)`
  display: block;
  margin-bottom: 6px;
`;

export const InfoText = styled.div<{ $isError: boolean }>`
  display: none;
  min-height: 24px;
  margin-top: 6px;
  ${({ $isError }) =>
    $isError &&
    `display: block;
    color: ${STATIC_COLORS.base.red};
  `}
`;
