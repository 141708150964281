import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';

import FooterImageLink from '@components/web/src/assets/legacy/b2c/png/footer_image.png';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  isModalOpen: boolean;
  hideModal: () => void;
  shouldOpenModalsUnderHeader?: boolean;
};

const CookiePolicyModal: FC<Props> = ({ shouldOpenModalsUnderHeader, isModalOpen, hideModal }) => {
  const { cookiePolicyModal } = localeApp;

  const {
    heading,
    whatTimeOfCookieText,
    whatTimeOfCookieDescription,
    purposeCookieText,
    purposeCookieDescription,
    vinhoodUsedCookiesTitle,
    technicalCookiesTitle,
    technicalCookiesDescription,
    cookieAnalyticsTitle,
    cookieAnalyticsDescription,
    amendmentsDescription,
    amendmentsTitle,
    profilingCookiesText,
    disableCookieInfoText,
    cookieAnalyticsText,
    serviceNameText,
    mixpanelText,
    purposeTitleText,
    durationText,
    cookiePurposeDescriptionText,
    cookieDurationText,
    profilingCookiesTitleText,
    profilingCookiePurposeText,
    profilingDurationText,
  } = cookiePolicyModal;

  return (
    <ModalWrapper
      fullWidthModal
      hideModal={hideModal}
      isOpen={isModalOpen}
      modalClassName={`${shouldOpenModalsUnderHeader ? 'under-header' : ''}`}
    >
      <div className="cookie-policy-modal-container">
        <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={heading} weight="semibold" />
        <Text color={STATIC_COLORS.base.black} size="body2" text={cookiePolicyModal.description} />

        <div className="cookie-content-body ">
          <Text
            color={STATIC_COLORS.base.black}
            size="subtitle2"
            text={cookiePolicyModal.whatAreCookiesText}
            weight="medium"
          />
          <Text color={STATIC_COLORS.base.black} size="body2" text={cookiePolicyModal.whatAreCookiesDescription} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={cookiePolicyModal.whoManageCookieText} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={cookiePolicyModal.whoManageCookieDescription} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={whatTimeOfCookieText} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={whatTimeOfCookieDescription} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={purposeCookieText} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={purposeCookieDescription} />
        </div>

        <div className="cookie-content-body ">
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={vinhoodUsedCookiesTitle} weight="medium" />
          <Text color={STATIC_COLORS.base.black} size="body2" text={technicalCookiesTitle} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={technicalCookiesDescription} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={cookieAnalyticsTitle} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={cookieAnalyticsDescription} />

          <div className="table-format">
            <div className="table-title gray-background bordered mt-12px">
              <Text color={STATIC_COLORS.base.black} size="subtitle2" text={cookieAnalyticsText} weight="medium" />
            </div>
            <div className="table-service-name gray-background bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={serviceNameText} />
            </div>
            <div className="table-service-detail bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={mixpanelText} />
            </div>
            <div className="table-purpose gray-background bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={purposeTitleText} />
            </div>
            <div className="table-purpose-detail bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={cookiePurposeDescriptionText} />
            </div>
            <div className="table-duration gray-background bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={durationText} />
            </div>
            <div className="table-duration-detail bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={cookieDurationText} />
            </div>
          </div>

          <Text color={STATIC_COLORS.base.black} size="body2" text={profilingCookiesText} />

          <img alt="cookie-footer-sc" className="footer-screenshot-image my-12px" src={FooterImageLink} />
          <Text color={STATIC_COLORS.base.black} size="body2" text={disableCookieInfoText} />

          <div className=" table-format mt-12px">
            <div className="table-title gray-background bordered">
              <Text
                color={STATIC_COLORS.base.black}
                size="subtitle2"
                text={profilingCookiesTitleText}
                weight="medium"
              />
            </div>
            <div className="table-service-name gray-background bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={serviceNameText} />
            </div>
            <div className="table-service-detail bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={mixpanelText} />
            </div>
            <div className="table-purpose gray-background bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={purposeTitleText} />
            </div>
            <div className="table-purpose-detail bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={profilingCookiePurposeText} />
            </div>
            <div className="table-duration gray-background bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={durationText} />
            </div>
            <div className="table-duration-detail bordered">
              <Text color={STATIC_COLORS.base.black} size="body2" text={profilingDurationText} />
            </div>
          </div>
        </div>
        <div className="cookie-content-body ">
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={amendmentsTitle} weight="medium" />
          <Text color={STATIC_COLORS.base.black} size="body2" text={amendmentsDescription} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CookiePolicyModal;
