import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import * as S from '@components/web/src/atoms/Headers/HeaderWidget/HeaderDropDownMenu/styles';
import { Text } from '@components/web/src/foundations/Text/Text';

export interface IMenuOptions {
  icon: JSX.Element;
  text: ILocaleText;
  localeOptions?: Record<string, string>;
  localeIndex?: Record<string, string>;
  onClick: (arg?: string) => void;
}

interface IProps {
  isMenuOpened: boolean;
  menuOptions: IMenuOptions[];
  producerName: string;
}

export const HeaderDropDownMenu: FC<IProps> = ({ isMenuOpened, menuOptions }) => {
  return (
    <>
      <S.DropdownMenuContainer data-testid="HeaderDropDownMenu" direction="column">
        {menuOptions.map(({ onClick, icon, text, localeIndex, localeOptions }, index) => (
          <S.MenuItem key={index} align="center" justify="flex-start" onClick={() => onClick()}>
            <S.MenuIcon align="center" justify="center">
              {icon}
            </S.MenuIcon>
            <Text localeIndex={localeIndex} localeOptions={localeOptions} size="body1" text={text} />
          </S.MenuItem>
        ))}
      </S.DropdownMenuContainer>
      {isMenuOpened && <S.BackgroundOverlay data-testid="BackgroundOverlay" />}
    </>
  );
};
