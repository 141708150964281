export const ExperienceCardSkeleton = () => (
  <div className="vh-experience-card-skeleton">
    <div className="image" />
    <div className="feedback-bar" />
    <div className="texts-wrapper">
      <div className="section-one" />
      <div className="section-two" />
      <div className="section-three" />
      <div className="section-four" />
      <div className="section-five" />
    </div>
    <div className="contact-wrapper">
      <div className="contact" />
    </div>
    <div className="footer-wrapper">
      <div className="section-one" />
      <div className="section-two" />
      <div className="section-three" />
    </div>
  </div>
);
