import { FC } from 'react';

import { TJournalProductPanelFilter, TProductCategory } from '@lib/core/products/types';
import {
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  JOURNAL_PANEL_LOCATION_FILTER,
  JOURNAL_PANEL_PLACES_FILTER,
  JOURNAL_PLACES_VARIANT,
  JOURNAL_PRODUCT_VARIANT,
  TJournalPlacesPanelFilter,
  TJournalVariant,
} from '@lib/tools/shared/helpers/consts';

import { ReactComponent as TabIcon } from '@components/web/src/assets/svg/journal_tab.svg';
import {
  JournalPageBookmarkButton,
  JournalPageRateButton,
} from '@components/web/src/templates/TasteId/TasteIdJournalPage/styles';
import * as S from '@components/web/src/templates/TasteId/TasteIdJournalPage/styles';

interface IProps {
  variant: TJournalVariant;
  productCategory: TProductCategory;
  activeJournalPanelFilter: TJournalProductPanelFilter | TJournalPlacesPanelFilter;
  handlePanelFilterChange: (value: TJournalProductPanelFilter | TJournalPlacesPanelFilter) => void;
}

export const JournalPanel: FC<IProps> = ({
  variant,
  activeJournalPanelFilter,
  productCategory,
  handlePanelFilterChange,
}) => {
  const isPlacesVariant = variant === JOURNAL_PLACES_VARIANT;
  const isProductVariant = variant === JOURNAL_PRODUCT_VARIANT;

  const buttonConfigs = [
    {
      Component: JournalPageBookmarkButton,
      isActive:
        activeJournalPanelFilter === FILTER_TYPE_SHOW_FAVORITES ||
        activeJournalPanelFilter === JOURNAL_PANEL_PLACES_FILTER,
      value: (isPlacesVariant ? JOURNAL_PLACES_VARIANT : FILTER_TYPE_SHOW_FAVORITES) as TJournalPlacesPanelFilter,
    },
    {
      Component: JournalPageRateButton,
      isActive:
        activeJournalPanelFilter === FILTER_TYPE_RATING || activeJournalPanelFilter === JOURNAL_PANEL_LOCATION_FILTER,
      value: (isProductVariant ? FILTER_TYPE_RATING : JOURNAL_PANEL_LOCATION_FILTER) as TJournalProductPanelFilter,
    },
  ];

  return (
    <S.JournalPageLeftPanel $isPlacesVariant={isPlacesVariant} data-testid={`JournalPanel-${variant}`}>
      <S.JournalPageLeftPanelButtonsWrapper>
        {buttonConfigs.map(({ value, isActive, Component }, index) => (
          <S.JournalPageLeftPanelButton
            key={index}
            $isActive={isActive}
            $productCategory={productCategory}
            onClick={() => handlePanelFilterChange(value)}
          >
            <Component $isActive={isActive} $productCategory={productCategory} />
            <TabIcon />
          </S.JournalPageLeftPanelButton>
        ))}
      </S.JournalPageLeftPanelButtonsWrapper>
    </S.JournalPageLeftPanel>
  );
};
