import { Dispatch, FC, SetStateAction, useState } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import TestAnswerAnimation from '@components/web/src/atoms/TestAnswer/TestAnswerAnimation/TestAnswerAnimation';
import * as S from '@components/web/src/atoms/TestAnswer/TestAnswerItem/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';

type Props = {
  id: string;
  image: string;
  text: ILocaleText;
  customTextColor?: string;
  isApplicationKiosk?: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  isFoodHabitsQuiz?: boolean;
  isDevToolsEnabled: boolean;
  isUserQuizLoading?: boolean;
  onSelect: (id: string) => void;
  setIsDisableQuiz?: Dispatch<SetStateAction<boolean>>;
};

const TestAnswer: FC<Props> = ({
  id,
  image,
  text,
  customTextColor,
  isApplicationKiosk,
  isSelected,
  isDisabled,
  isFoodHabitsQuiz,
  isDevToolsEnabled,
  isUserQuizLoading = false,
  setIsDisableQuiz,
  onSelect,
}) => {
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const handleClick = () => {
    setIsAnswerSelected(!isAnswerSelected);
    if (!isFoodHabitsQuiz) {
      setIsDisableQuiz(true);
      setTimeout(
        () => {
          onSelect(id);
          setIsDisableQuiz(false);
        },
        isDevToolsEnabled ? 0 : 760, // The time taken before showing the next quiz, to allow the animation to end.
      );
    } else {
      onSelect(id);
    }
  };

  const isShowQuizAnimation = isAnswerSelected && !isDevToolsEnabled;
  const isShowQuizAnimationFoodHabits = isSelected && !isDisabled && !isDevToolsEnabled;
  const isDisabledInDevTools = isFoodHabitsQuiz && isDevToolsEnabled && !isSelected;

  return (
    <S.TestAnswerContainer
      data-testid={`AnswerItem-${isSelected}-${isDisabled}`}
      disabled={!isFoodHabitsQuiz ? isDisabled || isUserQuizLoading : null}
      onClick={handleClick}
    >
      <S.ImageWrapper $isDisableInDevTools={isDisabledInDevTools} $isSelected={isSelected}>
        {isFoodHabitsQuiz
          ? isShowQuizAnimationFoodHabits && (
              <S.AnimationWrapper>
                <TestAnswerAnimation duration={1.5} isActive={isSelected} scale={isApplicationKiosk ? 1.5 : 1} />
              </S.AnimationWrapper>
            )
          : isShowQuizAnimation && (
              <S.AnimationWrapper>
                <TestAnswerAnimation duration={1.5} isActive={isAnswerSelected} scale={isApplicationKiosk ? 1.5 : 1} />
              </S.AnimationWrapper>
            )}
        <S.AnswerImage $isSelected={isSelected} alt="answer item" src={image} />
      </S.ImageWrapper>
      <S.TestAnswerText color={customTextColor || STATIC_COLORS.base.black} size="body1" text={text} weight="medium" />
    </S.TestAnswerContainer>
  );
};

export default TestAnswer;
