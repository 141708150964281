import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import Button from '@components/web/src/atoms/Buttons/Button';

type Props = {
  handleTakeTestBtnClick?: () => void;
};

const TakeTestStartPage = ({ handleTakeTestBtnClick }: Props) => {
  const {
    takeTestStartPage: { description, header, button },
  } = localeCommon;

  return (
    <div className="app-takeTestStartPage-container">
      <div className="content-wrapper">
        <div className="heading">
          <p>
            <LocaleFragment message={header} />
          </p>
        </div>
        <p className="description">
          <LocaleFragment message={description} />
        </p>
      </div>
      <div className="actions">
        <Button text={button} onClick={handleTakeTestBtnClick} />
      </div>
    </div>
  );
};

export default TakeTestStartPage;
