import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { charactersByProductCategory } from '@lib/core/characters/utils';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { GPRL_CHARACTER_QUERY, PREFERENCES_QUERY, PRODUCT_REQUEST_FOR_HOME_PAGE } from '@lib/core/service/consts';
import { useProductPreferences } from '@lib/core/service/hooks/useProductPreferences';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { useProductsSwiper } from '@lib/tools/productsSwiper/hooks/useProductsSwiper';
import { fetchSwiperProducts } from '@lib/tools/productsSwiper/slices/index';
import {
  FILTER_TYPE_ORDERING,
  ORDERING_TYPE_RANK,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import MissedCharacterCard from '@components/web/src/organisms/Cards/MissedCharacterCard/MissedCharacterCard';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import SeeLaterCard from '@components/web/src/organisms/Cards/SeeLaterCard/SeeLaterCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type IProductsSwiperProps = {
  storeType: TRetailerLocationStoreType;
  locale: string;
  wishlistProductInstanceIds: string[];
  isWishlistProductListLoading?: boolean;
  feedbackData: IFeedbackData[];
  discoveryQuiz: IDiscoveryQuiz;
  handleUpdateFeedback: (feedback: TFeedback, productId: string, productName?: string) => void;
  handleUpdateWishlistProductList: (productId: string, productName?: string) => void;
  shouldHideComment?: boolean;
};

const ProductsSwiper: FC<IProductsSwiperProps> = ({
  storeType,
  locale,
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  feedbackData,
  discoveryQuiz,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  shouldHideComment,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isBeerProductsLoading,
    isCoffeeProductsLoading,
    isWineProductsLoading,
    wineProducts,
    coffeeProducts,
    beerProducts,
  } = useProductsSwiper();
  const { isUserProductPreferencesDataLoading, userProductsPreferencesString } = useProductPreferences();

  const { userCharacters } = useUser();
  const userCharactersIndexedByProductCategory = charactersByProductCategory(userCharacters);
  const isProductsLoading = isBeerProductsLoading || isCoffeeProductsLoading || isWineProductsLoading;

  useEffect(() => {
    userCharacters.forEach(character => {
      if (character && !isUserProductPreferencesDataLoading) {
        const requestParams = {
          [FILTER_TYPE_ORDERING]: ORDERING_TYPE_RANK,
          [GPRL_CHARACTER_QUERY]: character.identifier,
          [PRODUCT_CATEGORY_QUERY]: character.product_category,
          offset: 0,
        };

        if (userProductsPreferencesString) {
          requestParams[PREFERENCES_QUERY] = userProductsPreferencesString;
        }
        dispatch(fetchSwiperProducts({ params: requestParams, productResultFor: PRODUCT_REQUEST_FOR_HOME_PAGE }));
      }
    });
  }, [userCharacters, userProductsPreferencesString]);

  const allProducts = useMemo(() => {
    const pushProduct = (product, category) => {
      return product[0] || (userCharactersIndexedByProductCategory[category] ? null : { productCategory: category });
    };

    return [
      pushProduct(wineProducts, PRODUCT_CATEGORY_WINE),
      pushProduct(beerProducts, PRODUCT_CATEGORY_BEER),
      pushProduct(coffeeProducts, PRODUCT_CATEGORY_COFFEE),
    ].filter(product => product !== null);
  }, [isProductsLoading, wineProducts, beerProducts, coffeeProducts]);

  const navigateToFoodHabitsPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  return (
    <SwiperHOC>
      {isProductsLoading
        ? [
            <ProductCard key={1} isLoading={isProductsLoading} locale={locale} storeType={storeType} />,
            <ProductCard key={2} isLoading={isProductsLoading} locale={locale} storeType={storeType} />,
            <ProductCard key={3} isLoading={isProductsLoading} locale={locale} storeType={storeType} />,
          ]
        : allProducts?.map((productInstanceData, index) =>
            productInstanceData.identifier ? (
              <ProductCard
                key={productInstanceData.identifier}
                discoveryQuiz={discoveryQuiz}
                feedback={feedbackFilter(feedbackData, productInstanceData)}
                handleUpdateFeedback={handleUpdateFeedback}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isLoading={false}
                isWishlistProductListLoading={isWishlistProductListLoading}
                locale={locale}
                mixpanelIndex={index}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                productInstanceData={productInstanceData}
                shouldHideComment={shouldHideComment}
                storeType={storeType}
                variant="topBanner"
                isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                  wishlistProductInstanceIds,
                  productInstanceData,
                )}
              />
            ) : (
              <MissedCharacterCard
                key={productInstanceData.productCategory}
                handleButtonClick={navigateToFoodHabitsPage}
                productCategory={productInstanceData.productCategory}
              />
            ),
          )}
      <SeeLaterCard heightBox="100%" widthBox={312} />
    </SwiperHOC>
  );
};

export default ProductsSwiper;
