import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  hideModal: () => void;
}

const FoodPreferencesModal: FC<IProps> = ({ isModalOpen, hideModal }) => {
  const { foodPreferencesModal } = localeApp;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="food-preferences-modal-container">
      <CloseButton handleClick={hideModal} />
      <p className="title">
        <LocaleFragment message={foodPreferencesModal.heading} />
      </p>
      <p className="description">
        <LocaleFragment message={foodPreferencesModal.description} />
      </p>
    </ModalWrapper>
  );
};

export default FoodPreferencesModal;
