import styled from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const GoBackButton = styled(BackButton)`
  justify-content: flex-start;
  padding: 8px 0 16px;
`;

export const Divider = styled.div<{ $isInvisible?: boolean }>`
  width: 100%;
  height: 1px;
  background-color: ${STATIC_COLORS.gray[600]};
  ${({ $isInvisible }) =>
    $isInvisible &&
    `height: 0;
  `}
`;

export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormActionsButton = styled(Button)`
  min-width: 110px;
`;

export const Section = styled.section``;

export const LanguageButton = styled.button<{ $isActive: boolean }>`
  flex: 1;
  padding: 10px 16px;
  background-color: ${STATIC_COLORS.base.white};
  border: 1px solid ${STATIC_COLORS.gray[300]};
  border-left: 0;
  &:first-child {
    border: 1px solid ${STATIC_COLORS.gray[300]};
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
  }
  ${({ $isActive }) =>
    $isActive &&
    `background-color: ${STATIC_COLORS.teal[50]};
  `}
`;

export const ProfileButton = styled(Button)`
  min-width: 110px;
  margin: 4px 0 0 auto;
`;

export const ExitSectionWrapper = styled(Flexbox)`
  width: 100%;
`;
