import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { selectListData } from '@lib/core/serviceTerms/selectors';
import { apiUrlServiceTerms } from '@lib/core/serviceTerms/slices/urls';
import { IParsedServiceTerm, IServiceTermsOptionsResponse } from '@lib/core/serviceTerms/types';
import { parseError } from '@lib/tools/shared/helpers';

export interface IServiceTermsSlice {
  list: { data: { results: IParsedServiceTerm[] }; error: string; loading: boolean; firstGetDataSuccessFlag: boolean };
  options: { data: IServiceTermsOptionsResponse; error: string; loading: boolean };
}

const initialState: IServiceTermsSlice = {
  list: { data: { results: [] }, error: '', firstGetDataSuccessFlag: false, loading: false },
  options: { data: { choices: { service_terms: { platform: [], retailer: [] } } }, error: '', loading: false },
};

export const actionGetServiceTermsOptions = createTypedAsyncThunk(
  'serviceTerms/options',
  async () =>
    await requestWithKeysAsOptions({
      apiPath: apiUrlServiceTerms(),
      config: {
        method: REQUEST_METHODS.OPTIONS,
      },
    }),
);

export const actionGetServiceTermsList = createTypedAsyncThunk(
  'serviceTerms/get',
  async () =>
    await requestWithKeysAsOptions({
      apiPath: apiUrlServiceTerms(),
      config: {
        method: REQUEST_METHODS.GET,
      },
    }),
);

export const actionUpdateServiceTerms = createTypedAsyncThunk(
  'serviceTerms/update',
  async (serviceTerms: IParsedServiceTerm[], { getState, rejectWithValue }) => {
    try {
      return await Promise.all(
        serviceTerms.map(serviceTerm => {
          const listData = selectListData(getState());
          const isThisUserTermsServiceRegister = listData.find(
            userTerm => userTerm.identifier === serviceTerm.term || userTerm.term === serviceTerm.term,
          );

          if (isThisUserTermsServiceRegister) {
            return requestWithKeysAsOptions({
              apiPath: `${apiUrlServiceTerms()}${serviceTerm.term}/`,
              body: serviceTerm,
              config: {
                method: REQUEST_METHODS.PUT,
              },
            });
          }

          return requestWithKeysAsOptions({
            apiPath: `${apiUrlServiceTerms()}`,
            body: serviceTerm,
            config: {
              method: REQUEST_METHODS.POST,
            },
          });
        }),
      );
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const serviceTermsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetServiceTermsList.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.data = action.payload;
      state.list.firstGetDataSuccessFlag = true;
    });

    builder.addCase(actionGetServiceTermsOptions.pending, state => {
      state.options.loading = true;
    });
    builder.addCase(actionGetServiceTermsOptions.rejected, (state, action) => {
      state.options.loading = false;
      state.options.error = action.payload.message;
    });
    builder.addCase(actionGetServiceTermsOptions.fulfilled, (state, action) => {
      state.options.loading = false;
      state.options.data = action.payload;
    });
    builder.addCase(actionUpdateServiceTerms.pending, state => {
      state.list.loading = true;
    });
    builder.addCase(actionUpdateServiceTerms.fulfilled, (state, action) => {
      state.list.loading = false;
      const dataMap = new Map();

      state.list.data.results.forEach(item => {
        dataMap.set(item.identifier || item.term, item);
      });

      action.payload.forEach(({ term, is_selected }) => {
        dataMap.set(term.identifier, { ...term, is_selected });
      });

      state.list.data.results = Array.from(dataMap.values());
    });

    builder.addMatcher(isAnyOf(actionGetServiceTermsList.fulfilled, actionUpdateServiceTerms.fulfilled), state => {
      state.list.loading = false;
    });
    builder.addMatcher(
      isAnyOf(actionGetServiceTermsList.rejected, actionUpdateServiceTerms.rejected),
      (state, action) => {
        state.list.loading = false;
        state.list.error = action.payload.message;
      },
    );
  },
  initialState,
  name: 'serviceTerms',
  reducers: {
    actionResetServiceTermsList: state => {
      return {
        ...state,
        list: initialState.list,
      };
    },
  },
});

export default serviceTermsSlice.reducer;
export const { actionResetServiceTermsList } = serviceTermsSlice.actions;
