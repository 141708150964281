import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LEGAL_TAB_SLUG_REPLACEMENT } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useAddons } from '@lib/core/service/hooks/useAddons';
import { prependBasename } from '@lib/core/service/utils';
import { SERVICE_TERMS_CATEGORIES } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { useModals } from '@lib/tools/modals/hooks';
import RouteUtils from '@lib/tools/routes';
import {
  LEGAL_PAGE_COOKIE_POLICY_TAB,
  LEGAL_PAGE_PRIVACY_POLICY_TAB,
  LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
  MODALS,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import StartPage from '@components/web/src/pages/widget/StartPage/StartPage';
import { BasicServiceTermsModal } from '@components/web/src/templates/Modals/BasicServiceTermsModal/BasicServiceTermsModal';
import { GeneralServiceTermsModal } from '@components/web/src/templates/Modals/GeneralServiceTermsModal/GeneralServiceTermsModal';

const HocStart = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { closeAllModals, openModal, isBasicServiceTermsModalOpened, isGeneralServiceTermsModalOpened } = useModals();
  const { isDisableHomeAddon } = useAddons();

  const { productCategory } = useApp();
  const {
    updateServiceTerms,
    serviceTermsListUpdateIsLoading,
    isTermsInitialDataLoading,
    serviceTermsByCategory: { cookie },
  } = useServiceTerms();

  const isLegalPage = [
    LEGAL_PAGE_COOKIE_POLICY_TAB,
    LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
    LEGAL_PAGE_PRIVACY_POLICY_TAB,
  ].some(legalPage => RouteUtils.getPage().includes(legalPage));
  const isRedirectPage = RouteUtils.getPage().includes(PAGES.vinhood.redirect);

  useEffect(() => {
    if (!isAppInIframe && !cookie?.is_selected && !isLegalPage) {
      openModal(MODALS.TERMS_OF_SERVICE_BASIC_MODAL);
    }
  }, [location, isTermsInitialDataLoading, cookie]);

  const handleIframeCookieServiceTermConfirmation = () => {
    updateServiceTerms([{ category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true }]).then(() => {
      navigate(prependBasename(isDisableHomeAddon ? PAGES.vinhood.quiz.chooseExpert : PAGES.vinhood.home));
    });
  };

  const handleOpenGeneralServiceTermsModal = () => {
    closeAllModals();
    openModal(MODALS.TERMS_OF_SERVICE_GENERAL_MODAL);
  };

  const navigateToCookiePolicyTab = () => {
    closeAllModals();
    navigate(
      prependBasename(PAGES.vinhood.legal.tabs.replace(LEGAL_TAB_SLUG_REPLACEMENT, LEGAL_PAGE_COOKIE_POLICY_TAB)),
    );
  };

  const handleAcceptAllBasicServiceTerms = () => {
    updateServiceTerms([
      { category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true },
      { category: SERVICE_TERMS_CATEGORIES.profiling, is_selected: true },
    ]).then(() => closeAllModals());
  };

  const handleSaveGeneralServiceTerms = (values: IParsedServiceTerm[]) => {
    updateServiceTerms(values).then(() => closeAllModals());
  };

  if (isAppInIframe && !cookie?.is_selected && !isLegalPage) {
    return (
      <StartPage
        handleIframeCookieServiceTermConfirmation={handleIframeCookieServiceTermConfirmation}
        navigateToCookiePolicyTab={navigateToCookiePolicyTab}
        productCategory={productCategory}
        serviceTermsListUpdateIsLoading={serviceTermsListUpdateIsLoading}
      />
    );
  }

  const handleRejectOptionalBasicServiceTerms = () => {
    updateServiceTerms([
      { category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true },
      { category: SERVICE_TERMS_CATEGORIES.profiling, is_selected: false },
    ]).then(() => closeAllModals());
  };

  return (
    <>
      {children}
      {!isAppInIframe && isBasicServiceTermsModalOpened && !isLegalPage && !isRedirectPage && (
        <BasicServiceTermsModal
          handleAcceptAllBasicServiceTerms={handleAcceptAllBasicServiceTerms}
          handleOpenGeneralServiceTermsModal={handleOpenGeneralServiceTermsModal}
          handleRejectOptionalBasicServiceTerms={handleRejectOptionalBasicServiceTerms}
          isDisabled={isTermsInitialDataLoading}
          isLoading={serviceTermsListUpdateIsLoading || isTermsInitialDataLoading}
          isModalOpen={isBasicServiceTermsModalOpened}
        />
      )}
      {!isAppInIframe && isGeneralServiceTermsModalOpened && (
        <GeneralServiceTermsModal
          shouldOpenModalsUnderHeader
          handleSaveGeneralServiceTerms={handleSaveGeneralServiceTerms}
          hideModal={closeAllModals}
          isLoading={serviceTermsListUpdateIsLoading}
          isModalOpen={isGeneralServiceTermsModalOpened}
        />
      )}
    </>
  );
};

export default HocStart;
