import { localeApp } from '@lib/tools/locale/source/web/app';

import LoadingGIF from '@components/web/src/assets/gif/loading.gif';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Loading/styles';

const LoadingPage = () => {
  const {
    loadingScreen: { textOne, textTwo },
  } = localeApp;
  return (
    <S.LoadingPageContainer data-testid="LoadingPageContainer">
      <img alt="loading" src={LoadingGIF} />
      <S.TextContainer>
        <Text size="subtitle1" text={textOne} />
        <Text size="subtitle1" text={textTwo} weight="semibold" />
      </S.TextContainer>
    </S.LoadingPageContainer>
  );
};

export default LoadingPage;
