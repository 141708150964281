import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LEGAL_TAB_SLUG_REPLACEMENT } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LEGAL_PAGE_PRIVACY_POLICY_TAB, LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB } from '@lib/tools/shared/helpers/consts';
import { currentYear } from '@lib/tools/shared/helpers/utils';
import { PAGES } from '@lib/tools/views/urls';

import * as S from '@components/web/src/atoms/Footers/FooterKiosk/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  isWaveVariant: boolean;
}

const FooterKiosk: FC<IProps> = ({ isWaveVariant }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { commonMessages } = localeCommon;

  const onLinkClick = navigateTo =>
    location.pathname.includes(navigateTo)
      ? null
      : navigate(prependBasename(PAGES.vinhood.legal.tabs.replace(LEGAL_TAB_SLUG_REPLACEMENT, navigateTo)));

  const footerMenu = [
    {
      onClick: () => onLinkClick(LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB),
      text: commonMessages.termsAndConditionsTitle,
    },
    {
      onClick: () => onLinkClick(LEGAL_PAGE_PRIVACY_POLICY_TAB),
      text: commonMessages.privacyPolicyTitle,
    },
  ];

  return (
    <S.FooterKioskContainer data-testid="FooterKiosk">
      {isWaveVariant && <S.WaveIcon />}
      <S.ContentWrapper>
        <Text
          color={STATIC_COLORS.base.white}
          localeOptions={{ currentYear }}
          size="body3"
          text={commonMessages.copyrightText}
        />
        <S.LinksWrapper>
          {footerMenu.map(({ text, onClick }, idx) => (
            <S.Link key={idx} onClick={onClick}>
              <Text color={STATIC_COLORS.base.white} size="body2" text={text} />
            </S.Link>
          ))}
        </S.LinksWrapper>
      </S.ContentWrapper>
    </S.FooterKioskContainer>
  );
};

export default FooterKiosk;
