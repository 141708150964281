import React, { FC, Fragment } from 'react';

import { IPreferenceExposure } from '@app/native/src/models/UserFoodPreferences.model';

import { TLanguage } from '@lib/core/retailers/types';
import { TProfileCountryOption, TProfileGenderOption } from '@lib/core/users/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { languages } from '@lib/tools/locale/utils/consts';

import ProfileHintHeader from '@components/web/src/atoms/Headers/ProfileHintHeader/ProfileHintHeader';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProfilePictureSelection from '@components/web/src/templates/TasteId/TasteIdSettingsPage/ProfilePictureSelection/ProfilePictureSelection';
import * as S from '@components/web/src/templates/TasteId/TasteIdSettingsPage/styles';
import UserInfoForm, { IFormData } from '@components/web/src/templates/TasteId/TasteIdSettingsPage/UserInfoForm';

interface IProps {
  currentLanguage: string;
  foodPreferencesState: IPreferenceExposure;
  formData: IFormData;
  userImage: string;
  userImageUpdateError: string;
  supportedLanguages: TLanguage[];
  shouldShowManageSection?: boolean;
  profileCountryOptions: TProfileCountryOption[];
  profileGenderOptions: TProfileGenderOption[];
  handleUserImageUpdateError: React.Dispatch<React.SetStateAction<string>>;
  onToggleClick: (optionKey: string) => void;
  onExitBtnClick: () => void;
  onManageBtnClick: () => void;
  onPreferencesHintBtnClick: () => void;
  handleLanguageChange: (IB2CSupportedLanguages) => void;
  handleFormSubmit: (data: IFormData) => void;
  handleUserImageUpdate: (base64ImageData: string) => void;
  handleBackBtnClick?: () => void;
}

const TasteIdSettingsPage: FC<IProps> = ({
  currentLanguage = languages.ENGLISH,
  foodPreferencesState,
  formData,
  userImage,
  userImageUpdateError = '',
  supportedLanguages,
  shouldShowManageSection = false,
  profileGenderOptions,
  profileCountryOptions,
  onToggleClick,
  onExitBtnClick,
  onManageBtnClick,
  onPreferencesHintBtnClick,
  handleLanguageChange,
  handleFormSubmit,
  handleUserImageUpdateError,
  handleUserImageUpdate,
  handleBackBtnClick,
}) => {
  const {
    settings,
    languageBtnGroup: { languageTitle },
  } = localeApp;

  const { back } = localeCommon.commonMessages;

  const {
    settingsTitle,
    settingsSubTitle,
    foodPreferencesTitle,
    foodPreferencesSubTitle,
    accountTitle,
    accountSubTitle,
    manageTextDescription,
    manageBtnText,
    exitBtnText,
  } = settings;

  return (
    <Flexbox isFullWidth data-testid="SettingsTabContainer" direction="column" gap={16} padding="0 16px 32px">
      <S.GoBackButton arrowSize="short" handleClick={handleBackBtnClick} text={back} />
      <ProfileSectionHeader description={settingsSubTitle} title={settingsTitle} />
      <S.Divider $isInvisible />
      <ProfilePictureSelection
        handleUserImageUpdate={handleUserImageUpdate}
        handleUserImageUpdateError={handleUserImageUpdateError}
        userImage={userImage}
        userImageUpdateError={userImageUpdateError}
      />
      <S.Divider />
      <UserInfoForm
        formData={formData}
        handleFormSubmit={handleFormSubmit}
        profileCountryOptions={profileCountryOptions}
        profileGenderOptions={profileGenderOptions}
      />
      <S.Divider />
      {supportedLanguages?.length >= 2 && (
        <>
          <S.Section>
            <Text color={STATIC_COLORS.gray[700]} size="body1" text={languageTitle} weight="medium" />
            <Flexbox gap={0} padding="8px 0">
              {supportedLanguages.map(supportedLang => {
                const isActive = currentLanguage === supportedLang.code;
                return (
                  <S.LanguageButton
                    key={supportedLang.code}
                    $isActive={isActive}
                    onClick={() => handleLanguageChange(supportedLang.code)}
                  >
                    <Text
                      color={isActive ? STATIC_COLORS.teal[900] : STATIC_COLORS.teal[700]}
                      size="body1"
                      text={supportedLang.language}
                      weight={isActive ? 'medium' : 'normal'}
                    />
                  </S.LanguageButton>
                );
              })}
            </Flexbox>
          </S.Section>
          <S.Divider />
        </>
      )}
      <S.Section>
        <ProfileHintHeader
          subTitle={foodPreferencesSubTitle}
          title={foodPreferencesTitle}
          onHintBtnClick={onPreferencesHintBtnClick}
        />
        <Flexbox direction="column" gap={20} margin="16px 0 0">
          {Object.keys(foodPreferencesState)?.map((optionKey, index) => {
            const shouldDisplayDivider = Object.keys(foodPreferencesState).length === index + 1;
            return (
              <Fragment key={optionKey}>
                {shouldDisplayDivider && <S.Divider $isInvisible />}
                <ToggleButton
                  isToggled={foodPreferencesState[optionKey].isEnabled}
                  text={foodPreferencesState[optionKey].name}
                  onToggle={() => onToggleClick(optionKey)}
                />
              </Fragment>
            );
          })}
        </Flexbox>
      </S.Section>
      <S.Divider />
      <S.ExitSectionWrapper direction="column" gap={16}>
        <>
          <ProfileHintHeader subTitle={accountSubTitle} title={accountTitle} />
          <S.ProfileButton size="sm" text={exitBtnText} onClick={onExitBtnClick} />
        </>

        {shouldShowManageSection && (
          <>
            <ProfileHintHeader subTitle={manageTextDescription} />
            <S.ProfileButton size="sm" text={manageBtnText} onClick={onManageBtnClick} />
          </>
        )}
      </S.ExitSectionWrapper>
    </Flexbox>
  );
};

export default TasteIdSettingsPage;
