import classNames from 'classnames';
import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

type Props = {
  descriptionPoints: ILocaleText[];
  keyName: string;
  isOrderedList?: boolean;
  className?: string;
};

export const PrivacyAndTermsListItem: FC<Props> = ({
  descriptionPoints,
  keyName,
  isOrderedList = false,
  className = '',
}) => {
  const ListType = isOrderedList ? 'ol' : 'ul';
  return (
    <ListType className={classNames(className)}>
      {descriptionPoints.map((description, index) => (
        <li key={`${keyName}-${index}`}>
          <p>
            <LocaleFragment message={description} />
          </p>
        </li>
      ))}
    </ListType>
  );
};
