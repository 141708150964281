import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import { TToastMessages } from '@lib/tools/toastMessage/types/interfaces';

import { FooterWidgetAnonymous, FooterWidgetLogged } from '@components/web/src/atoms/Footers';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/styles';
import { HeaderWidget } from '@components/web/src/atoms/Headers/HeaderWidget/HeaderWidget';
import ToastMessage from '@components/web/src/atoms/Toast/ToastMessage';
import DownloadAppCard from '@components/web/src/organisms/Cards/DownloadAppCard/DownloadAppCard';

interface IProps {
  children: ReactNode;
  isHideGearIcon: boolean;
  shouldShowHeaderDropDownMenu: boolean;
  locale: string;
  isEnableExitNavigation: boolean;
  isEnableLanguageSwitcher: boolean;
  isEnableSettingsNavigation: boolean;
  retailerLanguages: TLanguage[];
  profileImageUrl?: string;
  producerName?: string;
  shouldHideHomeLink: boolean;
  isMultipleProducer?: boolean;
  shouldHideCatalogLink: boolean;
  shouldHideTasteIdLink: boolean;
  shouldHideExploreLink: boolean;
  shouldShowTasteIdGuide?: boolean;
  shouldShowDownloadAppCard: boolean;
  shouldHideAnonymousFooter: boolean;
  shouldHideLoggedFooter: boolean;
  isFixedBody: boolean;
  toastMessages: TToastMessages;
  handleLogoClick: () => void;
  handleExitNavigation: () => void;
  handleSettingPageNavigation: () => void;
  handleClearToastMessage: () => void;
  handleSelectLanguage: (selectedLanguageCode: string) => void;
  handleSetTasteIdPageShown?: () => void;
  handleDownloadAppClick?: () => void;
  handleHideDownloadAppClick?: () => void;
}

const HeaderFooterWidget: FC<IProps> = ({
  children,
  isHideGearIcon,
  isEnableSettingsNavigation,
  shouldShowHeaderDropDownMenu,
  isEnableExitNavigation,
  isEnableLanguageSwitcher,
  isMultipleProducer,
  locale,
  retailerLanguages,
  profileImageUrl,
  producerName,
  shouldHideCatalogLink,
  shouldHideTasteIdLink,
  shouldHideHomeLink,
  shouldShowDownloadAppCard,
  shouldHideExploreLink,
  shouldShowTasteIdGuide,
  shouldHideAnonymousFooter,
  shouldHideLoggedFooter,
  isFixedBody,
  toastMessages,
  handleLogoClick,
  handleExitNavigation,
  handleSettingPageNavigation,
  handleClearToastMessage,
  handleSelectLanguage,
  handleSetTasteIdPageShown,
  handleDownloadAppClick,
  handleHideDownloadAppClick,
}) => {
  return (
    <S.HeaderFooterWidgetContainer data-testid="HeaderFooterWidget">
      {toastMessages && (
        <ToastMessage
          handleClearToastMessage={handleClearToastMessage}
          message={toastMessages.message}
          title={toastMessages.title}
          type={toastMessages.type}
        />
      )}
      {shouldShowDownloadAppCard && (
        <DownloadAppCard handleClick={handleDownloadAppClick} handleCloseClick={handleHideDownloadAppClick} />
      )}
      <HeaderWidget
        handleExitNavigation={handleExitNavigation}
        handleLogoClick={handleLogoClick}
        handleSelectLanguage={handleSelectLanguage}
        handleSettingPageNavigation={handleSettingPageNavigation}
        isEnableExitNavigation={isEnableExitNavigation}
        isEnableLanguageSwitcher={isEnableLanguageSwitcher}
        isEnableSettingsNavigation={isEnableSettingsNavigation}
        locale={locale}
        producerName={producerName}
        retailerLanguages={retailerLanguages}
        shouldShowHeaderDropDownMenu={shouldShowHeaderDropDownMenu}
      />
      <S.BodyContainer $isFixedBody={isFixedBody} data-testid="BodyContainer" id="root-scroll-element">
        {children}
      </S.BodyContainer>
      {!shouldHideAnonymousFooter && <FooterWidgetAnonymous isHideGearIcon={isHideGearIcon} />}
      {!shouldHideLoggedFooter && (
        <FooterWidgetLogged
          handleSetTasteIdPageShown={handleSetTasteIdPageShown}
          isMultipleProducer={isMultipleProducer}
          profileImageUrl={profileImageUrl}
          shouldHideCatalogLink={shouldHideCatalogLink}
          shouldHideExploreLink={shouldHideExploreLink}
          shouldHideHomeLink={shouldHideHomeLink}
          shouldHideTasteIdLink={shouldHideTasteIdLink}
          shouldShowTasteIdGuide={shouldShowTasteIdGuide}
        />
      )}
    </S.HeaderFooterWidgetContainer>
  );
};

export default HeaderFooterWidget;
