import { createSelector } from '@reduxjs/toolkit';

import { IProductsSwiperState } from '@lib/tools/productsSwiper/types/interface';

/**
 * Selects the entire products swiper state from the Redux store.
 */
export const selectProductsSwiperState = (state): IProductsSwiperState => state.productsSwiper || {};

/**
 * Selects the product data for the swiper from the products swiper state.
 */
export const selectSwiperProductsData = createSelector(
  [selectProductsSwiperState],
  productsSwiper => productsSwiper.productsData || {},
);

/**
 * Selects the wine products from the swiper product data.
 */
export const selectSwiperProductsDataWine = createSelector(
  [selectSwiperProductsData],
  productsData => productsData?.wine || [],
);

/**
 * Selects the beer products from the swiper product data.
 */
export const selectSwiperProductsDataBeer = createSelector(
  [selectSwiperProductsData],
  productsData => productsData?.beer || [],
);

/**
 * Selects the coffee products from the swiper product data.
 */
export const selectSwiperProductsDataCoffee = createSelector(
  [selectSwiperProductsData],
  productsData => productsData?.coffee || [],
);

/**
 * Selects the loading state for beer products in the swiper.
 */
export const selectIsProductsRequestLoadingBeer = createSelector(
  [selectProductsSwiperState],
  productsSwiper => productsSwiper.isProductsRequestLoading.beer || false,
);

/**
 * Selects the loading state for coffee products in the swiper.
 */
export const selectIsProductsRequestLoadingCoffee = createSelector(
  [selectProductsSwiperState],
  productsSwiper => productsSwiper.isProductsRequestLoading.coffee || false,
);

/**
 * Selects the loading state for wine products in the swiper.
 */
export const selectIsProductsRequestLoadingWine = createSelector(
  [selectProductsSwiperState],
  productsSwiper => productsSwiper.isProductsRequestLoading.wine || false,
);
