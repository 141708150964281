import Skeleton from '@components/web/src/components/Skeleton/Skeleton';

const CharacterDetailsSkeleton = ({ productCategory }) => {
  return (
    <div className={`vh-character-details-skeleton ${productCategory}`}>
      <Skeleton className="result-intro" />
      <Skeleton className="character" />
      <Skeleton className="category" />
      <Skeleton className="description" />
      <Skeleton className="cta" />
    </div>
  );
};

export default CharacterDetailsSkeleton;
