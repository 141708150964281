import { FC } from 'react';

import { IPreferenceExposure } from '@app/native/src/models/UserFoodPreferences.model';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  tagsPreferencesState: IPreferenceExposure;
  hideModal: () => void;
}

const TagsModal: FC<IProps> = ({ isModalOpen, tagsPreferencesState, hideModal }) => {
  const {
    tagsModal: { heading, headingDescription },
  } = localeApp;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="tags-modal-wrapper">
      <div className="tags-modal-container">
        <div className="header-wrapper">
          <CloseButton handleClick={hideModal} />
          <p className="title">
            <LocaleFragment message={heading} />
          </p>
          <p className="description">
            <LocaleFragment message={headingDescription} />
          </p>
        </div>
        <div className="tags-wrapper">
          {Object.keys(tagsPreferencesState)?.map(tagKey => (
            <div key={tagsPreferencesState[tagKey].name}>
              <div className="tag-title">{tagsPreferencesState[tagKey].name}</div>
              <div className="tag-description">{tagsPreferencesState[tagKey].description}</div>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TagsModal;
