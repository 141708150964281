import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { selectProductsList } from '@lib/core/products/selectors/products';
import { selectProductSetsIds, selectProductSetsImages } from '@lib/core/products/selectors/productSets';
import { productSetProductsApiUrlCreator, productSetsApiUrlCreator } from '@lib/core/products/slices/urls';
import { IProductSetProductRanks, TProductSetsProducts } from '@lib/core/products/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { isGprlNotHidden, isProductLevia, isProductSentia } from '@lib/tools/shared/pmi/products/filters';

interface IProductSets {
  isProductSetsLoading: boolean;
  productSetsError: string;
  productSetsIds: string[];
  productSetsImages: string[];
  productSetProducts: TProductSetsProducts[];
  isProductSetProductsLoading: boolean;
  productSetProductsError: string;
}
const initialState: IProductSets = {
  isProductSetProductsLoading: false,
  isProductSetsLoading: false,
  productSetProducts: [],
  productSetProductsError: '',
  productSetsError: '',
  productSetsIds: [],
  productSetsImages: [],
};

export const actionGetProductSets = createTypedAsyncThunk(
  'products/productSets/get',
  async ({ characterId, mood }: { characterId: string; mood: string }) => {
    const productSets = await request(productSetsApiUrlCreator({ characterID: characterId, mood }));

    const { productSetIds, productSetImages } = productSets.reduce(
      (acc, productSet) => {
        acc.productSetIds.push(productSet.identifier);
        acc.productSetImages.push(productSet.image);
        return acc;
      },
      { productSetIds: [], productSetImages: [] },
    );

    return { productSetIds, productSetImages };
  },
);

export const actionGetProductSetProducts = createTypedAsyncThunk(
  'products/productSetsProducts/get',
  async ({ productSetIds, bestMatchSlug }: { productSetIds: string[]; bestMatchSlug: string }, { getState }) => {
    const newProductSetProducts = [];

    try {
      const rawProductSets = await request(productSetProductsApiUrlCreator(productSetIds));

      const productCategory = selectServiceProductCategory(getState());
      const productsList = selectProductsList(getState());
      const productSetsIds = selectProductSetsIds(getState());
      const productSetsImages = selectProductSetsImages(getState());
      const isSentiaAvailable = productsList.some(isProductSentia);
      const sentiaBestMatch = productsList.find(gprl => gprl.product.slug === bestMatchSlug && isProductSentia(gprl));

      let availableGprls = productsList.filter(gprl => {
        return (
          gprl.product.product_category === productCategory &&
          (sentiaBestMatch
            ? gprl.product.identifier !== sentiaBestMatch?.product?.attributes?.sentia_terea_association &&
              gprl.product.slug !== bestMatchSlug
            : gprl.product.slug !== bestMatchSlug) &&
          isGprlNotHidden(gprl)
        );
      });

      if (isSentiaAvailable && !sentiaBestMatch) {
        availableGprls = availableGprls.filter(gprl => !isProductSentia(gprl));
      }

      const availableProductIds = [];
      const availableProductSetProducts = [];

      availableGprls.forEach(gprl => {
        availableProductIds.push(gprl?.product?.identifier);
      });

      rawProductSets.forEach((productSetProducts: IProductSetProductRanks) => {
        if (availableProductIds.includes(productSetProducts?.product?.identifier)) {
          availableProductSetProducts.push(productSetProducts);
        }
      });

      const availableProductSetProductsSentia = availableProductSetProducts.filter(gprl => isProductSentia(gprl));
      const availableProductSetProductsTerea = availableProductSetProducts.filter(
        gprl => !isProductSentia(gprl) && !isProductLevia(gprl),
      );
      const selectedProducts = [];

      productSetsIds.forEach((id, index) => {
        const filteredAvailableProductSetProducts = productsArray => {
          return productsArray.filter(
            productSetGprl =>
              productSetGprl.product_set === id &&
              availableProductIds.includes(productSetGprl.product?.identifier) &&
              !selectedProducts.includes(productSetGprl.product?.identifier),
          );
        };

        if (isSentiaAvailable && !!sentiaBestMatch) {
          const topGprlsTerea = filteredAvailableProductSetProducts(availableProductSetProductsTerea);
          const topGprlsSentia = filteredAvailableProductSetProducts(availableProductSetProductsSentia);

          let gprlOne = productsList.find(gprl => gprl.product?.identifier === topGprlsTerea[0]?.product?.identifier);
          let gprlTwo = productsList.find(gprl => gprl.product?.identifier === topGprlsSentia[0]?.product?.identifier);

          topGprlsTerea.forEach(gprl => {
            const gprlWithNewDelta = Object.assign(gprl);
            gprlWithNewDelta.product.delta = gprl.rank;

            if (gprl.product.identifier === gprlOne.product.identifier) {
              gprlOne = { ...gprlOne, product: { ...gprlOne.product, rank: gprl.rank } };
            }
          });

          topGprlsSentia.forEach(gprl => {
            const gprlWithNewDelta = Object.assign(gprl);
            gprlWithNewDelta.product.delta = gprl.rank;

            if (gprl.product.identifier === gprlTwo.product.identifier) {
              gprlTwo = { ...gprlTwo, product: { ...gprlTwo.product, rank: gprl.rank } };
            }
          });

          if (topGprlsTerea.length && topGprlsSentia.length) {
            selectedProducts.push(gprlOne.product.identifier);
            selectedProducts.push(gprlTwo.product.identifier);

            newProductSetProducts.push({
              image: productSetsImages[index],
              product_set_id: id,
              products: [gprlOne, gprlTwo],
            });
          }
        } else {
          const topGprls = filteredAvailableProductSetProducts(availableProductSetProducts);
          const topGprlWithoutLevia = topGprls.filter(gprl => !isProductLevia(gprl));

          let gprlOne = productsList.find(gprl => gprl.product?.identifier === topGprls[0]?.product?.identifier);
          let gprlTwo =
            isProductLevia(gprlOne) && topGprlWithoutLevia.length
              ? productsList.find(gprl => gprl.product?.identifier === topGprlWithoutLevia[0]?.product?.identifier)
              : productsList.find(gprl => gprl.product?.identifier === topGprls[1]?.product?.identifier);

          topGprls.forEach(gprl => {
            const gprlWithNewDelta = Object.assign(gprl);
            gprlWithNewDelta.product.delta = gprl.rank;

            if (gprl.product.identifier === gprlOne.product.identifier) {
              gprlOne = { ...gprlOne, product: { ...gprlOne.product, rank: gprl.rank } };
            }
            if (gprl.product.identifier === gprlTwo.product.identifier) {
              gprlTwo = { ...gprlTwo, product: { ...gprlTwo.product, rank: gprl.rank } };
            }
          });

          if (topGprls.length) {
            selectedProducts.push(gprlOne.product.identifier, gprlTwo.product.identifier);

            newProductSetProducts.push({
              image: productSetsImages[index],
              product_set_id: id,
              products: [gprlOne, gprlTwo],
            });
          }
        }
      });
    } catch (e) {
      console.error(e);
    }

    return newProductSetProducts;
  },
);

export const productSetsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetProductSets.pending, state => {
      state.isProductSetsLoading = true;
      state.productSetsError = '';
      state.productSetsIds = [];
      state.productSetsImages = [];
    });
    builder.addCase(
      actionGetProductSets.fulfilled,
      (
        state,
        action: PayloadAction<{
          productSetIds: string[];
          productSetImages: string[];
        }>,
      ) => {
        const { payload: { productSetIds, productSetImages } = {} } = action;

        state.isProductSetsLoading = false;
        state.productSetsError = '';
        state.productSetsIds = productSetIds;
        state.productSetsImages = productSetImages;
      },
    );
    builder.addCase(actionGetProductSets.rejected, (state, action) => {
      state.isProductSetsLoading = false;
      state.productSetsError = action.payload.message;
      state.productSetsIds = [];
      state.productSetsImages = [];
    });

    builder.addCase(actionGetProductSetProducts.pending, state => {
      state.isProductSetProductsLoading = true;
      state.productSetProductsError = '';
    });
    builder.addCase(actionGetProductSetProducts.fulfilled, (state, action: PayloadAction<TProductSetsProducts[]>) => {
      const { payload } = action;
      state.isProductSetProductsLoading = false;
      state.productSetProducts = payload;
    });
    builder.addCase(actionGetProductSetProducts.rejected, (state, action) => {
      state.isProductSetProductsLoading = false;
      state.productSetProductsError = action.payload.message;
    });
  },
  initialState,
  name: 'productSets',
  reducers: {
    actionResetProductSets: () => initialState,
  },
});

export default productSetsSlice.reducer;
export const { actionResetProductSets } = productSetsSlice.actions;
