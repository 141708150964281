import { FC, useMemo } from 'react';
import { ZodType, z } from 'zod';

import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { IRegisterPageServiceTerms } from '@lib/tools/shared/auths/views/containers/RegistrationContainer';

import AuthFormServerError from '@components/web/src/components/Authentication/AuthFormServerError/AuthFormServerError';
import GenericAuthForm from '@components/web/src/components/Authentication/GenericAuthForm/GenericAuthForm';
import {
  AuthFormItemTypes,
  RegistrationFormInputObject,
} from '@components/web/src/pages/Authentication/AuthFormInterface';

interface IProps {
  authErrors: Record<string, string>;
  registerPageServiceTerms: IRegisterPageServiceTerms;
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  handleFormSubmit: (data: RegistrationFormInputObject) => void;
}

const EmailRegistrationForm: FC<IProps> = ({
  authErrors,
  registerPageServiceTerms,
  handleOnServiceTermToggle,
  handleFormSubmit,
}) => {
  const { emailRegistrationForm } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const nameFieldPlaceholderText = publishedTerms[emailRegistrationForm.nameFieldPlaceholder?.id];
  const emailFieldPlaceholderText = publishedTerms[emailRegistrationForm.emailFieldPlaceholder?.id];
  const passwordFieldPlaceholderText = publishedTerms[emailRegistrationForm.passwordFieldPlaceholder?.id];
  const emailValidationText = publishedTerms[emailRegistrationForm.emailValidation?.id];
  const validEmailValidationText = publishedTerms[emailRegistrationForm.validEmailValidation?.id];
  const nameValidationText = publishedTerms[emailRegistrationForm.nameValidation?.id];
  const passwordHintText = publishedTerms[emailRegistrationForm.passwordValidation?.id];
  const passwordTooShortValidationText = publishedTerms[emailRegistrationForm.passwordTooShortValidation?.id];
  const passwordTooLongValidationText = publishedTerms[emailRegistrationForm.passwordTooLongValidation?.id];
  const passwordUpperLowerNumberValidationText =
    publishedTerms[emailRegistrationForm.passwordUpperLowerNumberValidation?.id];

  const registrationFormFields: AuthFormItemTypes<keyof RegistrationFormInputObject>[] = useMemo(
    () => [
      {
        label: emailRegistrationForm.nameField,
        name: 'name',
        placeholder: nameFieldPlaceholderText,
        type: 'text',
      },
      {
        label: emailRegistrationForm.emailField,
        name: 'email',
        placeholder: emailFieldPlaceholderText,
        type: 'email',
      },
      {
        hint: passwordHintText,
        label: emailRegistrationForm.passwordField,
        name: 'password',
        placeholder: passwordFieldPlaceholderText,
        type: 'password',
      },
    ],
    [],
  );

  const registrationSchema: ZodType<RegistrationFormInputObject> = useMemo(
    () =>
      z.object({
        email: z.string().min(1, { message: emailValidationText }).email({ message: validEmailValidationText }),
        name: z.string().min(2, { message: nameValidationText }),
        password: z
          .string()
          .min(8, { message: passwordTooShortValidationText })
          .max(30, { message: passwordTooLongValidationText })
          .regex(new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])'), passwordUpperLowerNumberValidationText),
      }),
    [],
  );

  return (
    <div className="email-registration-form-container">
      <GenericAuthForm
        isRegistrationPage
        formFields={registrationFormFields}
        handleOnServiceTermToggle={handleOnServiceTermToggle}
        registerPageServiceTerms={registerPageServiceTerms}
        submitBtnText={emailRegistrationForm.submitBtn}
        validationSchema={registrationSchema}
        onSubmitHandler={handleFormSubmit}
      />
      <AuthFormServerError authErrors={authErrors} />
    </div>
  );
};

export default EmailRegistrationForm;
