import { createSelector } from 'reselect';

import { RootState } from '@lib/core/service/types/appStateType';
import { IProfileState } from '@lib/core/users/slices/profile';
import { TProfile, TProfileCountryOption, TProfileGenderOption } from '@lib/core/users/types';
import { languages } from '@lib/tools/locale/utils/consts';

export const selectProfileState = (state: RootState): IProfileState => state.user.profile;

/**
 * @returns {TProfile} profile data
 */
export const selectProfileData: (state) => TProfile = createSelector([selectProfileState], state => state?.data);

/**
 * @returns {boolean} is profile loading state
 */
export const selectIsProfileLoading: (state) => boolean = createSelector(
  [selectProfileState],
  state => state?.isLoading,
);

/**
 * @returns {number | null} the profile birth year
 */
export const selectProfileBirthYear: (state) => number | null = createSelector(
  [selectProfileData],
  state => state?.birth_year || null,
);

/**
 * @returns {string} the profile gender slug
 */
export const selectProfileGenderSlug: (state) => string = createSelector(
  [selectProfileData],
  state => state?.gender?.slug || '',
);

/**
 * @returns {string} the profile or user country
 */
export const selectProfileCountry = createSelector(
  [state => state?.user],
  userState => userState?.profile?.data?.country || userState?.data?.country || '',
);

/**
 * @returns {string} the profile or user language
 */
export const selectProfileLanguage = createSelector(
  [state => state?.user],
  userState => userState?.data?.language || userState?.profile?.data?.language || languages.ENGLISH,
);

/**
 * @returns the profile Id
 */
export const selectProfileId: (state) => string = createSelector(
  [selectProfileState],
  state => state?.data?.identifier,
);

/**
 * @returns the profile gender options
 */
export const selectProfileGenderOptions: (state) => TProfileGenderOption[] = createSelector(
  [selectProfileState],
  state => state?.options?.genders,
);

/**
 * @returns the profile country options
 */
export const selectProfileCountryOptions: (state) => TProfileCountryOption[] = createSelector(
  [selectProfileState],
  state => state?.options?.countries,
);

/**
 * @returns the profile Language options
 */
export const selectProfileLanguageOptions: (state) => [string, string] = createSelector(
  [selectProfileState],
  state => state?.options?.languages,
);
