import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useAuthFeatureClick } from '@app/web/src/hooks/useAuthFeatureClick';

import { useCharacters } from '@lib/core/characters/hooks';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useCommentsHandler } from '@lib/core/comments/hooks/useCommentsHandler';
import { useRecipes } from '@lib/core/productAttributes/hooks';
import { parseProductIdentities, parseProductInstance, parseProductPreparations } from '@lib/core/products/utils';
import { IS_FROM_PRODUCT_DETAILS_PAGE, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { createMobileKioskQRUrl, prependBasename, promotionsFilter } from '@lib/core/service/utils';
import { useFeedback, useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import RouteUtils from '@lib/tools/routes';
import {
  CHARACTERS_URL_PARAM,
  IS_FROM_KIOSK_QR_URL_PARAM,
  PRODUCT_CATEGORY_WINE,
  PROMOTION_LABEL_SLUG,
} from '@lib/tools/shared/helpers/consts';
import { useSustainability } from '@lib/tools/sustainability/hooks';
import { useAddons } from '@lib/tools/views/hooks';
import useGetProductInstance from '@lib/tools/views/hooks/useGetProductInstance';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import ProductPage from '@components/web/src/pages/ProductPage/ProductPage';

const ProductPageContainer: FC = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { locale } = useApp();
  const { isEnableLocationMapAddon, isEnableVusionAddon, isShowProductLocationAddon, isProductPDFAddon } = useAddons();
  const { isUserHasAnyCharacter } = useUser();
  const { handleAuthFeatureClick } = useAuthFeatureClick();

  const isFromKioskQR = !!searchParams.get(IS_FROM_KIOSK_QR_URL_PARAM);
  const { isFidelityCardScanned } = useFidelityCard();

  const { productInstanceData, isProductInstanceDataLoading } = useGetProductInstance();
  const { productCharacterId, productCategory, productPerfectFor, productFormatIdentifier } = parseProductInstance({
    locale,
    productInstanceData,
  });

  const { recipesData, isRecipesDataLoading } = useRecipes(productCharacterId, productCategory);
  const { sustainabilityData, isSustainabilityDataLoading } = useSustainability(
    productCategory,
    productFormatIdentifier,
  );

  const { feedbackData, handleUpdateFeedback } = useFeedback();
  const { wishlistProductInstanceIds, handleUpdateWishlistProductList, isWishlistProductListLoading } =
    useProductList();

  const { discoveryQuiz } = useDiscoveryQuizData();

  const { characters } = useCharacters();
  const translatedUserCharacterData = characters.find(character =>
    isCharacterByIdentifiers(character, [productCharacterId]),
  );

  // Comments data
  const {
    commentsList,
    handleSubmitComment,
    isCommentsLoaded,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
  } = useCommentsHandler();

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const { sortedRelatedCharacterEssences } = parseCharacterDetails({ character: translatedUserCharacterData });

  const mobileKioskQRUrl = createMobileKioskQRUrl();

  const preparationsData = useMemo(
    () =>
      productInstanceData &&
      parseProductPreparations({
        productInstanceData,
      }),
    [productInstanceData],
  );

  const productIdentitiesData = useMemo(
    () =>
      productInstanceData &&
      parseProductIdentities({
        locale,
        productInstanceData,
      }),
    [productInstanceData, locale],
  );

  const onBackButtonClick = () => {
    const paramSeparator = locationState?.from?.includes('?') ? '&' : '?';
    const url = `${locationState?.from}${paramSeparator}${IS_FROM_PRODUCT_DETAILS_PAGE}=true`;

    return isFromKioskQR || !locationState?.from ? navigate(prependBasename(PAGES.vinhood.catalog)) : navigate(url);
  };

  const navigateToCatalog = () =>
    navigate(prependBasename(PAGES.vinhood.catalog, { [CHARACTERS_URL_PARAM]: productCharacterId }));

  const isLoading =
    !productInstanceData ||
    isProductInstanceDataLoading ||
    !recipesData ||
    isRecipesDataLoading ||
    isSustainabilityDataLoading;

  // We don't reset catalog/filter data if we go from catalog to product page
  // That's why we should do it here, in case if we are navigating from product page to another page (not catalog)
  useEffect(() => {
    return () => {
      if (!RouteUtils.getPage().includes(PAGES.vinhood.catalog)) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
      }
    };
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <ProductPage
      commentsList={commentsList}
      discoveryQuiz={discoveryQuiz}
      essencesData={sortedRelatedCharacterEssences}
      feedback={feedbackFilter(feedbackData, productInstanceData)}
      handleAuthFeatureClick={handleAuthFeatureClick}
      handleSubmitComment={handleSubmitComment}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isApplicationKiosk={isApplicationKiosk}
      isCommentsLoaded={isCommentsLoaded}
      isEnableLocationMapAddon={isEnableLocationMapAddon}
      isEnableVusionAddon={isEnableVusionAddon}
      isNewCommentsDataFetching={isNewCommentsDataFetching}
      isProductInstanceInWishlist={isProductInstanceInWishlistFilter(wishlistProductInstanceIds, productInstanceData)}
      isProductPDFDownload={isProductPDFAddon && productCategory === PRODUCT_CATEGORY_WINE}
      isShowProductLocationAddon={isShowProductLocationAddon}
      isShowPromoLabel={!!promotionsFilter(productInstanceData?.promotions, [PROMOTION_LABEL_SLUG]).length}
      isUserHasAnyCharacter={isUserHasAnyCharacter}
      isWishlistProductListLoading={isWishlistProductListLoading}
      locale={locale}
      mobileKioskQRUrl={mobileKioskQRUrl}
      navigateToCatalog={navigateToCatalog}
      navigateToQuizSelectionPage={navigateToQuizSelectionPage}
      perfectForData={productPerfectFor}
      preparationsData={preparationsData}
      productCategory={productCategory}
      productIdentitiesData={productIdentitiesData}
      productInstanceData={productInstanceData}
      recipesData={recipesData}
      setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
      shouldHideComment={isApplicationKiosk}
      shouldHideFeedback={isApplicationKiosk}
      shouldHideWishlist={isApplicationKiosk && !isFidelityCardScanned}
      sustainabilityData={sustainabilityData}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default ProductPageContainer;
