import styled, { css } from 'styled-components';

import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';

export const CloseCardContainer = styled.div<{ $isVinhoodApp: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 56px;
  max-height: 56px;
  cursor: pointer;
  background-color: ${DEFAULT_COLOR_THEME.primaryColor[-800]};
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.07);

  ${({ $isVinhoodApp }) =>
    $isVinhoodApp &&
    css`
      background-color: ${STATIC_COLORS.green[600]};
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  color: ${STATIC_COLORS.base.white};
`;

export const StyledCloseButton = styled(CloseButton)`
  width: 28px;
  height: 28px;

  &::before,
  &::after {
    width: 11px;
  }
`;
