import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BannerCard, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import SectionHeader from '@components/web/src/atoms/Headers/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import ExperienceCard from '@components/web/src/organisms/Cards/ExperienceCard/ExperienceCard';
import ViewMoreCard from '@components/web/src/organisms/Cards/ViewMoreCard/ViewMoreCard';

interface IProps {
  experienceSectionData?: any;
  topBanner?: TBannerCard;
  handleBannerClick?: (route?: string, linkParams?: string) => void;
  navigateToExperienceSearch: (experienceTypeId: string) => void;
}

const ExperienceCatalog: FC<IProps> = ({
  experienceSectionData,
  topBanner,
  handleBannerClick,
  navigateToExperienceSearch,
}) => {
  const { experienceCatalog } = localeApp;

  const { publishedTerms } = LocaleUtils;

  const showMeMore = publishedTerms[experienceCatalog.showMeMore?.id];

  return (
    <div className="experience-catalog-container">
      <div className="heading-wrapper">
        <p className="catalog-title">
          <LocaleFragment message={experienceCatalog.titleHeading} />
        </p>
        {!!topBanner && <BannerCard {...topBanner} onClickHandler={handleBannerClick} />}
      </div>
      <div className="experiences-wrapper">
        {experienceSectionData?.map(({ sectionTitle, experiences, experienceTypeId }) => {
          const isOneCardSection = experiences.length === 1;
          return (
            experiences.length > 0 && (
              <div key={sectionTitle} className={`experience-section ${isOneCardSection ? 'one-card-section' : ''}`}>
                <SectionHeader title={sectionTitle} />
                {isOneCardSection ? (
                  <ExperienceCard isResponsive experienceData={experiences[0]} />
                ) : (
                  <SwiperHOC>
                    {experiences.slice(0, 3).map(experience => (
                      <ExperienceCard key={experience.identifier} experienceData={experience} />
                    ))}
                    {experiences.length > 3 && (
                      <ViewMoreCard
                        handleClick={() => navigateToExperienceSearch(experienceTypeId)}
                        icon="rocket"
                        text={`${showMeMore} ${sectionTitle}`}
                      />
                    )}
                  </SwiperHOC>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ExperienceCatalog;
