import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { BANNER_POSITION_TIP_AND_TRICKS } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { charactersByProductCategory } from '@lib/core/characters/utils';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { PRODUCT_CATEGORY_QUERY_KEY } from '@components/web/src/foundations/consts';
import HintPage from '@components/web/src/templates/HintPage/HintPage';

const HintPageContainer = () => {
  const navigate = useNavigate();
  const hintPageBanners = useBanners({
    bannerPosition: BANNER_POSITION_TIP_AND_TRICKS,
    returnAllBanners: true,
  });

  const { isUserHasAnyCharacter, userCharacters } = useUser();
  const userCharactersIndexedByProductCategory = charactersByProductCategory(userCharacters);
  const characterTypeBanners = useMemo(() => {
    const characterTypeBannersTemp = {
      [PRODUCT_CATEGORY_BEER]: [],
      [PRODUCT_CATEGORY_COFFEE]: [],
      [PRODUCT_CATEGORY_WINE]: [],
    };
    if (!isUserHasAnyCharacter) {
      return characterTypeBannersTemp;
    }
    hintPageBanners.forEach(banner => {
      const { link_params: linkParams } = banner;
      const productCategory = new URLSearchParams(linkParams).get(PRODUCT_CATEGORY_QUERY_KEY);
      switch (productCategory) {
        case PRODUCT_CATEGORY_WINE:
          if (userCharactersIndexedByProductCategory[PRODUCT_CATEGORY_WINE]) {
            characterTypeBannersTemp.wine.push(banner);
          }
          break;
        case PRODUCT_CATEGORY_BEER:
          if (userCharactersIndexedByProductCategory[PRODUCT_CATEGORY_BEER]) {
            characterTypeBannersTemp.beer.push(banner);
          }
          break;
        case PRODUCT_CATEGORY_COFFEE:
          if (userCharactersIndexedByProductCategory[PRODUCT_CATEGORY_COFFEE]) {
            characterTypeBannersTemp.coffee.push(banner);
          }
          break;
        default:
          break;
      }
    });
    return characterTypeBannersTemp;
  }, [hintPageBanners]);

  const handleBackButtonNavigate = () => {
    navigate(prependBasename(PAGES.vinhood.home));
  };

  return <HintPage characterBanners={characterTypeBanners} handleBackButtonClick={handleBackButtonNavigate} />;
};

export default HintPageContainer;
