import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { PrivacyAndTermsListItem } from '@components/web/src/app/Modals/PrivacyAndTermsListItem';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  isModalOpen: boolean;
  hideModal: () => void;
  shouldOpenModalsUnderHeader?: boolean;
};

const TermsConditionsModal: FC<Props> = ({ shouldOpenModalsUnderHeader, isModalOpen, hideModal }) => {
  const { termsConditionsModal } = localeApp;

  const {
    headingDescription,
    heading,
    // what is
    whatisVhHeading,
    whatisVhHeadingDesc,
    whatisVhHeadingDesc1,
    whatisVhHeadingDesc2,
    whatisVhHeadingDesc3,
    whatisVhHeadingDesc4,
    whatisVhHeadingDesc5,
    // general info
    generalInfoAndScopeSubHeading,
    generalInfoAndScopeHeading,
    generalInfoAndScopeDesc1,
    generalInfoAndScopeDesc2,
    generalInfoAndScopeDesc3,
    generalInfoAndScopeDesc4,
    generalInfoAndScopeDesc5,
    generalInfoAndScopeDesc6,
    // registration
    registrationHeading,
    registrationDesc1,
    registrationDesc2,
    registrationDesc3,
    registrationDesc4,
    registrationDesc5,
    registrationDesc6,
    registrationDesc7,
    // service suspension
    serviceSuspensionHeading,
    serviceSuspensionDesc1,
    serviceSuspensionDesc2,
    // app content
    appContentPropertyRightsHeading,
    appContentPropertyRightsDesc1,
    appContentPropertyRightsDesc2,
    appContentPropertyRightsDesc3,
    appContentPropertyRightsDesc4,
    // exclusion
    exclusionLiabilityHeading,
    exclusionLiabilityDesc1,
    exclusionLiabilityDesc2,
    exclusionLiabilityDesc3,
    exclusionLiabilityDesc4,
    exclusionLiabilityDesc5,
    exclusionLiabilityDesc6,
    // final provision
    finalProvisionHeading,
    finalProvisionDesc1,
    finalProvisionDesc2,
    finalProvisionDesc3,
  } = termsConditionsModal;

  const whatisVhHeadingDescriptionPoints = [
    whatisVhHeadingDesc1,
    whatisVhHeadingDesc2,
    whatisVhHeadingDesc3,
    whatisVhHeadingDesc4,
    whatisVhHeadingDesc5,
  ];

  const generalInfoDescriptionPoints = [
    generalInfoAndScopeDesc1,
    generalInfoAndScopeDesc2,
    generalInfoAndScopeDesc3,
    generalInfoAndScopeDesc4,
    generalInfoAndScopeDesc5,
    generalInfoAndScopeDesc6,
  ];

  const registrationDescriptionPoints = [
    registrationDesc1,
    registrationDesc2,
    registrationDesc3,
    registrationDesc4,
    registrationDesc5,
    registrationDesc6,
    registrationDesc7,
  ];

  const serviceSuspensionPoints = [serviceSuspensionDesc1, serviceSuspensionDesc2];

  const appContentPropertyRightsPoints = [
    appContentPropertyRightsDesc1,
    appContentPropertyRightsDesc2,
    appContentPropertyRightsDesc3,
    appContentPropertyRightsDesc4,
  ];

  const exclusionLiabilityPoints = [
    exclusionLiabilityDesc1,
    exclusionLiabilityDesc2,
    exclusionLiabilityDesc3,
    exclusionLiabilityDesc4,
    exclusionLiabilityDesc5,
    exclusionLiabilityDesc6,
  ];

  const finalProvisionPoints = [finalProvisionDesc1, finalProvisionDesc2, finalProvisionDesc3];

  return (
    <ModalWrapper
      fullWidthModal
      hideModal={hideModal}
      isOpen={isModalOpen}
      modalClassName={`${shouldOpenModalsUnderHeader ? 'under-header' : ''}`}
    >
      <div className="terms-conditions-modal-container">
        <div className="headingContainer">
          <div className="title">
            <LocaleFragment message={heading} />
          </div>
          <div className="description">
            <LocaleFragment message={headingDescription} />
          </div>
        </div>
        <section>
          <div className="heading">
            <LocaleFragment message={whatisVhHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={whatisVhHeadingDesc} />
            </span>
            <PrivacyAndTermsListItem
              descriptionPoints={whatisVhHeadingDescriptionPoints}
              keyName="whatisVhHeadingDescPoints"
            />
            <span>
              <LocaleFragment message={generalInfoAndScopeSubHeading} />
            </span>
          </div>
        </section>
        <section>
          <div className="description-container">
            <span className="heading">
              <LocaleFragment message={generalInfoAndScopeHeading} />
            </span>
            <PrivacyAndTermsListItem
              isOrderedList
              descriptionPoints={generalInfoDescriptionPoints}
              keyName="generalInfoDescriptionPoints"
            />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={registrationHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem
              isOrderedList
              className="registrationDescriptionPoints-container"
              descriptionPoints={registrationDescriptionPoints}
              keyName="registrationDescriptionPoints"
            />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={serviceSuspensionHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem
              isOrderedList
              descriptionPoints={serviceSuspensionPoints}
              keyName="serviceSuspensionPoints"
            />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={appContentPropertyRightsHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem
              isOrderedList
              descriptionPoints={appContentPropertyRightsPoints}
              keyName="appContentPropertyRightsPoints"
            />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={exclusionLiabilityHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem
              isOrderedList
              descriptionPoints={exclusionLiabilityPoints}
              keyName="exclusionLiabilityPoints"
            />
          </div>
        </section>
        <section className="last-section">
          <div className="heading">
            <LocaleFragment message={finalProvisionHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem
              isOrderedList
              descriptionPoints={finalProvisionPoints}
              keyName="finalProvisionPoints"
            />
          </div>
        </section>
      </div>
    </ModalWrapper>
  );
};

export default TermsConditionsModal;
