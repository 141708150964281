import { FC } from 'react';

import { ReactComponent as CubeIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_greencube.svg';
import { ReactComponent as DocumentIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_greendocument.svg';
import { ReactComponent as RocketIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_greenrocket.svg';
import Button from '@components/web/src/atoms/Buttons/Button';

type IconVariantType = 'cube' | 'rocket' | 'document';

type IconVariants = {
  [key in IconVariantType]: JSX.Element | null;
};

interface IProps {
  icon: IconVariantType;
  text: string;
  heightBox?: number;
  handleClick?: () => void;
}

const ViewMoreCard: FC<IProps> = ({ text, icon, heightBox, handleClick }) => {
  const icons: IconVariants = {
    cube: <CubeIcon />,
    document: <DocumentIcon />,
    rocket: <RocketIcon />,
  };
  const renderedIcon = icons[icon];
  return (
    <div className="view-more-card-container" style={{ height: heightBox }}>
      <div className="icon-container">{renderedIcon}</div>
      <Button
        size="sm"
        text={text}
        onClick={() => {
          if (handleClick) {
            handleClick();
          }
        }}
      />
    </div>
  );
};

export default ViewMoreCard;
