import styled from 'styled-components';

import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import { Text } from '@components/web/src/foundations/Text/Text';

export const TagsTabContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px 32px;
`;

export const GoBackButton = styled(BackButton)`
  justify-content: flex-start;
  width: 100%;
  padding: 8px 0 16px;
`;

export const TitleText = styled(Text)`
  margin-bottom: 4px;
`;

export const Icon = styled.span`
  height: fit-content;
  min-width: 24px;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: $b2c-gray-100;
`;
