import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQuizzes } from '@lib/core/quizzes/hooks';
import QuizUtils from '@lib/core/quizzes/utils';
import { QUIZ_TYPE_EXPERT, QUIZ_TYPE_TASTE } from '@lib/core/quizzes/utils/consts';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useCustomStyles } from '@lib/tools/views/hooks/useCustomStyles';
import { PAGES } from '@lib/tools/views/urls';

import sketches_restrictions_gluten from '@components/web/src/assets/legacy/b2b/widget/sketches_tastetest_expert_test.svg';
import sketches_tastetest_guide_me from '@components/web/src/assets/legacy/b2b/widget/sketches_tastetest_guide_me.svg';
import QuizPage from '@components/web/src/templates/TestFlowsPages/QuizPage/QuizPage';
import { EXPERT_QUIZ_PREFERENCE_SLUG, TASTE_QUIZ_PREFERENCE_SLUG } from '@components/web/src/widget/consts';

const QuizChooseExpertiseContainer: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { youGuideMe, answerYes, title } = localeWidget.expertQuizPage;

  const { productCategory, isDevToolsEnabled } = useApp();
  const { isDisableHomeAddon } = useAddons();
  const { isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = useCustomStyles();
  const { isQuizzesFetching } = useQuizzes();

  const [expertQuizPreference, setExpertQuizPreference] = useState(null);
  const isExpertQuizAvailable = !!QuizUtils.getQuiz(QUIZ_TYPE_EXPERT);
  const isTasteQuizAvailable = !!QuizUtils.getQuiz(QUIZ_TYPE_TASTE);
  const productCategoryUrlParam: any = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);

  useEffect(() => {
    if (!isQuizzesFetching && !isExpertQuizAvailable) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.chooseTaste, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }
    if (!isQuizzesFetching && !isTasteQuizAvailable) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.expert, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (expertQuizPreference === TASTE_QUIZ_PREFERENCE_SLUG) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.chooseTaste, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }

    if (expertQuizPreference === EXPERT_QUIZ_PREFERENCE_SLUG) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.expert, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }
  }, [productCategory, expertQuizPreference]);

  const answers = [
    {
      [TASTE_QUIZ_PREFERENCE_SLUG]: {
        image: sketches_tastetest_guide_me,
        tags: [],
        text: youGuideMe,
      },
    },
    {
      [EXPERT_QUIZ_PREFERENCE_SLUG]: {
        image: sketches_restrictions_gluten,
        tags: [],
        text: answerYes,
      },
    },
  ];

  const onGoBackClick = () =>
    navigate(prependBasename(PAGES.vinhood.home, { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam }));

  return (
    <QuizPage
      firstQuestion
      withoutProgressBar
      // @ts-ignore
      answers={answers}
      customColors={{ customQuizBackgroundColor, customSecondaryColor, customTextColor }}
      goBack={!isDisableHomeAddon ? onGoBackClick : null}
      isApplicationKiosk={isApplicationKiosk}
      isDesignSetVinhoodExperience={isDesignSetVinhoodExperience}
      isDevToolsEnabled={isDevToolsEnabled}
      progressPercent={0}
      question={title}
      onAnswerSelectHandler={id => setExpertQuizPreference(id)}
    />
  );
};

export default QuizChooseExpertiseContainer;
