import { FC, useEffect, useState } from 'react';

import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { IModalOption } from '@components/web/src/app/Modals/modalTypes';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { GeneralTermsCheckboxesComponent } from '@components/web/src/templates/Modals/GeneralTermsCheckboxesComponent/GeneralTermsCheckboxesComponent';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  isModalOpen: boolean;
  isLoading?: boolean;
  hideModal: () => void;
  handleSaveGeneralServiceTerms?: (values: IParsedServiceTerm[]) => void;
  handleGoBack?: () => void;
  shouldOpenModalsUnderHeader?: boolean;
  shouldDisplayBackButton?: boolean;
};

export const GeneralServiceTermsModal: FC<Props> = ({
  isModalOpen,
  isLoading,
  hideModal,
  handleSaveGeneralServiceTerms,
  shouldOpenModalsUnderHeader,
}) => {
  const {
    generalServiceTermsModal: { headerTitle, headerDescription, cookieOptionTitle, btnConfirm },
  } = localeCommon;

  const {
    serviceTermsListUpdateIsLoading,
    serviceTermsByCategory: { cookie, profiling },
  } = useServiceTerms();

  const [generalServiceTermsState, setGeneralServiceTermsState] = useState([cookie, profiling]);

  const handleSaveGeneralServiceTermsButton = () => {
    handleSaveGeneralServiceTerms(generalServiceTermsState);
  };

  const handleOnServiceTermToggle = ({ category }: IParsedServiceTerm | IModalOption) => {
    setGeneralServiceTermsState(
      generalServiceTermsState.map(term =>
        term?.category === category ? { ...term, is_selected: !term?.is_selected } : term,
      ),
    );
  };

  useEffect(() => {
    if (!serviceTermsListUpdateIsLoading) {
      setGeneralServiceTermsState(
        [cookie, profiling].map(term => ({ ...term, is_selected: term?.is_always_active || term?.is_selected })),
      );
    }
  }, [cookie, profiling]);

  return (
    <ModalWrapper
      hideModal={hideModal}
      isOpen={isModalOpen}
      modalClassName={`general-service-terms-modal-container ${shouldOpenModalsUnderHeader ? 'under-header' : ''}`}
    >
      <div className="top-section">
        <CloseButton handleClick={hideModal} />
        <p className="top-title">
          <LocaleFragment message={headerTitle} />
        </p>
        <p className="top-description">
          <LocaleFragment message={headerDescription} />
        </p>
      </div>

      <div className="bottom-section">
        <p className="bottom-title">
          <LocaleFragment message={cookieOptionTitle} />
        </p>
        <GeneralTermsCheckboxesComponent options={generalServiceTermsState} onToggle={handleOnServiceTermToggle} />
        <Button
          handleClick={handleSaveGeneralServiceTermsButton}
          size="sm"
          text={btnConfirm}
          variant={VH_VARIANTS.PRIMARY}
        />
      </div>
      {isLoading && <Spinner />}
    </ModalWrapper>
  );
};
