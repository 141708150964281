import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import * as S from '@components/web/src/atoms/ToggleButton/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

type Props = {
  isToggled: boolean;
  text?: ILocaleText;
  onToggle: () => void;
  textPosition?: 'left' | 'right';
  isDisabled?: boolean;
  isVariantModalOption?: boolean;
  toggleColor?: string;
  descriptionText?: string;
};

const ToggleButton: FC<Props> = ({
  toggleColor,
  isToggled,
  onToggle,
  text,
  textPosition = 'right',
  isDisabled,
  isVariantModalOption = false,
  descriptionText,
}) => (
  <Flexbox
    align={!descriptionText ? 'center' : 'flex-start'}
    data-testid={`ToggleButton-${isToggled}-${textPosition}-${isDisabled}-${toggleColor}`}
    direction="row"
    gap={8}
  >
    <S.SliderWrapper $isToggled={isToggled} $toggleColor={toggleColor} disabled={isDisabled} onClick={onToggle}>
      <input readOnly checked={isToggled} type="checkbox" />
      <span />
    </S.SliderWrapper>
    <S.TextWrapper $position={textPosition}>
      {text && (
        <Text
          color={STATIC_COLORS.base.black}
          size={isVariantModalOption ? 'body3' : 'body1'}
          text={text}
          weight="medium"
        />
      )}
      {descriptionText && <Text color={STATIC_COLORS.gray[500]} size="body1" text={descriptionText} />}
    </S.TextWrapper>
  </Flexbox>
);

export default ToggleButton;
