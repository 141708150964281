import { FC } from 'react';

import SpinnerWrapper from '@components/web/src/components/GenericSpinner/SpinnerWrapper';

const Spinner: FC = () => {
  return (
    <SpinnerWrapper portalClassName="spinner-container">
      <div className="spinner-event">
        <div className="content">
          <div className="spinner" />
        </div>
      </div>
    </SpinnerWrapper>
  );
};

export default Spinner;
