import { TProductInstance } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CATEGORY_COFFEE } from '@lib/tools/shared/helpers/consts';

type IPreparationsProps = {
  productInstanceData: TProductInstance;
};

export const parseProductPreparations = ({ productInstanceData }: IPreparationsProps) => {
  const { productPage } = localeCommon;

  const preparationsClassicText = productPage.preparationsClassic;
  const preparationsSpecialText = productPage.preparationsSpecial;

  const { character, product: { category: productCategory = '' } = {} } = productInstanceData;

  const preparationsData =
    productCategory === PRODUCT_CATEGORY_COFFEE
      ? [
          {
            description: character?.preparationClassic?.technicalCaption,
            image: character?.preparationClassic?.metadata?.thumbnail,
            subtitle: preparationsClassicText,
            title: character?.preparationClassic?.characterName,
          },
          {
            description: character?.preparationSpecialty?.technicalCaption,
            image: character?.preparationSpecialty?.metadata?.thumbnail,
            subtitle: preparationsSpecialText,
            title: character?.preparationSpecialty?.characterName,
          },
        ]
      : [];

  return preparationsData;
};
