import { FC, Fragment } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import arrowIcon from '@components/web/src/assets/legacy/icons/arrow_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/TastePathGuide/styles';

interface IProps {
  isDisabledBtn?: boolean;
  isResultPage?: boolean;
  characterName: string;
  onHandleClick?: () => void;
}

const TastePathGuide: FC<IProps> = ({ characterName, isDisabledBtn, onHandleClick, isResultPage = false }) => {
  const {
    tastePathDiscoverHint,
    tastePathDirectionHint,
    tastePathFeedbackHint,
    subTitle,
    title,
    footerText,
    footerTitle,
    tastePathPageButtonText,
  } = localeCommon.tastePathStartPage;

  const hintTextList = [tastePathDiscoverHint, tastePathDirectionHint, tastePathFeedbackHint];

  return (
    <Flexbox isFullWidth data-testid="TastePathGuide" direction="column" gap={6} padding="0 16px 32px">
      {isResultPage && <S.StyledTextTitle fontFamily="Fraunces" size="h5" text={title} weight="semibold" />}
      <S.StyledTextSubtitle color={STATIC_COLORS.green[600]} size="body1" text={subTitle} weight="semibold" />
      <Flexbox align="center" direction="column" gap={6}>
        {hintTextList?.map((hint, index) => (
          <Fragment key={index}>
            <S.HintItem>
              <Text localeOptions={{ characterName }} size="body1" text={hint} />
            </S.HintItem>
            {index !== hintTextList.length - 1 && <img alt="arrow" className="arrow" src={arrowIcon} />}
          </Fragment>
        ))}
      </Flexbox>
      {!isResultPage && (
        <Flexbox direction="column" gap={12}>
          <S.TextWrapper direction="column" gap={0}>
            <Text color={STATIC_COLORS.green[600]} size="body2" text={footerTitle} weight="semibold" />
            <Text size="body2" text={footerText} />
          </S.TextWrapper>
          <Button
            disabled={isDisabledBtn}
            size="sm"
            text={tastePathPageButtonText}
            variant={VH_VARIANTS.PRIMARY}
            onClick={onHandleClick}
          />
        </Flexbox>
      )}
    </Flexbox>
  );
};

export default TastePathGuide;
