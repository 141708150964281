import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

interface IProps {
  title: ILocaleText;
  subtitle?: ILocaleText;
  className?: string;
}

const SectionHeader: FC<IProps> = ({ title, subtitle, className }) => (
  <div className={`app-section-header ${className}`}>
    <p className="title">
      <LocaleFragment message={title} />
    </p>
    {subtitle && (
      <span className="subtitle">
        <LocaleFragment message={subtitle} />
      </span>
    )}
  </div>
);

export default SectionHeader;
