import { TokenResponse } from '@react-oauth/google';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { EMAIL } from '@lib/tools/shared/helpers/consts';

import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import GoogleLoginButton from '@components/web/src/organisms/Authentication/GoogleLoginButton/GoogleLoginButton';
import * as S from '@components/web/src/templates/Login&Registration/SocialRegistrationPrompt/styles';

interface IProps {
  hidePrompt: () => void;
  responseGoogle: (response: TokenResponse) => void;
  handleEmailClick: () => void;
  isVisible: boolean;
}

const SocialRegistrationPrompt = ({ hidePrompt, responseGoogle, isVisible, handleEmailClick }: IProps) => {
  const { googleContinueSignup, emailSignup } = localeCommon.authOptions;
  const { title } = localeCommon.socialRegistrationPrompt;
  return (
    <S.SocialRegistrationPromptWrapper $visible={isVisible} data-testid="SocialRegistrationPromptWrapper">
      <S.SocialRegistrationPromptContents direction="column" gap={12}>
        <Flexbox justify="space-between">
          <Text fontFamily="Fraunces" size="subtitle1" text={title} weight="semibold" />
          <S.Icon align="center" justify="center">
            <CloseButton handleClick={hidePrompt} />
          </S.Icon>
        </Flexbox>
        <Flexbox direction="column">
          <GoogleLoginButton isRegistrationPage={false} responseGoogle={responseGoogle} text={googleContinueSignup} />
          {/* TODO: Add Apple Login CTA Later  */}
          <AuthorizationButton
            key={EMAIL}
            handleClick={handleEmailClick}
            text={emailSignup}
            type={EMAIL}
            variant="secondary"
          />
        </Flexbox>
      </S.SocialRegistrationPromptContents>
    </S.SocialRegistrationPromptWrapper>
  );
};

export default SocialRegistrationPrompt;
