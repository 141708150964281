import L from 'leaflet';
import { FC } from 'react';
import { Marker, TileLayer } from 'react-leaflet';

import { TProductCategory } from '@lib/core/products/types';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import exploreBeerIcon from '@components/web/src/assets/icons/explore/explore_beer_icon.svg';
import exploreCoffeeIcon from '@components/web/src/assets/icons/explore/explore_coffee_icon.svg';
import exploreCurrentBeerIcon from '@components/web/src/assets/icons/explore/explore_current_beer_icon.svg';
import exploreCurrentCoffeeIcon from '@components/web/src/assets/icons/explore/explore_current_coffee_icon.svg';
import exploreCurrentWineIcon from '@components/web/src/assets/icons/explore/explore_current_wine_icon.svg';
import exploreWineIcon from '@components/web/src/assets/icons/explore/explore_wine_icon.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/MapCard/styles';

import 'leaflet/dist/leaflet.css';

export interface IMapLocation {
  address: string;
  coordinates: number[] | null;
  isCurrentLocation: boolean;
  shouldUseCurrentLocationIcon?: boolean;
}

interface IProps {
  productCategory: TProductCategory;
  mapLocationList: IMapLocation[];
  mapHeight?: number;
}

export const MapCard: FC<IProps> = ({ productCategory, mapLocationList, mapHeight = 0 }) => {
  const currentRetailerLocationCoordinates = mapLocationList?.find(location => location.isCurrentLocation)?.coordinates;

  return (
    <S.CustomMapContainer
      zoomControl
      $mapHeight={mapHeight}
      attributionControl={false}
      center={currentRetailerLocationCoordinates as [number, number]}
      scrollWheelZoom={false}
      zoom={12}
    >
      <S.MapContainerShadow />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {mapLocationList?.map(({ address, coordinates, isCurrentLocation, shouldUseCurrentLocationIcon }, idx) => {
        const icons =
          isCurrentLocation && shouldUseCurrentLocationIcon
            ? {
                [PRODUCT_CATEGORY_BEER]: exploreCurrentBeerIcon,
                [PRODUCT_CATEGORY_COFFEE]: exploreCurrentCoffeeIcon,
                [PRODUCT_CATEGORY_WINE]: exploreCurrentWineIcon,
              }
            : {
                [PRODUCT_CATEGORY_BEER]: exploreBeerIcon,
                [PRODUCT_CATEGORY_COFFEE]: exploreCoffeeIcon,
                [PRODUCT_CATEGORY_WINE]: exploreWineIcon,
              };

        const customIconSettings = L.icon({
          iconAnchor: [16, 0],
          iconSize: [32, 32],
          iconUrl: icons[productCategory],
          popupAnchor: [0, 3],
        });

        return (
          <Marker key={idx} icon={customIconSettings} position={coordinates as [number, number]}>
            {address && (
              <S.CustomPopup closeButton={false}>
                <Text color={STATIC_COLORS.base.white} size="body2" text={address} weight="medium" />
              </S.CustomPopup>
            )}
          </Marker>
        );
      })}
    </S.CustomMapContainer>
  );
};
