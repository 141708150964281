export const parseExploreLocation = data => {
  const {
    identifier = '',
    store_type: storeType,
    producer,
    slug = '',
    phone_number: phone = '',
    email = '',
    description = '',
    images = [],
    location,
    taste_match: tasteMatch = null,
    catalog_type: catalogType = '',
    associated_kiosk_retailer_location: associatedKioskRetailerLocation,
  } = data || {};
  const exploreLocationLogo = producer?.image || '';
  const exploreLocationName = producer?.name || '';
  const exploreLocationWebsite = producer?.website || '';
  const exploreLocationAddress = location?.address || '';
  const exploreLocationProducerId = producer?.identifier || '';
  const { latitude = '', longitude = '' } = location?.location || {};
  const exploreLocationCoordinates = latitude && longitude ? [Number(latitude), Number(longitude)] : null;

  return {
    associatedRetailerLocation: associatedKioskRetailerLocation,
    exploreLocationAddress,
    exploreLocationCatalogType: catalogType,
    exploreLocationCoordinates,
    exploreLocationDescription: description,
    exploreLocationEmail: email,
    exploreLocationId: identifier,
    exploreLocationImages: images,
    exploreLocationLogo,
    exploreLocationName,
    exploreLocationPhone: phone,
    exploreLocationProducerId,
    exploreLocationSlug: slug,
    exploreLocationStoreType: storeType,
    exploreLocationTasteMatch: tasteMatch,
    exploreLocationWebsite,
  };
};
