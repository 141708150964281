import styled from 'styled-components';

import { TTabsGradientVariant } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { DEFAULT_COLOR_THEME, STATIC_COLORS, STATIC_GRADIENTS, customGradient } from '@components/web/src/foundations';

export const TabsContainer = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: ${DEFAULT_COLOR_THEME.secondaryColor['-100']};
`;

export const TabsWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`;

export const Tabs = styled.div<{
  $gradientVariant: TTabsGradientVariant;
  $isHiddenTabs: boolean;
  $isAutoSpacedTabs: boolean;
}>`
  display: flex;
  flex-flow: row nowrap;
  gap: 32px;
  align-items: flex-end;
  padding: 16px 16px 0;
  overflow-y: auto;
  background: ${({ $gradientVariant }) => {
    if ($gradientVariant === 'default-up') {
      return STATIC_GRADIENTS.warmGradientReversed();
    }
    if ($gradientVariant === 'default-down') {
      return STATIC_GRADIENTS.warmGradient();
    }
    if ($gradientVariant === 'custom-up') {
      return customGradient('var(--color-secondary-100)', 'var(--color-secondary-25)');
    }
    return customGradient('var(--color-secondary-100)', 'var(--color-secondary-25)');
  }};

  ${({ $isHiddenTabs }) => $isHiddenTabs && `display: none`};
  ${({ $isAutoSpacedTabs }) => $isAutoSpacedTabs && `justify-content: space-between`};

  /* Hide scrollbar for WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Safari and Internet Explorer 10+ */
  -ms-overflow-style: none;
`;

export const Divider = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid ${STATIC_COLORS.warmGray[300]};
`;
