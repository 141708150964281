import { FC, useEffect, useState } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import productCardIcon from '@components/web/src/assets/icons/productCardIcon.svg';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Authentication/AuthBanners/RegisterDetailsBanner/styles';

interface IProps {
  title: ILocaleText;
  description: ILocaleText;
  extraDescription?: ILocaleText;
  icon?: boolean;
  isAnimated?: boolean;
}

const RegisterDetailsBanner: FC<IProps> = ({
  title,
  description,
  icon = false,
  isAnimated = false,
  extraDescription,
}) => {
  const [startAnimation, setStartAnimation] = useState(isAnimated);

  useEffect(() => {
    // to hide confetti animation
    const timer = setTimeout(() => setStartAnimation(null), 2000);

    return () => {
      clearTimeout(timer);
      setStartAnimation(null);
    };
  }, [startAnimation]);

  return (
    <S.RegisterDetailsBannerContainer data-testid="RegisterDetailsBanner">
      {startAnimation && <Confetti />}
      <S.Header>
        <Text color={STATIC_COLORS.base.white} fontFamily="Fraunces" size="h4" text={title} weight="semibold" />
        {icon && <img alt="productCardIcon" src={productCardIcon} />}
        <S.StyledText color={STATIC_COLORS.base.white} size="body1" text={description} weight="medium" />
        {extraDescription && (
          <Text color={STATIC_COLORS.base.white} size="body1" text={extraDescription} weight="medium" />
        )}
      </S.Header>
    </S.RegisterDetailsBannerContainer>
  );
};

export default RegisterDetailsBanner;
