import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

type Props = {
  perfectFor: string;
};

const PerfectFor: FC<Props> = ({ perfectFor }) => {
  const { perfectForText } = localeApp;

  return (
    <div className="perfect-for-container">
      <span className="perfect-for-title">
        <LocaleFragment message={perfectForText.perfectForTitleText} />
      </span>
      <div className="perfect-for-text-container">
        <span>
          <LocaleFragment message={perfectFor} />
        </span>
      </div>
    </div>
  );
};

export default PerfectFor;
