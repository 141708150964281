import { Font, StyleSheet } from '@react-pdf/renderer';

import { STATIC_COLORS } from '@components/web/src/foundations';
// Fraunces Font Imports
import frauncesFontBold from '@components/web/src/styles/assets/fonts/Fraunces-Bold.ttf';
import frauncesFontMedium from '@components/web/src/styles/assets/fonts/Fraunces-Medium.ttf';
import frauncesFontRegular from '@components/web/src/styles/assets/fonts/Fraunces-Regular.ttf';
import frauncesFontSemiBold from '@components/web/src/styles/assets/fonts/Fraunces-SemiBold.ttf';
// Montserrat Font Imports
import montserratFontBold from '@components/web/src/styles/assets/fonts/Montserrat-Bold.ttf';
import montserratFontMedium from '@components/web/src/styles/assets/fonts/Montserrat-Medium.ttf';
import montserratFontRegular from '@components/web/src/styles/assets/fonts/Montserrat-Regular.ttf';
import montserratFontSemibold from '@components/web/src/styles/assets/fonts/Montserrat-SemiBold.ttf';

Font.register({
  family: 'Fraunces',
  fonts: [
    { src: frauncesFontRegular },
    { fontWeight: 700, src: frauncesFontBold },
    { fontWeight: 600, src: frauncesFontSemiBold },
    { fontWeight: 500, src: frauncesFontMedium },
  ],
  format: 'truetype',
});

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: montserratFontRegular },
    { fontWeight: 700, src: montserratFontBold },
    { fontWeight: 600, src: montserratFontSemibold },
    { fontWeight: 500, src: montserratFontMedium },
  ],
  format: 'truetype',
});

export const typographyFamilyForPDF = StyleSheet.create({
  fraunces: {
    fontFamily: 'Fraunces',
  },
  montserrat: {
    fontFamily: 'Montserrat',
  },
});

// Typo Styles Setup for PDF
export const typographyFontsForPDF = StyleSheet.create({
  frauncesBody1Bold: {
    color: STATIC_COLORS.base.black,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '129%',
    ...typographyFamilyForPDF.fraunces,
  },
  frauncesSubtitle1Bold: {
    color: STATIC_COLORS.base.black,
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '133%',
    ...typographyFamilyForPDF.fraunces,
  },
  frauncesSubtitle2Bold: {
    color: STATIC_COLORS.base.black,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '125%',
    ...typographyFamilyForPDF.fraunces,
  },
  mpBody1Bold: {
    color: STATIC_COLORS.base.black,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '128%',
    ...typographyFamilyForPDF.montserrat,
  },
  mpBody1Regular: {
    color: STATIC_COLORS.base.black,
    fontSize: '14px',
    lineHeight: '128%',
    ...typographyFamilyForPDF.montserrat,
  },
  mpBody1SemiBold: {
    color: STATIC_COLORS.base.black,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '128%',
    ...typographyFamilyForPDF.montserrat,
  },
  mpBody2Medium: {
    ...typographyFamilyForPDF.montserrat,
    color: STATIC_COLORS.base.black,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '133%',
  },
  mpBody2Regular: {
    ...typographyFamilyForPDF.montserrat,
    color: STATIC_COLORS.base.black,
    fontSize: '12px',
    lineHeight: '133%',
  },
  mpBody2Semibold: {
    ...typographyFamilyForPDF.montserrat,
    color: STATIC_COLORS.base.black,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '133%',
  },
  mpBody3Medium: {
    ...typographyFamilyForPDF.montserrat,
    color: STATIC_COLORS.base.black,
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '140%',
  },
  mpBody3Regular: {
    ...typographyFamilyForPDF.montserrat,
    color: STATIC_COLORS.base.black,
    fontSize: '10px',
    lineHeight: '140%',
  },
});
