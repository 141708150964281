import { Navigate, Outlet } from 'react-router-dom';

import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { PAGES } from '@lib/tools/views/urls';

export const AuthRoutes = () => {
  const { isUserAuthenticated, isUserHasAnyCharacter } = useUser();

  const redirectTo = isUserHasAnyCharacter
    ? prependBasename(PAGES.vinhood.signIn)
    : prependBasename(PAGES.vinhood.start);

  return isUserAuthenticated ? <Outlet /> : <Navigate replace to={redirectTo} />;
};

export const AnonRoutes = () => {
  const { isUserAuthenticated } = useUser();
  const redirectTo = prependBasename(PAGES.vinhood.home);
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();

  return isUserAuthenticated && !isServiceInstanceFeatureEnabled ? <Navigate replace to={redirectTo} /> : <Outlet />;
};
