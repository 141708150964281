import { FC } from 'react';

import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { IRegisterPageServiceTerms } from '@lib/tools/shared/auths/views/containers/RegistrationContainer';

import Checkbox from '@components/web/src/components/Checkbox/Checkbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  handleOnServiceTermToggle: (term: IParsedServiceTerm) => void;
  registerPageServiceTerms: IRegisterPageServiceTerms;
}

export const RegisterPageServiceTermsCheckboxes: FC<IProps> = ({
  handleOnServiceTermToggle,
  registerPageServiceTerms,
}) => {
  const { marketing, policy } = registerPageServiceTerms;

  return (
    <div className="legal-agreement-checkboxes">
      <Checkbox
        id="policy"
        isSelected={policy?.is_selected || false}
        label={<Text size="body1" text={policy?.text} weight="medium" />}
        onChange={() => handleOnServiceTermToggle(policy)}
      />
      <Checkbox
        id="marketing"
        isDisabled={!policy?.is_selected}
        isSelected={marketing?.is_selected || false}
        label={<Text size="body1" text={marketing?.text} weight="medium" />}
        onChange={() => handleOnServiceTermToggle(marketing)}
      />
    </div>
  );
};
