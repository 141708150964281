import styled, { css, keyframes } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

export const CheckboxAnswer = styled.button<{ $isSelected: boolean; $isResponsive?: boolean }>`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 60px;
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '163px')};
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.md};
  background-color: ${({ $isSelected }) => ($isSelected ? STATIC_COLORS.gray[700] : STATIC_COLORS.base.white)};
`;

export const Icon = styled.div<{ $isSelected: boolean }>`
  position: relative;
  border-radius: 8px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border: 1px solid
    ${({ $isSelected, theme }) => ($isSelected ? theme?.colors?.primaryColor['-600'] : STATIC_COLORS.gray[300])};
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme?.colors?.primaryColor['-100'] : STATIC_COLORS.base.white};

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        border: none;
        border-radius: 1em;
        background-color: ${theme?.colors?.primaryColor['-600']};
        width: 6px;
        height: 6px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation-name: ${popIn};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      }
    `};
`;
