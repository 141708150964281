import { StyleSheet } from '@react-pdf/renderer';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { typographyFontsForPDF } from '@components/web/src/foundations/ReactPDF/styles';

// Create styles
export const borderLineStyles = StyleSheet.create({
  borderLine: {
    backgroundColor: 'black',
    minHeight: '1px',
    minWidth: '100%',
  },
});

export const featureItemStyles = StyleSheet.create({
  featureItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    paddingBottom: 8,
    width: '100%',
  },
});

export const tagItemStyles = StyleSheet.create({
  tagItemContainer: {
    alignItems: 'center',
    border: `1px solid ${STATIC_COLORS.base.black}`,
    borderRadius: '50%',
    display: 'flex',
    height: '25px',
    justifyContent: 'center',
    width: '25px',
  },
  tagText: {
    fontSize: '6px',
    fontWeight: 'semibold',
  },
});

export const productDetailsPdfStyles = StyleSheet.create({
  appLogoImage: {
    height: '18px',
    objectFit: 'contain',
    width: '110px',
  },
  featuresListContainer: {
    display: 'flex',
    gap: 6,
    width: '100%',
  },
  featuresListWrapper: {
    display: 'flex',
    gap: 16,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  flexRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  page: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '2px solid black',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '12px 20px 28px',
    width: '100%',
  },
  pdfWrapper: {
    textDecoration: 'none',
  },
  perfectSituationFlexWrapper: {
    display: 'flex',
    gap: 16,
  },
  productDetailsLeftSection: {
    display: 'flex',
    gap: 32,
    maxWidth: '175px',
    minWidth: '175px',
  },
  productDetailsSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    width: '100%',
  },
  productImage: {
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  },
  productImageSection: {
    height: '195px',
    minWidth: '195px',
    position: 'relative',
    width: '195px',
  },
  productOwnerImage: {
    maxHeight: '50px',
    maxWidth: '152px',
    minHeight: '50px',
    minWidth: '152px',
    objectFit: 'contain',
  },
  productOwnerImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  qrCodeSection: {
    backgroundColor: STATIC_COLORS.productColors.wine['500'],
    borderRadius: '6px',
    bottom: '23px',
    left: '8px',
    minHeight: '146px',
    padding: 4,
    position: 'absolute',
    width: '113px',
  },
  qrContentWrapper: {
    backgroundColor: '#E3E3E3',
    borderRadius: '10px',
    display: 'flex',
    maxHeight: '118px',
    minHeight: '118px',
    position: 'relative',
  },
  qrImageSection: {
    height: '105px',
    marginHorizontal: 'auto',
    objectFit: 'cover',
    width: '105px',
  },
  recommendedSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: 4,
    width: '100%',
  },

  retailerName: {
    ...typographyFontsForPDF.mpBody1SemiBold,
    textTransform: 'capitalize',
  },
  rightSideDetailsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '91px',
    width: '100%',
  },
  scanInfoText: {
    ...typographyFontsForPDF.mpBody1Bold,
    color: 'white',
    marginTop: 'auto',
    paddingVertical: '9px 10px',
    textAlign: 'center',
  },
  scanLogoSection: {
    display: 'flex',
    gap: '2px',
  },
  scanTextSection: {
    display: 'flex',
    gap: 14,
    padding: '18px 17px 16px 137px',
  },
  tagsContainer: {
    bottom: 0,
    display: 'flex',
    gap: 8,
    position: 'absolute',
  },
  viewSection: {
    flexGrow: 1,
    width: '100%',
  },
});

export const recommendationListItemStyles = StyleSheet.create({
  recommendedContent: {
    display: 'flex',
    width: '100%',
  },
  recommendedImageSection: {
    maxHeight: '55px',
    maxWidth: '89px',
    minHeight: '55px',
    minWidth: '89px',
  },
  recommendedWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-start',
    width: '100%',
  },
});

export const progressBarStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  progressBar: {
    backgroundColor: STATIC_COLORS.base.black,
    borderRadius: 4,
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
    position: 'absolute',
  },
  progressBarContainer: {
    backgroundColor: 'rgb(35 32 33 / 10%)',
    borderRadius: 4,
    display: 'flex',
    maxHeight: '6px',
    minHeight: '6px',
    position: 'relative',
    width: '100%',
  },
  progressBarTextContainer: {
    maxWidth: '100px',
    minWidth: '100px',
  },
});
