import styled, { css } from 'styled-components';

import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import { SCREEN_SIZE } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const QuizPageContainer = styled(Flexbox)<{ $background?: string; $withoutProgressBar: boolean }>`
  background-color: ${({ $background }) => $background || 'var(--color-secondary-100)'};

  ${({ theme, $withoutProgressBar }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 60px;
      padding: ${$withoutProgressBar ? '200px 90px' : '80px 90px 200px'};
    `}
`;

export const ProgressBarWrapper = styled.div`
  width: ${({ theme }) => (theme?.isKioskRootElementType ? '536px' : '279px')};
`;

export const AnswersContainer = styled(Flexbox)`
  flex-flow: row wrap;
  gap: 24px 16px;
  max-width: 343px;
  margin: 0 auto;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 140px 24px;
      max-width: ${SCREEN_SIZE.desktopKiosk};
    `}
`;

export const GoBackButton = styled(BackButton)`
  margin: 0 auto;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      margin: 92px auto 0;
    `}
`;
