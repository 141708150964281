import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as ArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/bannerdropdownwhite.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/MyTasteProfileCard/ProfileProgressBar/styles';
import ProgressBar from '@components/web/src/organisms/ProgressBar/ProgressBar';

export type TProgressBarCardVariant = 'default' | 'detached';

type Props = {
  variant?: TProgressBarCardVariant;
  title: ILocaleText;
  description?: ILocaleText;
  progress: number;
  onClick: () => void;
};

const ProfileProgressBar: FC<Props> = ({ variant = 'default', title, description, progress, onClick }) => {
  return (
    <S.ProfileProgressBarContainer aria-hidden $variant={variant} data-testid="ProfileProgressBar" onClick={onClick}>
      <S.LeftSectionWrapper $variant={variant}>
        <Text color={STATIC_COLORS.base.white} size="body1" text={title} weight="bold" />
        <ProgressBar progressPercentage={progress} variant={VH_VARIANTS.LIGHT} />
        {description && <Text color={STATIC_COLORS.base.white} size="body3" text={description} weight="medium" />}
      </S.LeftSectionWrapper>
      <div>
        <ArrowIcon />
      </div>
    </S.ProfileProgressBarContainer>
  );
};

export default ProfileProgressBar;
