import { Dispatch, FC, SetStateAction } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TJournalProductPanelFilter, TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { FILTER_TYPE_RATING, PRODUCT_CARD_VARIANTS, TJournalPlacesPanelFilter } from '@lib/tools/shared/helpers/consts';

import * as S from '@components/web/src/app/Journal/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { DESKTOP_RESOLUTION_MIN_VALUE } from '@components/web/src/foundations/consts';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import EmptyStateCard from '@components/web/src/organisms/EmptyStateCard/EmptyStateCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  feedbackData?: IFeedbackData[];
  isProductsRequestLoading: boolean;
  itemsCount: number;
  locale: string;
  products: TProductInstance[];
  isAllProductsLoaded?: boolean;
  storeType: TRetailerLocationStoreType;
  wishlistProductInstanceIds: string[];
  isWishlistProductListLoading?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  productCategory: TProductCategory;
  activeJournalPanelFilter: TJournalProductPanelFilter | TJournalPlacesPanelFilter;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  shouldHideComment?: boolean;
  navigateToCatalogPage: () => void;
  handleProductsRequest: ({ isPagination }: { isPagination?: boolean }) => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string, productName?: string) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

const JournalProductsList: FC<IProps> = ({
  feedbackData,
  isAllProductsLoaded,
  isProductsRequestLoading,
  itemsCount,
  locale,
  products,
  storeType,
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  discoveryQuiz,
  productCategory,
  activeJournalPanelFilter,
  commentsList,
  isCommentsLoaded,
  isNewCommentsDataFetching,
  shouldHideComment,
  navigateToCatalogPage,
  handleProductsRequest,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
}) => {
  const {
    journalPage: { isEndOfProductsList },
    emptyStateCards: { noRatedProductTitle, noSavedProductTitle },
  } = localeCommon;
  const loader = (
    <S.SkeletonsWrapper>
      <ProductCard key={1} isLoading locale={locale} storeType={storeType} />
      <ProductCard key={2} isLoading locale={locale} storeType={storeType} />
    </S.SkeletonsWrapper>
  );
  const isDesktopResolution = useMediaQuery({ minWidth: DESKTOP_RESOLUTION_MIN_VALUE });

  const isEndOfProductList = isAllProductsLoaded && !!products.length;
  const isNoAvailableProducts = isAllProductsLoaded && !products.length;
  const isProductsLoading = !products.length && isProductsRequestLoading;
  const noProductsTitle = activeJournalPanelFilter === FILTER_TYPE_RATING ? noRatedProductTitle : noSavedProductTitle;

  return (
    <S.JournalProductListWrapper data-testid="JournalProductsList">
      <InfiniteScroll
        dataLength={products.length}
        hasMore={products.length < itemsCount}
        loader={loader}
        next={isProductsRequestLoading && !products.length ? null : () => handleProductsRequest({ isPagination: true })}
        scrollableTarget={isDesktopResolution ? 'root-scroll-element' : null}
      >
        {isProductsLoading && loader}
        {products?.map((productInstanceData, index) => (
          <ProductCard
            key={productInstanceData.identifier}
            isResponsive
            commentsList={commentsList}
            discoveryQuiz={discoveryQuiz}
            feedback={feedbackFilter(feedbackData, productInstanceData)}
            handleSubmitComment={handleSubmitComment}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isCommentsLoaded={isCommentsLoaded}
            isLoading={false}
            isNewCommentsDataFetching={isNewCommentsDataFetching}
            isWishlistProductListLoading={isWishlistProductListLoading}
            locale={locale}
            mixpanelIndex={index}
            mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
            productInstanceData={productInstanceData}
            setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
            shouldHideComment={shouldHideComment}
            storeType={storeType}
            variant={PRODUCT_CARD_VARIANTS.COMPACT}
            isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
              wishlistProductInstanceIds,
              productInstanceData,
            )}
          />
        ))}
        {isNoAvailableProducts && (
          <S.NoProductsWrapper>
            <EmptyStateCard
              handleBtnClick={navigateToCatalogPage}
              productCategory={productCategory}
              title={noProductsTitle}
              variant="product"
            />
          </S.NoProductsWrapper>
        )}
        {isEndOfProductList && (
          <S.EndOfList>
            <Text color={STATIC_COLORS.gray['400']} size="body1" text={isEndOfProductsList} />
          </S.EndOfList>
        )}
      </InfiniteScroll>
    </S.JournalProductListWrapper>
  );
};

export { JournalProductsList };
