import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import { isLocationInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';

import CTAButton from '@components/web/src/app/CTAIconBtn/CTAButton/CTAButton';
import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/icons/icon_search.svg';
import InputField from '@components/web/src/components/Input/InputFields';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { IMapLocation, MapCard } from '@components/web/src/organisms/Cards/MapCard/MapCard';
import { PlacesCard } from '@components/web/src/organisms/Cards/PlacesCard/PlacesCard';
import * as S from '@components/web/src/templates/ExploreListPage/styles';

interface IProps {
  productCategory: TProductCategory;
  value?: string;
  locationList: ILocation[];
  exploreLocationList: ILocation[];
  mapLocationList: IMapLocation[];
  isLocationListLoading: boolean;
  currentRetailerLocationSlug: string;
  navigateToExplorePage: () => void;
  searchHandler?: (searchText: string) => void;
  handleUpdateLocationList: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
}

export const ExploreListPage: FC<IProps> = ({
  productCategory,
  value,
  locationList,
  exploreLocationList,
  mapLocationList,
  isLocationListLoading,
  currentRetailerLocationSlug,
  navigateToExplorePage,
  searchHandler,
  handleUpdateLocationList,
}) => {
  const { listTitle, listSubtitle, searchPlaces } = localeWidget.explorePage;
  const productCategoryText = localeCommon.productCategories[productCategory];
  const searchPlacesText = LocaleUtils.publishedTerms[searchPlaces.id];

  return (
    <S.ExploreListPageContainer data-testid="ExplorePage">
      <CTAButton
        btnIcon="leftarrow"
        btnText={localeCommon.commonMessages.back}
        handleBtnClick={navigateToExplorePage}
      />
      {false && (
        <S.InputWrapper>
          <InputField
            icon={<SearchIcon />}
            name="search-recipe"
            placeholder={searchPlacesText}
            value={value}
            onChange={e => searchHandler(e.target.value)}
          />
        </S.InputWrapper>
      )}
      {!!mapLocationList?.length && (
        <MapCard mapHeight={175} mapLocationList={mapLocationList} productCategory={productCategory} />
      )}
      <S.ContentWrapper $withExtraMargin={searchHandler && !!mapLocationList?.length}>
        <Flexbox direction="column" gap={0}>
          <Text
            color={STATIC_COLORS.productColors[productCategory][500]}
            size="subtitle2"
            text={listTitle}
            weight="medium"
          />
          <S.Subtitle
            color={STATIC_COLORS.productColors[productCategory][500]}
            fontFamily="Fraunces"
            localeIndex={{ productCategory }}
            localeVariables={{ productCategoryText }}
            size="h4"
            text={listSubtitle}
            weight="semibold"
          />
        </Flexbox>
        {exploreLocationList.map((locationData, idx) => {
          const locationId = locationData?.retailer_location?.identifier;
          const isCurrentLocation = currentRetailerLocationSlug === locationData?.retailer_location?.slug;

          return (
            <PlacesCard
              key={idx}
              isResponsive
              handleUpdateLocationList={handleUpdateLocationList}
              isCurrentLocation={isCurrentLocation}
              isLocationInWishlist={isLocationInWishlistFilter(locationList, locationId)}
              isLocationListLoading={isLocationListLoading}
              locationData={locationData}
              locationPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
              locationPositionIndex={idx}
              productCategory={productCategory}
            />
          );
        })}
      </S.ContentWrapper>
    </S.ExploreListPageContainer>
  );
};
