import classNames from 'classnames';
import { ReactNode } from 'react';

import Portal from '@lib/tools/views/web/components/Portal';

type ModalProps = {
  children: ReactNode;
  portalClassName?: string;
};

const SpinnerWrapper = ({ children, portalClassName }: ModalProps) => {
  return (
    <Portal className={classNames('spinner-overlay-container', portalClassName)}>
      <div className={`centered ${portalClassName}`}>{children}</div>
    </Portal>
  );
};

export default SpinnerWrapper;
