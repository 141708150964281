import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { REQUEST_METHODS, RETAILER_LOCATION_QUERY } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectLocationListData } from '@lib/core/users/selectors/locationList';
import { locationListApiUrlCreator } from '@lib/core/users/slices/urls';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import { parseError } from '@lib/tools/shared/helpers';

export interface ILocationListState {
  data: ILocation[];
  error: string;
  isLoading: boolean;
}

export const initialState: ILocationListState = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetLocationList = createTypedAsyncThunk('users/locationList/get', async (_, { rejectWithValue }) => {
  try {
    const locationList = await request(locationListApiUrlCreator());

    return { locationList: locationList.results };
  } catch (error) {
    return rejectWithValue(parseError(error));
  }
});

export const actionUpdateLocationList = createTypedAsyncThunk(
  'users/locationList/update',
  async ({ retailerLocationSlug }: IUpdateLocationListParams, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const locationList = selectLocationListData(state);

      const isLocationInList = !!locationList.find(
        location => location.retailer_location.slug === retailerLocationSlug,
      );

      const config = { method: isLocationInList ? REQUEST_METHODS.DELETE : REQUEST_METHODS.POST };
      const params = {
        ...(!isLocationInList && { [RETAILER_LOCATION_QUERY]: retailerLocationSlug }),
      };

      const locationListResponse = await request(
        locationListApiUrlCreator(isLocationInList && retailerLocationSlug),
        config,
        params,
      ).then(() => request(locationListApiUrlCreator()));

      return { locationList: locationListResponse.results };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

const locationListSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(actionGetLocationList.pending, actionUpdateLocationList.pending), state => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addMatcher(
      isAnyOf(actionGetLocationList.fulfilled, actionUpdateLocationList.fulfilled),
      (state, action) => {
        state.isLoading = false;

        // @ts-ignore
        const { locationList } = action.payload;
        state.data = locationList;
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetLocationList.rejected, actionUpdateLocationList.rejected),
      (state, action: any) => {
        if (action.payload?.errorMessage) {
          state.error = action.payload.errorMessage;
        } else if (action.error?.message) {
          state.error = action.error.message;
        }
        state.isLoading = false;
      },
    );
  },
  initialState,
  name: 'locationList',
  reducers: {
    actionResetLocationList: () => initialState,
  },
});

export const { actionResetLocationList } = locationListSlice.actions;
export default locationListSlice.reducer;
