import { FC } from 'react';

import { TCharacterEssences } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { TOTAL_INDICATOR_AMOUNT } from '@components/web/src/foundations/consts';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/EssenceCard/styled';
import ProgressBar, { IProgressBarVariant } from '@components/web/src/organisms/ProgressBar/ProgressBar';

export interface IEssenceProps extends TCharacterEssences {
  productCategory?: TProductCategory;
}

const EssenceCard: FC<IEssenceProps> = ({ name, description, productCategory, score }) => {
  return (
    <S.EssenceContainer
      isFullHeight
      data-testid="EssenceCard"
      direction="column"
      gap={16}
      justify="space-between"
      padding="16px 16px 24px"
    >
      <Flexbox direction="column" gap={8}>
        <Text size="body1" text={name} weight="semibold" />
        <Text size="body2" text={description} />
      </Flexbox>
      <S.EssenceDetails direction="column" gap={16}>
        <Flexbox direction="column" gap={8}>
          <Flexbox align="center" gap={0} justify="space-between">
            <Text size="body2" text={name} />
            <Text
              color={STATIC_COLORS.gray[700]}
              size="body2"
              text={`${score} / ${TOTAL_INDICATOR_AMOUNT}`}
              weight="medium"
            />
          </Flexbox>
          <ProgressBar
            progressPercentage={(score / TOTAL_INDICATOR_AMOUNT) * 100}
            variant={productCategory as IProgressBarVariant}
          />
        </Flexbox>
      </S.EssenceDetails>
    </S.EssenceContainer>
  );
};

export default EssenceCard;
