/* eslint-disable sort-keys-fix/sort-keys-fix */
import classNames from 'classnames';
import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BackButton from '@components/web/src/components/Button/BackButton/BackButton';

interface IProps {
  handleGoBack: () => void;
  isApplicationKiosk: boolean;
}

const CookiePolicyTab: FC<IProps> = ({ handleGoBack, isApplicationKiosk }) => {
  const { cookiePolicyPage } = localeWidget;

  const {
    mainTitle,
    mainDescription,
    whatAreCookiesTitle,
    whatAreCookiesParagraph1,
    whatAreCookiesParagraph2,
    whatAreCookiesParagraph3,
    whatAreCookiesParagraph4,
    whatAreCookiesParagraph5,
    whatAreCookiesParagraph6,
    whatAreCookiesParagraph7,
    whatAreCookiesParagraph8,
    whatAreCookiesParagraph9,
    whatAreCookiesParagraph10,
    whatAreCookiesParagraph11,
    whatAreCookiesParagraph12,
    whatAreCookiesGoogleChrome,
    whatAreCookiesMozillaFirefox,
    whatAreCookiesInternetExplorer,
    whatAreCookiesOpera,
    whatAreCookiesSafari,
    cookiesUsedTitle,
    cookiesUsedParagraph1,
    cookiesUsedParagraph2,
    cookiesUsedParagraph3,
    cookiesUsedParagraph4,
    cookiesUsedParagraph5,
    cookiesUsedParagraph6,
    cookiesUsedParagraph7,
    cookiesUsedParagraph8,
    cookiesUsedParagraph9,
    cookiesUsedParagraph10,
    thirdPartyCookiesAnalytics,
    serviceName,
    purpose,
    persistence,
    purposeGoogleAnalytics,
    persistenceGoogleAnalytics,
    cookiesUsedParagraph11,
    cookiesUsedParagraph12,
    cookiesUsedParagraph13,
    cookiesUsedParagraph14,
    cookiesUsedParagraph15,
    // puerpose google
    purposeGoogleAds1,
    purposeGoogleAds2,
    persistenceGoogleAds,
    purposeBrainLead,
    persistenceBrainLead,
    purposeFacebook1,
    purposeFacebook2,
    persistenceFacebook,
    cookiesUsedParagraph16,
    cookiesUsedInstagram,
    cookiesUsedLinkedin,
    cookiesUsedYoutube,
    // button
    btnBack,
    // ammendment
    amendmentsUpdatesTitle,
    amendmentsUpdatesParagraph1,
  } = cookiePolicyPage;
  return (
    <div className={classNames('cookie-policy-tab', { isApplicationKiosk })}>
      <p className="main-title">
        <LocaleFragment message={mainTitle} />
      </p>
      <p>
        <LocaleFragment message={mainDescription} />
      </p>
      <div className="section">
        <p className="section-title">
          <LocaleFragment message={whatAreCookiesTitle} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph1} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph2} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph3} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph4} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph5} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph6} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph7} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph8} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph9} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph10} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph11} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesParagraph12} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesGoogleChrome} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesMozillaFirefox} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesInternetExplorer} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesOpera} />
        </p>
        <p>
          <LocaleFragment message={whatAreCookiesSafari} />
        </p>
      </div>
      <div className="section">
        <p className="section-title">
          <LocaleFragment message={cookiesUsedTitle} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph1} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph2} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph3} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph4} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph5} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph6} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph7} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph8} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph9} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph10} />
        </p>
        <div className="table-wrapper">
          <div className="table">
            <p className="table-main-title">
              <LocaleFragment message={thirdPartyCookiesAnalytics} />
            </p>
            <p className="table-title">
              <LocaleFragment message={serviceName} />
            </p>
            <p className="table-description">
              <strong>Google Analytics</strong>
              {`${' '}https://marketingplatform.google.com/about/${' '}analytics/terms/us/`}
            </p>
            <p className="table-title">
              <LocaleFragment message={purpose} />
            </p>
            <p className="table-description">
              <LocaleFragment message={purposeGoogleAnalytics} />
            </p>
            <p className="table-title">
              <LocaleFragment message={persistence} />
            </p>
            <p className="table-description">
              <LocaleFragment message={persistenceGoogleAnalytics} />
            </p>
          </div>
        </div>
        <p>
          <LocaleFragment message={cookiesUsedParagraph11} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph12} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph13} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph14} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedParagraph15} />
        </p>
        <div className="table-wrapper">
          <div className="table">
            <p className="table-main-title">
              <LocaleFragment message={thirdPartyCookiesAnalytics} />
            </p>
            <p className="table-title">
              <LocaleFragment message={serviceName} />
            </p>
            <p className="table-description">
              <strong>Google Ads</strong>
              <br />
              https://policies.google.com/technologies/ads?
              <br />
              hl=en-US
            </p>
            <p className="table-title">
              <LocaleFragment message={purpose} />
            </p>
            <p className="table-description">
              <LocaleFragment message={purposeGoogleAds1} />
              <span />
              <LocaleFragment message={purposeGoogleAds2} />
            </p>
            <p className="table-title">
              <LocaleFragment message={persistence} />
            </p>
            <p className="table-description">
              <LocaleFragment message={persistenceGoogleAds} />
            </p>
          </div>
          <div className="table">
            <p className="table-title">
              <LocaleFragment message={serviceName} />
            </p>
            <p className="table-description">
              <strong>BrainLead</strong>
              <br />
              https://brainlead.it/utilizzo-dei-cookies.php
            </p>
            <p className="table-title">
              <LocaleFragment message={purpose} />
            </p>
            <p className="table-description">
              <LocaleFragment message={purposeBrainLead} />
            </p>
            <p className="table-title">
              <LocaleFragment message={persistence} />
            </p>
            <p className="table-description">
              <LocaleFragment message={persistenceBrainLead} />
            </p>
          </div>
          <div className="table">
            <p className="table-title">
              <LocaleFragment message={serviceName} />
            </p>
            <p className="table-description">
              <strong>Facebook</strong>
              <br />
              https://m.facebook.com/policy.php
            </p>
            <p className="table-title">
              <LocaleFragment message={purpose} />
            </p>
            <p className="table-description">
              <LocaleFragment message={purposeFacebook1} />
              <span />
              <LocaleFragment message={purposeFacebook2} />
            </p>
            <p className="table-title">
              <LocaleFragment message={persistence} />
            </p>
            <p className="table-description">
              <LocaleFragment message={persistenceFacebook} />
            </p>
          </div>
        </div>
        <p>
          <LocaleFragment message={cookiesUsedParagraph16} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedInstagram} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedLinkedin} />
        </p>
        <p>
          <LocaleFragment message={cookiesUsedYoutube} />
        </p>
      </div>
      <div className="section">
        <p className="section-title">
          <LocaleFragment message={amendmentsUpdatesTitle} />
        </p>
        <p>
          <LocaleFragment message={amendmentsUpdatesParagraph1} />
        </p>
      </div>
      <BackButton className="previous-cta" handleClick={handleGoBack} size="sm" text={btnBack} />
    </div>
  );
};

export default CookiePolicyTab;
