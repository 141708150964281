import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';

type Props = {
  image: string;
  productCategory: TProductCategory;
};

const UserCharacterInfoImage: FC<Props> = ({ image = '', productCategory }) => {
  return (
    <div className="info-image-container">
      <div className={`image-wrapper ${productCategory}`}>
        {image ? <img alt="" src={image} /> : <div className={`missing-character ${productCategory}`}>?</div>}
      </div>
    </div>
  );
};

export default UserCharacterInfoImage;
