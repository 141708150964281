import { FC } from 'react';

import { IRegistrationBannersData } from '@app/web/src/hooks/useRegistrationBannersData';

import { AUTH_BANNERS } from '@lib/core/banners/consts';

import DefaultBanner from '@components/web/src/organisms/Authentication/AuthBanners/AuthBanner/AuthBanner';
import RegisterExtraInfoSwiper from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';
import RegisterDetailsBanner from '@components/web/src/organisms/Authentication/AuthBanners/RegisterDetailsBanner/RegisterDetailsBanner';

interface IProps {
  registrationBannersData?: IRegistrationBannersData;
}

const RegistrationBanner: FC<IProps> = ({ registrationBannersData }) => {
  const {
    bannerDescription = '',
    bannerSwiperData = [],
    bannerExtraDescription = '',
    registrationBannerVariant,
    bannerTitle = '',
  } = registrationBannersData;

  const isIcon = registrationBannerVariant === AUTH_BANNERS.authFeature;
  const isAnimated = registrationBannerVariant === AUTH_BANNERS.quizTrue;

  switch (registrationBannerVariant) {
    case AUTH_BANNERS.adjective:
    case AUTH_BANNERS.character:
      return <RegisterExtraInfoSwiper swiperData={bannerSwiperData} variant={registrationBannerVariant} />;

    case AUTH_BANNERS.quizFalse:
    case AUTH_BANNERS.quizTrue:
    case AUTH_BANNERS.authFeature:
      return (
        <RegisterDetailsBanner
          description={bannerDescription}
          extraDescription={bannerExtraDescription}
          icon={isIcon}
          isAnimated={isAnimated}
          title={bannerTitle}
        />
      );

    default:
      return (
        <DefaultBanner
          description={bannerDescription}
          registrationBannerVariant={registrationBannerVariant}
          title={bannerTitle}
        />
      );
  }
};

export default RegistrationBanner;
