import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { IS_REDIRECTED_FROM_WIDGET } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import StartPage from '@components/web/src/templates/Start&HomePages/StartPage/StartPage';
import TakeTestStartPage from '@components/web/src/templates/TakeTestStartPage/TakeTestStartPage';

const StartPageContainer: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const isRedirectedFromWidget = queryParams.get(IS_REDIRECTED_FROM_WIDGET);

  const handleSignInBtn = () => {
    navigate(prependBasename(PAGES.vinhood.signIn));
    MixpanelTracker.events.startPageEnterClick();
  };

  const handleTakeTestBtn = () => {
    navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));
    MixpanelTracker.events.startTasteTestClick();
  };

  if (search && isRedirectedFromWidget) return <TakeTestStartPage handleTakeTestBtnClick={handleTakeTestBtn} />;

  return <StartPage handleSignInBtn={handleSignInBtn} handleTakeTestBtn={handleTakeTestBtn} />;
};

export default StartPageContainer;
