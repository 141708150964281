import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/CharacterInfo/HowToTaste/styles';
// TODO: this is old component and will soon be removed
export interface ITasteCardProps {
  tasteTitle: ILocaleText;
  tasteText: ILocaleText;
  icon: string;
}
const HowToTaste = ({ tasteTitle, icon, tasteText }: ITasteCardProps) => {
  return (
    <S.HowToTasteContainer data-testid="HowToTaste">
      <Text color={STATIC_COLORS.base.black} size="body1" text={tasteTitle} weight="semibold" />
      <S.ContentWrapper>
        <Image alt="taste-icon" height="90px" objectFit="contain" src={icon} width="90px" />
        <Text color={STATIC_COLORS.base.black} size="body1" text={tasteText} weight="semibold" />
      </S.ContentWrapper>
    </S.HowToTasteContainer>
  );
};

export default HowToTaste;
