import { FC, SyntheticEvent } from 'react';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { EXPERIENCES_PRICE_RANGE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ExperienceCardSkeleton } from '@components/web/src/app/Skeleton/ExperienceCardSkeleton';
import SkeletonWrapper from '@components/web/src/app/Skeleton/SkeletonWrapper';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import CardFooter from '@components/web/src/organisms/CardFooter/CardFooter';
import * as S from '@components/web/src/organisms/Cards/ExperienceCard/styles';

export type IExperienceCardProps = {
  experienceData?: IB2CExperience;
  priceCurrency?: string;
  cardFooterProps?: { badges?: string[] };
  isLoading?: boolean;
  isResponsive?: boolean;
  isVisibleBadges?: boolean;
};

const ExperienceCard: FC<IExperienceCardProps> = ({
  experienceData,
  priceCurrency = 'EUR',
  isLoading = false,
  isResponsive = false,
  isVisibleBadges = true,
}) => {
  const {
    price,
    image,
    link: producerSite = '',
    name: title = '',
    experience_type: { name: experienceType = '' } = {},
    region: { name: location = '', country = '' } = {},
    producer: { name: author = '' } = {},
    user_product_preferences: userProductPreferences = [],
  } = experienceData;

  const badgesData: string[] = userProductPreferences.map(item => item.name);
  const parsedPrice = Number(price) || 0;

  const redirectToProducerSite = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (producerSite) {
      window.open(producerSite, '_blank');
      MixpanelTracker.events.viewProducerExperiencePage(experienceData, priceCurrency, false);
    }
  };

  return (
    <SkeletonWrapper data-testid="ExperienceCard" showSkeleton={isLoading} skeleton={ExperienceCardSkeleton}>
      <S.ExperienceCardContainer $isResponsive={isResponsive} onClick={redirectToProducerSite}>
        <S.ImageWrapper>
          <img alt={experienceType} src={image} />
        </S.ImageWrapper>
        <S.ContentWrapper>
          <Text color={STATIC_COLORS.green['600']} size="body1" text={experienceType} weight="medium" />
          <S.StyledProductName
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={2}
            size="h5"
            text={title}
            weight="semibold"
          />
          <Text color={STATIC_COLORS.base.black} size="body1" text={author} weight="semibold" />
          <Text color={STATIC_COLORS.base.black} size="body1" text={`${location}${country && `, ${country}`}`} />
          <S.PriceRangeWrapper>
            <PriceRange
              currencySymbolValue={LocaleUtils.getCurrencySymbolFromCode(priceCurrency)}
              customPriceRange={EXPERIENCES_PRICE_RANGE}
              originalPrice={parsedPrice}
            />
          </S.PriceRangeWrapper>
          {producerSite && (
            <S.Button
              text={localeApp.articleCard.ctaBtn}
              textWeight="semibold"
              variant={VH_VARIANTS.LINK}
              onClick={redirectToProducerSite}
            />
          )}
        </S.ContentWrapper>
        {isVisibleBadges && <CardFooter badges={badgesData} />}
      </S.ExperienceCardContainer>
    </SkeletonWrapper>
  );
};

export default ExperienceCard;
