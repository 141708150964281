import { FC } from 'react';

import { IB2CArticle } from '@app/native/src/interfaces/article';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BannerCard, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import SectionHeader from '@components/web/src/atoms/Headers/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import ArticleCard from '@components/web/src/organisms/Cards/ArticleCard/ArticleCard';

interface IArticleSection {
  articles: IB2CArticle[];
  sectionTitle: string;
}

interface IProps {
  articleSectionData: IArticleSection[];
  topBanner?: TBannerCard;
  handleBannerClick?: (route?: string, linkParams?: string) => void;
  navigateToArticlePage: (articleId: string) => void;
}

const ArticleCatalog: FC<IProps> = ({ articleSectionData, topBanner, handleBannerClick, navigateToArticlePage }) => {
  return (
    <div className="articles-catalog-container">
      <div className="heading-wrapper">
        <p className="catalog-title">
          <LocaleFragment message={localeApp.articleCatalog.titleHeading} />
        </p>
        {!!topBanner && <BannerCard {...topBanner} onClickHandler={handleBannerClick} />}
      </div>
      <div className="articles-wrapper">
        {articleSectionData.map(({ sectionTitle, articles }) => {
          const isOneCardSection = articles.length === 1;
          return (
            articles?.length > 0 && (
              <div key={sectionTitle} className={`article-section ${isOneCardSection ? 'one-card-section' : ''}`}>
                <SectionHeader title={sectionTitle} />
                {isOneCardSection ? (
                  <ArticleCard isResponsive articleData={articles[0]} navigateToArticlePage={navigateToArticlePage} />
                ) : (
                  <SwiperHOC>
                    {articles.map((article, idx) => (
                      <ArticleCard key={idx} articleData={article} navigateToArticlePage={navigateToArticlePage} />
                    ))}
                  </SwiperHOC>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default ArticleCatalog;
