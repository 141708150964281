import { FC, useEffect, useState } from 'react';

import TabButton, { ITab } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import * as S from '@components/web/src/atoms/Tabs/Tabs/styles';

export type TTabsGradientVariant = 'default-up' | 'default-down' | 'custom-up' | 'custom-down';
export interface ITabs {
  tabs: ITab[];
  active: string;
  isAutoSpacedTabs?: boolean;
  gradientVariant?: TTabsGradientVariant;
  isHiddenTabs?: boolean;
  onTabClick?: (code: string) => void;
}

const Tabs: FC<ITabs> = ({
  tabs,
  active,
  gradientVariant = 'default-down',
  isHiddenTabs = false,
  isAutoSpacedTabs = false,
  onTabClick: onTabClickCB = () => {},
}) => {
  const [activeTab, setActiveTab] = useState(active);

  useEffect(() => setActiveTab(active), [active]);

  const onTabClick = (code: string): void => {
    setActiveTab(code);
    onTabClickCB(code);
  };
  const { children: activeChildren } = tabs.find(tab => tab.tabCode === activeTab);

  return (
    <S.TabsContainer data-testid={`TabsContainer-${active}`}>
      <S.TabsWrapper>
        <S.Tabs $gradientVariant={gradientVariant} $isAutoSpacedTabs={isAutoSpacedTabs} $isHiddenTabs={isHiddenTabs}>
          <S.Divider />
          <>
            {tabs.length > 0 &&
              tabs.map(
                ({ tabText, tabCode, isHiddenTab, tabIcon }: ITab) =>
                  !isHiddenTab && (
                    <TabButton
                      key={tabCode}
                      isActive={tabCode === activeTab}
                      tabCode={tabCode}
                      tabIcon={tabIcon}
                      tabText={tabText}
                      onTabClick={onTabClick}
                    />
                  ),
              )}
          </>
        </S.Tabs>
      </S.TabsWrapper>
      {activeChildren}
    </S.TabsContainer>
  );
};
export default Tabs;
