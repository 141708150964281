import { FC } from 'react';

import { IAuthFeatureParams } from '@app/web/src/hooks/useAuthFeatureClick';

import { AUTH_BANNERS } from '@lib/core/banners/consts';
import { TCharacter } from '@lib/core/characters/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import quizTimeIcon from '@components/web/src/assets/icons/quiz_time_icon.png';
import { ReactComponent as IconFalse } from '@components/web/src/assets/legacy/icons/icon_false.svg';
import { ReactComponent as IconTrue } from '@components/web/src/assets/legacy/icons/icon_true.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/TestResult/QuizSection/styles';

export interface IQuizSectionQuizData {
  question: string;
  result: boolean;
}

interface IProps {
  characterData: TCharacter;
  quizData: IQuizSectionQuizData[];
  handleQuizSectionClick?: (params: IAuthFeatureParams) => void;
}

const QuizSection: FC<IProps> = ({ characterData, quizData, handleQuizSectionClick }) => {
  const [currentQuizAnswer] = quizData;
  const correctQuizAnswer = quizData.find(answer => answer.result);

  const { quizTitleText, quizTrueText, quizFalseText } = localeWidget.testResultPage;

  const handleAnswerClick = pickedResult => {
    const registrationBannerVariant =
      currentQuizAnswer.result === pickedResult ? AUTH_BANNERS.quizTrue : AUTH_BANNERS.quizFalse;
    const isCorrectAnswer = currentQuizAnswer.result === pickedResult;

    MixpanelTracker.events.discoverQuizSubmit('', isCorrectAnswer);
    handleQuizSectionClick?.({
      correctQuizAnswer: correctQuizAnswer?.question,
      registrationBannerVariant,
    });
  };

  const technicalCaption = characterData?.technical_caption || '';

  return (
    <S.QuizSection data-testid="QuizSection">
      <S.Icon alt="quiz time icon" src={quizTimeIcon} />
      <S.TextWrapper>
        <Text
          color={STATIC_COLORS.productColors.bread[200]}
          fontFamily="Fraunces"
          size="subtitle1"
          text={quizTitleText}
          weight="semibold"
        />
        <S.StyledText
          color={STATIC_COLORS.productColors.bread[200]}
          fontFamily="Fraunces"
          size="subtitle1"
          text={technicalCaption}
          weight="semibold"
        />
      </S.TextWrapper>
      <S.TextWrapper>
        <Text color={STATIC_COLORS.base.white} size="subtitle2" text={currentQuizAnswer.question} />
      </S.TextWrapper>
      <S.ButtonsWrapper>
        <Button
          fontSize="subtitle2"
          handleClick={() => handleAnswerClick(true)}
          icon={<IconTrue />}
          iconPosition="left"
          size="md"
          text={quizTrueText}
          variant={VH_VARIANTS.LIGHT}
        />
        <Button
          fontSize="subtitle2"
          handleClick={() => handleAnswerClick(false)}
          icon={<IconFalse />}
          iconPosition="left"
          size="md"
          text={quizFalseText}
          variant={VH_VARIANTS.LIGHT}
        />
      </S.ButtonsWrapper>
    </S.QuizSection>
  );
};

export default QuizSection;
