import Skeleton from '@components/web/src/components/Skeleton/Skeleton';
import { NUM_SEARCH_SKELETONS } from '@components/web/src/foundations/consts';

const GlobalSearchSkeleton = () => {
  const styles1 = {
    minHeight: '40px',
  };

  const styles2 = {
    minHeight: '100px',
  };

  return (
    <div className="vh-global-search-skeleton-wrapper">
      <div className="tabs-skeleton">
        <Skeleton styles={styles1} />
      </div>
      {Array.from({ length: NUM_SEARCH_SKELETONS })
        .fill(0)
        .map(() => (
          <Skeleton key={Math.random().toString()} styles={styles2} />
        ))}
    </div>
  );
};

export default GlobalSearchSkeleton;
