import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import { LEGAL_MODAL_TYPES } from '@components/web/src/foundations/consts';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import LegalCard from '@components/web/src/organisms/Cards/LegalCard/LegalCard';
import * as S from '@components/web/src/templates/TasteId/TasteIdLegalPage/styles';

const TasteIdLegalPage = ({ onHandleModal, handleBackBtnClick }) => {
  const {
    legalDescription,
    legalTitle,
    permissionCardButtonText,
    permissionCardDescriptionText,
    permissionCardTitle,
    privacyCardButtonText,
    privacyCardDescriptionText,
    privacyCardTitle,
    cookieCardButtonText,
    cookieCardDescriptionText,
    cookieCardTitle,
    conditionsCardButtonText,
    conditionsCardDescriptionText,
    conditionsCardTitle,
  } = localeApp.legalComponent;

  const { back } = localeCommon.commonMessages;

  const legalCardsProps = [
    {
      buttonText: permissionCardButtonText,
      cardDescription: permissionCardDescriptionText,
      cardTitle: permissionCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.PREFERENCES),
    },
    {
      buttonText: privacyCardButtonText,
      cardDescription: privacyCardDescriptionText,
      cardTitle: privacyCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.PRIVACY),
    },
    {
      buttonText: cookieCardButtonText,
      cardDescription: cookieCardDescriptionText,
      cardTitle: cookieCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.COOKIE),
    },
    {
      buttonText: conditionsCardButtonText,
      cardDescription: conditionsCardDescriptionText,
      cardTitle: conditionsCardTitle,
      onBtnClickHandler: () => onHandleModal(LEGAL_MODAL_TYPES.TERMS_AND_CONDITIONS),
    },
  ];

  return (
    <Flexbox isFullWidth data-testid="LegalTabContainer" direction="column" gap={32} padding="0 16px 32px">
      <S.GoBackButton arrowSize="short" handleClick={handleBackBtnClick} text={back} />
      <ProfileSectionHeader description={legalDescription} title={legalTitle} />
      <Flexbox data-testid="LegalCardsContainer" direction="column" gap={16}>
        {legalCardsProps.map((cardData, idx) => (
          <LegalCard {...cardData} key={idx} />
        ))}
      </Flexbox>
    </Flexbox>
  );
};

export default TasteIdLegalPage;
