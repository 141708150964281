import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useLocationList } from '@lib/core/users/hooks';
import { useExploreLocations } from '@lib/tools/explore/hooks';
import { parseExploreLocation } from '@lib/tools/explore/utils/parseExploreLocation';
import { PAGES } from '@lib/tools/views/urls';

import GenericSpinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { IMapLocation } from '@components/web/src/organisms/Cards/MapCard/MapCard';
import { ExploreListPage } from '@components/web/src/templates/ExploreListPage/ExploreListPage';

export const ExploreListContainer = () => {
  const navigate = useNavigate();

  const { productCategory } = useApp();
  const { isRetailerLoading } = useRetailer();
  const { retailerLocationSlug } = useRetailerLocation();
  const { locationList, isLocationListLoading, handleUpdateLocationList } = useLocationList();
  const { isExploreLocationListLoading, exploreLocationList } = useExploreLocations();

  const parsedExploreLocationList = useMemo(
    () =>
      exploreLocationList?.map(
        ({ producer, identifier, images, physical_address, retailer_slug, slug, web_address, name }) => ({
          retailer_location: {
            identifier,
            images: images || [],
            name,
            physical_address,
            producer: {
              identifier: producer?.identifier,
              image: producer?.image,
              name: producer?.name,
            },
            retailer: retailer_slug,
            slug,
            web_address,
          },
        }),
      ),
    [exploreLocationList],
  );

  const mapLocationList: IMapLocation[] = useMemo(() => {
    if (!exploreLocationList?.length) return [];
    return exploreLocationList
      ?.map(locationData => {
        const { exploreLocationAddress, exploreLocationSlug, exploreLocationCoordinates } =
          parseExploreLocation(locationData);
        const isCurrentLocation = exploreLocationSlug === retailerLocationSlug;

        return exploreLocationCoordinates
          ? {
              address: exploreLocationAddress,
              // ToDo: take coordinates from the correct place
              coordinates: exploreLocationCoordinates,
              isCurrentLocation,
              shouldUseCurrentLocationIcon: isCurrentLocation,
            }
          : null;
      })
      .filter(Boolean);
  }, [exploreLocationList]);

  const navigateToExplorePage = () => navigate(prependBasename(PAGES.vinhood.explore));

  const isLoading = isExploreLocationListLoading || isRetailerLoading;

  return isLoading ? (
    <GenericSpinner />
  ) : (
    <ExploreListPage
      currentRetailerLocationSlug={retailerLocationSlug}
      exploreLocationList={parsedExploreLocationList}
      handleUpdateLocationList={handleUpdateLocationList}
      isLocationListLoading={isLocationListLoading}
      locationList={locationList}
      mapLocationList={mapLocationList}
      navigateToExplorePage={navigateToExplorePage}
      productCategory={productCategory}
    />
  );
};
