import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import CharacterMapCard from '@components/web/src/organisms/CharactersMapScroller/CharactersMap/CharacterMapCard';
import * as S from '@components/web/src/organisms/CharactersMapScroller/CharactersMap/styles';

interface IProps {
  lang?: string;
  characterSubCategoryText: string;
  characters: TCharacter[];
}

const CharactersMap: FC<IProps> = ({ characterSubCategoryText, characters, lang }) => {
  return (
    <S.CharacterMapContainer isFullWidth data-testid="CharactersMap" direction="column" gap={8} justify="flex-start">
      <Text
        color={STATIC_COLORS.green[600]}
        fontFamily="Fraunces"
        size="h3"
        text={characterSubCategoryText}
        weight="semibold"
      />
      <S.CharactersWrapper isFullWidth align="flex-start" className="characters-wrapper" justify="flex-start">
        {characters?.map((item, idx) => <CharacterMapCard {...item} key={idx} lang={lang} />)}
      </S.CharactersWrapper>
    </S.CharacterMapContainer>
  );
};

export default CharactersMap;
