import { useUser } from '@lib/core/users/hooks';

import HomePageB2CContainer from 'containers/app/HomeContainer';
import TestResultPageContainer from 'containers/app/TestResultPageContainer';

const ResultTestContainer = () => {
  const { isUserAuthenticated } = useUser();

  if (isUserAuthenticated) return <HomePageB2CContainer />;

  return <TestResultPageContainer />;
};

export default ResultTestContainer;
