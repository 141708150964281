import { FC, useMemo } from 'react';
import { FieldError, Message } from 'react-hook-form';
import { ZodType, z } from 'zod';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import AuthHeader from '@components/web/src/components/Authentication/AuthHeader/AuthHeader';
import GenericAuthForm from '@components/web/src/components/Authentication/GenericAuthForm/GenericAuthForm';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import {
  AuthFormItemTypes,
  ForgotPasswordInputObject,
} from '@components/web/src/pages/Authentication/AuthFormInterface';

interface IProps {
  onSubmitHandler: (data: ForgotPasswordInputObject) => void;
  handleGoBack?: () => void;
  errors?: string;
}

const ResetPasswordPage: FC<IProps> = ({ onSubmitHandler, handleGoBack, errors }) => {
  const { commonMessages, resetPasswordPage } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const emailRequiredText = publishedTerms[commonMessages.emailRequired?.id];
  const validEmailValidationText = publishedTerms[commonMessages.validEmailValidation?.id];
  const emailPlaceholderText = publishedTerms[commonMessages.emailPlaceholder?.id];

  const forgotPasswordSchema: ZodType<ForgotPasswordInputObject> = useMemo(
    () =>
      z.object({
        email: z.string().nonempty(emailRequiredText).email({ message: validEmailValidationText }),
      }),
    [],
  );

  const forgotPasswordFields: AuthFormItemTypes<keyof ForgotPasswordInputObject>[] = useMemo(
    () => [
      {
        errors: {
          email: {
            message: (errors || '') as Message,
          } as FieldError,
        },
        label: commonMessages.emailField,
        name: 'email',
        placeholder: emailPlaceholderText,
        type: 'email',
      },
    ],
    [errors],
  );

  return (
    <div className="reset-password-page-container">
      <AuthHeader
        className="reset-password-header-container"
        headerIcon="key"
        mainHeader={commonMessages.forgotPasswordText}
        subHeader={resetPasswordPage.subTitle}
      />
      <GenericAuthForm
        formFields={forgotPasswordFields}
        submitBtnText={commonMessages.resetPassword}
        validationSchema={forgotPasswordSchema}
        onSubmitHandler={onSubmitHandler}
      />
      <BackButton className="go-back-btn" handleClick={handleGoBack} size="sm" text={commonMessages.goBackBtn} />
    </div>
  );
};

export default ResetPasswordPage;
