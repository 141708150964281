import { useSelector } from 'react-redux';

import {
  selectIsProductsRequestLoadingBeer,
  selectIsProductsRequestLoadingCoffee,
  selectIsProductsRequestLoadingWine,
  selectSwiperProductsData,
  selectSwiperProductsDataBeer,
  selectSwiperProductsDataCoffee,
  selectSwiperProductsDataWine,
} from '@lib/tools/productsSwiper/selectors/index';

export const useProductsSwiper = () => ({
  beerProducts: useSelector(selectSwiperProductsDataBeer),
  coffeeProducts: useSelector(selectSwiperProductsDataCoffee),
  isBeerProductsLoading: useSelector(selectIsProductsRequestLoadingBeer),
  isCoffeeProductsLoading: useSelector(selectIsProductsRequestLoadingCoffee),
  isWineProductsLoading: useSelector(selectIsProductsRequestLoadingWine),
  productsData: useSelector(selectSwiperProductsData),
  wineProducts: useSelector(selectSwiperProductsDataWine),
});
