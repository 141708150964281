import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ARTICLE_ID_URL_PARAM } from '@app/web/src/helpers/consts';

import { fetchArticleLists } from '@lib/core/articles/slices/articleCatalogSlice';
import { BANNER_POSITION_ARTICLES } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { useTypedSelector } from '@lib/tools/views/hooks/useTypedSelector';
import { PAGES } from '@lib/tools/views/urls';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import ArticlePage from '@components/web/src/pages/app/Article/ArticlePage';

const ArticlePageContainer: FC = () => {
  const { search, pathname, state: locationState } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentArticleId = new URLSearchParams(search).get(ARTICLE_ID_URL_PARAM);

  const {
    articlesData: { results: articlesList },
  } = useTypedSelector(state => state.articleCatalog);

  const currentArticleData = useMemo(() => {
    if (articlesList.length > 0) return articlesList.find(article => article.identifier === currentArticleId);

    return null;
  }, [currentArticleId, articlesList]);

  const filteredArticlesSwiperData = useMemo(() => {
    if (articlesList.length > 0 && currentArticleData) {
      const {
        article_type: { identifier: currentArticleType },
      } = currentArticleData;

      return articlesList.filter(
        article => article.article_type.identifier === currentArticleType && article.identifier !== currentArticleId,
      );
    }

    return [];
  }, [currentArticleData, currentArticleId, articlesList]);

  useEffect(() => {
    if (!articlesList.length) {
      // temporary solution until we can't make request to backend
      dispatch(fetchArticleLists());
    }
  }, [currentArticleId, articlesList]);

  const banners = useBanners({
    bannerPosition: BANNER_POSITION_ARTICLES,
    returnAllBanners: true,
  });

  const getBanner: () => TBannerCard = () => getMultipleUniqueRandomItemsFromArray(banners, 1)[0];

  const banner = useMemo(() => getBanner(), [banners]);

  const handleBannerClick = (route, linkParams) =>
    navigate(`${prependBasename(`/${route}/`)}${linkParams ? `?${linkParams}` : ''}`);

  const navigateToArticlePage = articleId =>
    navigate(prependBasename(PAGES.vinhood.article, { [ARTICLE_ID_URL_PARAM]: articleId }), {
      state: { previousPage: pathname + search },
    });

  const handleBackBtnClick = page => {
    navigate(prependBasename(page));
  };

  if (currentArticleData) {
    return (
      <ArticlePage
        articleData={currentArticleData}
        articlesSwiperData={filteredArticlesSwiperData}
        banner={banner}
        handleBackBtnClick={handleBackBtnClick}
        handleBannerClick={handleBannerClick}
        navigateToArticlePage={navigateToArticlePage}
        previousPage={locationState?.previousPage}
      />
    );
  }
  return null;
};

export default ArticlePageContainer;
