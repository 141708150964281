import { FC, Fragment, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { useTypedSelector } from '@lib/tools/views/hooks/useTypedSelector';

import Banner, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import { DESKTOP_RESOLUTION_MIN_VALUE } from '@components/web/src/foundations/consts';
import ExperienceCard from '@components/web/src/organisms/Cards/ExperienceCard/ExperienceCard';

interface IExperienceResultProps {
  experiencesData: IB2CExperience[];
  getRandomBanner: () => TBannerCard;
  isExperienceRequestLoading: boolean;
  handleExperiencesRequest: ({ isPagination }: { isPagination?: boolean }) => void;
  searchCount: number;
  handleBannerClick?: (route?: string, linkParams?: string) => void;
}

const ExperienceResult: FC<IExperienceResultProps> = ({
  experiencesData,
  getRandomBanner,
  isExperienceRequestLoading,
  handleExperiencesRequest,
  searchCount,
  handleBannerClick,
}) => {
  const { bannersFetchSuccess } = useTypedSelector(store => store.b2cBanners);

  const [banners, setBanners] = useState([]);
  const isDesktopResolution = useMediaQuery({ minWidth: DESKTOP_RESOLUTION_MIN_VALUE });

  useEffect(() => {
    if (bannersFetchSuccess && banners.length <= Math.floor(experiencesData.length / 4)) {
      setBanners([
        ...banners,
        getRandomBanner(),
        getRandomBanner(),
        getRandomBanner(),
        getRandomBanner(),
        getRandomBanner(),
      ]);
    }
  }, [experiencesData, bannersFetchSuccess]);

  return (
    <InfiniteScroll
      className="experience-result-container"
      dataLength={experiencesData.length}
      hasMore={searchCount > experiencesData.length}
      next={isExperienceRequestLoading ? null : () => handleExperiencesRequest({ isPagination: true })}
      scrollableTarget={isDesktopResolution ? 'root-element' : null}
      loader={
        isExperienceRequestLoading ? (
          <div className="catalog-loading">
            <ExperienceCard isLoading={isExperienceRequestLoading} />
            <ExperienceCard isLoading={isExperienceRequestLoading} />
          </div>
        ) : null
      }
    >
      {experiencesData?.map((experience, index) => {
        const banner = banners[Math.floor(index / 4)];
        return (
          <Fragment key={experience.identifier}>
            <ExperienceCard experienceData={experience} />
            {(index + 1) % 4 === 0 && banner && <Banner {...banner} onClickHandler={handleBannerClick} />}
          </Fragment>
        );
      })}
    </InfiniteScroll>
  );
};

export default ExperienceResult;
