import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';

interface IProps {
  characterData: TCharacter;
  productCategory: TProductCategory;
  locale: string;
}

export const parseCharacterInfo = ({ characterData, productCategory, locale }: IProps): ICharacterInfoCardProps[] => {
  if (!characterData || !locale) return [];

  const { createCharacterTasteDataText } = localeApp;

  const attributes = characterData?.attributes;
  const preparationClassic = characterData?.preparation_classic;
  const preparationSpecialty = characterData?.preparation_specialty;

  if (productCategory === PRODUCT_CATEGORY_COFFEE) {
    return [
      {
        icon: preparationClassic.image,
        tasteText: `${preparationClassic.character_name} - ${preparationClassic.technical_caption}`,
        tasteTitle: createCharacterTasteDataText.classicCoffeeText,
      },
      {
        icon: preparationSpecialty.image,
        tasteText: `${preparationSpecialty.character_name} - ${preparationSpecialty.technical_caption}`,
        tasteTitle: createCharacterTasteDataText.specialCoffeeText,
      },
    ];
  }
  if (productCategory === PRODUCT_CATEGORY_WINE) {
    return [
      {
        icon: attributes.ideal_glass_img,
        tasteText: `**${attributes[`ideal_glass_${locale}`].trim()}**`,
        tasteTitle: createCharacterTasteDataText.wineGlassText,
      },
      {
        icon: attributes.ideal_temperature_img,
        tasteText: `**${attributes.ideal_temperature_min_celsius}° - ${attributes.ideal_temperature_max_celsius}°**`,
        tasteTitle: createCharacterTasteDataText.temperatureText,
      },
      {
        icon: attributes.food_pairing_img,
        tasteText: `**${attributes[`food_pairing_${locale}`]}**`,
        tasteTitle: createCharacterTasteDataText.foodPariringText,
      },
    ];
  }
  if (productCategory === PRODUCT_CATEGORY_BEER) {
    return [
      {
        icon: attributes.ideal_glass_img,
        tasteText: `**${attributes[`ideal_glass_${locale}`].trim()}**`,
        tasteTitle: createCharacterTasteDataText.glassText,
      },
      {
        icon: attributes.ideal_temperature_img,
        tasteText: `**${attributes.ideal_temperature_min_celsius}° - ${attributes.ideal_temperature_max_celsius}°**`,
        tasteTitle: createCharacterTasteDataText.temperatureText,
      },
      {
        icon: attributes.food_pairing_one_img,
        tasteText: `**${attributes[`food_pairing_one_${locale}`]}, ${attributes[`food_pairing_two_${locale}`]}**`,
        tasteTitle: createCharacterTasteDataText.foodPariringText,
      },
    ];
  }
  return [];
};
