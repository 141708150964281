import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import AuthHeader from '@components/web/src/components/Authentication/AuthHeader/AuthHeader';

interface IProps {
  handleGoBack?: () => void;
}

const CompleteResetPasswordPage: FC<IProps> = ({ handleGoBack }) => {
  const { commonMessages, completeResetPasswordPage } = localeCommon;

  return (
    <div className="complete-reset-password-page-container">
      <AuthHeader
        isCompleteResetPasswordPage
        headerIcon="greenTick"
        mainHeader={completeResetPasswordPage.title}
        subHeader={completeResetPasswordPage.subTitle}
      />
      <Button
        handleClick={handleGoBack}
        text={commonMessages.returnToLogin}
        type="button"
        variant={VH_VARIANTS.PRIMARY}
      />
    </div>
  );
};

export default CompleteResetPasswordPage;
