import { hotjar } from 'react-hotjar';

import { ENV_HOTJAR_SITE_ID, ENV_IS_BUILD } from '@lib/core/service/consts';
import { store } from '@lib/core/service/store';
import { AppStateType } from '@lib/core/service/types/appStateType';
import { USER_ROLE_KIOSK } from '@lib/core/users/utils/consts';
import { actionHotjarStarting } from '@lib/tools/dat/slices';

const initHotjar = () => {
  const {
    user: { data: userData },
    tracking: trackingState,
  }: AppStateType = store.getState();

  if (!trackingState.hotjarState && userData?.role === USER_ROLE_KIOSK && ENV_HOTJAR_SITE_ID) {
    hotjar.initialize({
      debug: !ENV_IS_BUILD,
      id: Number(ENV_HOTJAR_SITE_ID),
      sv: 6,
    });
    hotjar.identify(`${userData?.pk}`, { userProperty: 'value' });
    store.dispatch(actionHotjarStarting());
  }
};

export default initHotjar;
