import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as WhiteArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/bannerdropdownwhite.svg';
import { ReactComponent as CubeIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_cube_white.svg';
import SectionHeader from '@components/web/src/components/SectionHeader/SectionHeader';
import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';

const CatalogSummary = ({
  productCategory,
  productsList,
  handleRedirectClick,
  characterCaption = '',
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  locale,
  feedbackData,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  discoveryQuiz,
  shouldHideComment,
}) => {
  const { headerHeading, bannerTitle } = localeApp.productCatalogPage;

  const { productCategories } = localeCommon;

  const productCategoryText = productCategories[productCategory];

  return (
    <div className="catalog-summary-page-container">
      <div className="header-section">
        <SectionHeader
          sectionDescription={characterCaption}
          sectionTitle={headerHeading}
          titleLocaleOptions={{ productCategory, productCategoryText }}
        />
      </div>
      <div className="swiper-section test">
        <SwiperHOC>
          {productsList.map((productInstanceData, idx) => (
            <ProductCard
              key={idx}
              discoveryQuiz={discoveryQuiz}
              feedback={feedbackFilter(feedbackData, productInstanceData)}
              handleUpdateFeedback={handleUpdateFeedback}
              handleUpdateWishlistProductList={handleUpdateWishlistProductList}
              isLoading={false}
              isResponsive={false}
              isWishlistProductListLoading={isWishlistProductListLoading}
              locale={locale}
              mixpanelIndex={idx}
              mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
              productInstanceData={productInstanceData}
              shouldHideComment={shouldHideComment}
              storeType={RetailerLocationStoreType.main}
              variant="badges"
              isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                wishlistProductInstanceIds,
                productInstanceData,
              )}
            />
          ))}
        </SwiperHOC>
      </div>
      <div className="action-section">
        <div aria-hidden className="action-container" onClick={handleRedirectClick}>
          <CubeIcon />
          <span className="text">
            <LocaleFragment index={{ productCategory }} message={bannerTitle} variables={{ productCategoryText }} />
          </span>
          <WhiteArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default CatalogSummary;
