import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { useDisclosure } from '@lib/tools/views/hooks';

import { LEGAL_MODAL_TYPES } from '@components/web/src/foundations/consts';
import CookiePolicyModal from '@components/web/src/templates/Modals/CookiePolicyModal/CookiePolicyModal';
import { FullServiceTermsModal } from '@components/web/src/templates/Modals/FullServiceTermsModal/FullServiceTermsModal';
import PrivacyPolicyModal from '@components/web/src/templates/Modals/PrivacyPolicyModal/PrivacyPolicyModal';
import TermsConditionsModal from '@components/web/src/templates/Modals/TermsConditionsModal/TermsConditionsModal';
import LegalPage from '@components/web/src/templates/TasteId/TasteIdLegalPage/TasteIdLegalPage';

type IModalTypes = 'privacy' | 'cookie' | 'termsAndConditions' | 'preferences';

const TasteIdLegalPageContainer = () => {
  const navigate = useNavigate();

  const { updateServiceTerms, serviceTermsListUpdateIsLoading, serviceTermsByCategory } = useServiceTerms();
  const { cookie, profiling, marketing } = serviceTermsByCategory;
  const [isPrivacyModalOpen, { open: openPrivacyModal, close: closePrivacyModal }] = useDisclosure({
    defaultIsOpen: false,
  });
  const [isTermsConditionsModalOpen, { open: openTermsConditionsModal, close: closeTermsConditionsModal }] =
    useDisclosure({
      defaultIsOpen: false,
    });
  const [isCookieModalOpen, { open: openCookieModal, close: closeCookieModal }] = useDisclosure({
    defaultIsOpen: false,
  });
  const [isPreferenceModalOpen, { open: openPreferenceModal, close: closePreferenceModal }] = useDisclosure({
    defaultIsOpen: false,
  });

  const onHandleModal = (modalType: IModalTypes) => {
    switch (modalType) {
      case LEGAL_MODAL_TYPES.PREFERENCES:
        openPreferenceModal();
        break;
      case LEGAL_MODAL_TYPES.PRIVACY:
        openPrivacyModal();
        break;
      case LEGAL_MODAL_TYPES.COOKIE:
        openCookieModal();
        break;
      case LEGAL_MODAL_TYPES.TERMS_AND_CONDITIONS:
        openTermsConditionsModal();
        break;
      default:
        break;
    }
  };

  const handleSaveServiceTerms = (newServiceTerms: IParsedServiceTerm[]) => {
    // Filter function is only to improve business logic to not call API for non changed values
    updateServiceTerms(
      newServiceTerms.filter(term => serviceTermsByCategory[term.category]?.is_selected !== term.is_selected),
    ).then(() => closePreferenceModal());
  };

  const handleBackBtnClick = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <LegalPage handleBackBtnClick={handleBackBtnClick} onHandleModal={onHandleModal} />

      <FullServiceTermsModal
        handleSaveServiceTerms={handleSaveServiceTerms}
        hideModal={closePreferenceModal}
        isLoading={serviceTermsListUpdateIsLoading}
        isModalOpen={isPreferenceModalOpen}
        serviceTerms={{ cookie, marketing, profiling }}
      />
      <PrivacyPolicyModal
        handleShowBasicServiceTermsModal={openPrivacyModal}
        hideModal={closePrivacyModal}
        isModalOpen={isPrivacyModalOpen}
      />
      <CookiePolicyModal hideModal={closeCookieModal} isModalOpen={isCookieModalOpen} />
      <TermsConditionsModal hideModal={closeTermsConditionsModal} isModalOpen={isTermsConditionsModalOpen} />
    </Fragment>
  );
};

export default TasteIdLegalPageContainer;
