import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as DownloadIcon } from '@components/web/src/assets/icons/download.svg';
import * as S from '@components/web/src/components/Button/DownloadButton/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const DownloadButton = ({ handleDownloadClick }: { handleDownloadClick?: () => void }) => {
  const { pdfText } = localeCommon.commonMessages;

  return (
    <Flexbox direction="column" gap={2} onClick={() => handleDownloadClick()}>
      <DownloadIcon />
      <S.StyledText color={STATIC_COLORS.teal['600']} size="body3" text={pdfText} weight="medium" />
    </Flexbox>
  );
};

export default DownloadButton;
