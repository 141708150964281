import { createSelector } from '@reduxjs/toolkit';

import { IState } from '@lib/tools/tastePathProducts/types/types';

/**
 * Selects the entire taste path products state from the Redux store.
 */
export const selectTastePathProducts = (state): IState => state.tastePath || {};

/**
 * Selects the taste path data, containing product information for different taste path categories.
 */
export const selectTastePathData = createSelector([selectTastePathProducts], tastePath => tastePath.tastePathData);

/**
 * Selects the loading state for taste path products.
 */
export const selectIsTastePathProductsLoading = createSelector(
  [selectTastePathProducts],
  tastePath => tastePath.isTastePathProductsLoading,
);

/**
 * Selects the ID of the current taste path category.
 */
export const selectCurrentTastePathCategoryId = createSelector(
  [selectTastePathProducts],
  tastePath => tastePath.currentTastePathCategoryId,
);

/**
 * Selects the ID of the next taste path category.
 */
export const selectNextTastePathCategoryId = createSelector(
  [selectTastePathProducts],
  tastePath => tastePath.nextTastePathCategoryId,
);
