import styled from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

export const CharacterDetailsContainer = styled.div<{ $productCategory: string }>`
  position: fixed;
  z-index: 2;
  width: 100%;
  max-width: ${SCREEN_SIZE.vhApp};
  padding: 32px 16px 48px;
  color: ${STATIC_COLORS.base.white};
  border-radius: 0;
  background-color: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory]['500']};
`;

export const CharacterDetailsWrapper = styled(Flexbox)`
  height: 100%;
`;

export const CharacterImageWrapper = styled(Flexbox)`
  width: 124px;
  height: 100%;
  padding: 16px 10px;
`;

export const CharacterImage = styled(Image)`
  position: absolute;
  width: 173px;
  height: 173px;
  left: -21px;
  top: 15px;
  background: radial-gradient(48.4% 50% at 51.6% 50%, rgba(255, 255, 255, 0.33) 42%, rgba(255, 255, 255, 0) 99.58%);
`;

export const CharacterDetailsContent = styled(Flexbox)`
  width: 100%;
`;
export const ProductSummary = styled(Flexbox)`
  width: 100%;
`;

export const CharacterDescriptionWrapper = styled.div`
  padding: 16px 0 8px 0;
`;

export const CtaButton = styled.button`
  all: unset;
  float: right;
  text-decoration: underline;
  cursor: pointer;
`;
