/* eslint-disable sort-keys-fix/sort-keys-fix */
import classNames from 'classnames';
import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';

interface IProps {
  handleGoBack: () => void;
  isApplicationKiosk: boolean;
}

const TermsConditionsTab: FC<IProps> = ({ handleGoBack, isApplicationKiosk }) => {
  const { termsConditionsPage } = localeWidget;

  const {
    mainTitle,
    mainDescription,
    platformDescription,
    platformDescriptionParagraph1,
    platformDescriptionParagraph2,
    platformDescriptionParagraph3,
    platformDescriptionParagraph4,
    platformDescriptionParagraph5,
    platformDescriptionPrint,
    // condition
    conditionTitle1,
    condition1Paragraph1,
    condition1Paragraph2,
    condition1Paragraph3,
    condition1Paragraph4,
    condition1Paragraph5,
    condition1Paragraph6,
    conditionTitle2,
    condition2Paragraph1,
    condition2Paragraph2,
    condition2Paragraph3,
    condition2Paragraph4,
    condition2Paragraph5,
    condition2Paragraph6,
    condition2Paragraph7,
    condition2Paragraph8,
    conditionTitle3,
    condition3Paragraph1,
    condition3Paragraph2,
    conditionTitle4,
    condition4Paragraph1,
    condition4Paragraph2,
    condition4Paragraph3,
    condition4Paragraph4,
    conditionTitle5,
    condition5Paragraph1,
    condition5Paragraph2,
    condition5Paragraph3,
    condition5Paragraph4,
    condition5Paragraph5,
    condition5Paragraph6,
    conditionTitle6,
    condition6Paragraph1,
    condition6Paragraph2,
    condition6Paragraph3,
    btnBack,
  } = termsConditionsPage;

  const conditionsData = [
    {
      title: conditionTitle1,
      paragraphs: [
        condition1Paragraph1,
        condition1Paragraph2,
        condition1Paragraph3,
        condition1Paragraph4,
        condition1Paragraph5,
        condition1Paragraph6,
      ],
    },
    {
      title: conditionTitle2,
      paragraphs: [
        condition2Paragraph1,
        condition2Paragraph2,
        condition2Paragraph3,
        condition2Paragraph4,
        condition2Paragraph5,
        condition2Paragraph6,
        condition2Paragraph7,
        condition2Paragraph8,
      ],
    },
    {
      title: conditionTitle3,
      paragraphs: [condition3Paragraph1, condition3Paragraph2],
    },
    {
      title: conditionTitle4,
      paragraphs: [condition4Paragraph1, condition4Paragraph2, condition4Paragraph3, condition4Paragraph4],
    },
    {
      title: conditionTitle5,
      paragraphs: [
        condition5Paragraph1,
        condition5Paragraph2,
        condition5Paragraph3,
        condition5Paragraph4,
        condition5Paragraph5,
        condition5Paragraph6,
      ],
    },
    {
      title: conditionTitle6,
      paragraphs: [condition6Paragraph1, condition6Paragraph2, condition6Paragraph3],
    },
  ];

  return (
    <div className={classNames('terms-conditions-tab-wrapper', { isApplicationKiosk })}>
      {isApplicationKiosk && <KioskCirclesBackground />}
      <p className="main-title">
        <LocaleFragment message={mainTitle} />
      </p>
      <p>
        <LocaleFragment message={mainDescription} />
      </p>
      <div className="section">
        <p>
          <LocaleFragment message={platformDescription} />
        </p>
        <ul>
          {[
            platformDescriptionParagraph1,
            platformDescriptionParagraph2,
            platformDescriptionParagraph3,
            platformDescriptionParagraph4,
            platformDescriptionParagraph5,
          ].map((paragraph, paragraphIdx) => (
            <li key={paragraphIdx}>
              <LocaleFragment message={paragraph} />
            </li>
          ))}
        </ul>
        <p>
          <LocaleFragment message={platformDescriptionPrint} />
        </p>
      </div>
      {conditionsData.map((condition, conditionIdx) => {
        const condIdx = conditionIdx + 1;
        return (
          <div key={conditionIdx} className="section">
            <p className="section-title">
              {`${condIdx}.`} <LocaleFragment message={condition.title} />
            </p>
            {condition.paragraphs.map((paragraph, paragraphIdx) => {
              const parIdx = paragraphIdx + 1;
              return (
                <p key={paragraphIdx}>
                  <span className="order">{`${condIdx}.${parIdx} `}</span>
                  <LocaleFragment message={paragraph} />
                </p>
              );
            })}
          </div>
        );
      })}
      <BackButton className="previous-cta" handleClick={handleGoBack} size="sm" text={btnBack} />
    </div>
  );
};

export default TermsConditionsTab;
