import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { currentYear } from '@lib/tools/shared/helpers/utils';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as GearIcon } from '@components/web/src/assets/icons/icon_gear.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/RedirectFooter/styles';

const RedirectLink = ({ text, icon = null, handleLinkClick, link = '' }) => (
  <S.RedirectLink align="center" gap={4} justify="center" onClick={() => handleLinkClick(link)}>
    <S.StyledRedirectText color={STATIC_COLORS.gray['900']} size="body2" text={text} />
    {icon && <S.Icon>{icon}</S.Icon>}
  </S.RedirectLink>
);

interface IProps {
  handleNavigateClick?: (link: string) => void;
}

const RedirectFooter: FC<IProps> = ({ handleNavigateClick }) => {
  const { copyrightText } = localeCommon.commonMessages;
  const { legalTabText, settingsTabText } = localeApp.profilePageContainer;

  return (
    <Flexbox align="center" direction="column" gap={14} padding="16px">
      <Flexbox align="center" gap={32} justify="center">
        <RedirectLink
          handleLinkClick={handleNavigateClick}
          link={PAGES.vinhood.tasteId.tasteIdLegal}
          text={legalTabText}
        />
        <RedirectLink
          handleLinkClick={handleNavigateClick}
          icon={<GearIcon />}
          link={PAGES.vinhood.tasteId.tasteIdSettings}
          text={settingsTabText}
        />
      </Flexbox>
      <Text color={STATIC_COLORS.gray['900']} localeOptions={{ currentYear }} size="body3" text={copyrightText} />
    </Flexbox>
  );
};

export default RedirectFooter;
