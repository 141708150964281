import Badge from '@components/web/src/atoms/Badge/Badge';

type Props = {
  tags: string[];
};

const ProductTags = ({ tags }: Props) => {
  return (
    <div className="app-product-tags-container">
      {tags.length > 0 && tags.map(tag => tag && <Badge key={tag} text={tag} />)}
    </div>
  );
};

export default ProductTags;
