import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import CookieGif from '@components/web/src/assets/legacy/b2c/images/biscuit.gif';
import Button from '@components/web/src/atoms/Buttons/Button';
import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/BasicServiceTermsModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  handleRejectOptionalBasicServiceTerms: () => void;
  handleAcceptAllBasicServiceTerms: () => void;
  handleOpenGeneralServiceTermsModal: () => void;
}

export const BasicServiceTermsModal: FC<IProps> = ({
  isModalOpen,
  handleRejectOptionalBasicServiceTerms,
  handleAcceptAllBasicServiceTerms,
  handleOpenGeneralServiceTermsModal,
  isLoading = false,
}) => {
  const { descriptionText, acceptAllBtn, manageBtn, rejectBtn } = localeCommon.basicServiceTermsModal;

  return (
    <ModalWrapper isOpen={isModalOpen} modalClassName="cookie-modal-container">
      <S.DescriptionContainer>
        <Text color={STATIC_COLORS.base.black} size="body1" text={descriptionText} />
        <div>
          <img alt="cookie-gif" src={CookieGif} />
        </div>
      </S.DescriptionContainer>
      <Button
        fontSize="subtitle2"
        handleClick={handleAcceptAllBasicServiceTerms}
        size="lg"
        text={acceptAllBtn}
        textWeight="medium"
        variant={VH_VARIANTS.PRIMARY}
      />
      <S.ButtonWrapper>
        <Button
          fontSize="subtitle2"
          handleClick={handleOpenGeneralServiceTermsModal}
          size="md"
          text={manageBtn}
          textWeight="medium"
          variant={VH_VARIANTS.LIGHT}
        />
        <Button
          fontSize="subtitle2"
          handleClick={handleRejectOptionalBasicServiceTerms}
          size="md"
          text={rejectBtn}
          textWeight="medium"
          variant={VH_VARIANTS.LIGHT}
        />
      </S.ButtonWrapper>
      {isLoading && <Spinner />}
    </ModalWrapper>
  );
};
