import { styled } from 'styled-components';

import Badge from '@components/web/src/atoms/Badge/Badge';
import { Text } from '@components/web/src/foundations/Text/Text';

export const StyledBadge = styled(Badge)`
  img {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const StyledText = styled(Text)`
  padding: 0px 0px 4px;
`;
