import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import TimeSKetchImage from '@components/web/src/assets/legacy/b2c/png/sketches_time_large.png';

interface IProps {
  heightBox?: number | 'auto' | '100%';
  widthBox?: number | 'auto';
}

const SeeLaterCard: FC<IProps> = ({ heightBox = 490, widthBox = 312 }) => {
  const { seeLaterCardComponent } = localeApp;

  return (
    <div className="see-later-card-container" style={{ height: heightBox, width: widthBox }}>
      <div className="wrapper">
        <p className="title">
          <LocaleFragment message={seeLaterCardComponent.titleText} />
        </p>
        <img alt="timer clock" src={TimeSKetchImage} />
      </div>
      <p className="subtitle">
        <LocaleFragment message={seeLaterCardComponent.subtitleText} />
      </p>
      <p className="description">
        <LocaleFragment message={seeLaterCardComponent.descriptionText} />
      </p>
    </div>
  );
};

export default SeeLaterCard;
