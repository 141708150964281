import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserProductPreferences } from '@lib/core/products/selectors/userProductPreferences';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { actionFetchSelectedUserProductPreferences } from '@lib/core/service/slices/productPreferencesSlice';
import { useUser } from '@lib/core/users/hooks';
import { USER_NO_TAGS_PREFERENCES_DATA } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

type IUseUserProductPreferencesProps = {
  isForDataControl?: boolean;
};

/**
 * Use for make API request for user preferences data also as for return this data
 *
 * @param {boolean} isForDataControl - uses to have only 1 instance of hook be responsive of data management
 * @returns {Record<string, any>} useful data related to preferences
 */

export const useProductPreferences = ({ isForDataControl = false }: IUseUserProductPreferencesProps = {}) => {
  const dispatch = useDispatch();
  const {
    selectedUserProductPreferencesFetchSuccess,
    isUserProductPreferencesDataLoading,
    tagsPreferencesData = {},
    foodPreferencesData = [],
  } = useTypedSelector(state => state.productPreferences);

  const userProductPreferences = useSelector(selectUserProductPreferences);
  const { userSessionId, isUserAuthenticated } = useUser();
  const isKiosk = isApplicationKiosk;
  useEffect(() => {
    if (userSessionId && isForDataControl && !isKiosk && isUserAuthenticated && userProductPreferences.length) {
      dispatch(actionFetchSelectedUserProductPreferences(userSessionId));
    }
  }, [userSessionId, isForDataControl, userProductPreferences]);

  const userProductsPreferencesString = useMemo(
    () =>
      Object.keys(tagsPreferencesData)
        .filter(
          key => tagsPreferencesData[key].isEnabled && tagsPreferencesData[key].slug !== USER_NO_TAGS_PREFERENCES_DATA,
        )
        .map(key => tagsPreferencesData[key].slug)
        .join(','),
    [tagsPreferencesData, userSessionId, selectedUserProductPreferencesFetchSuccess],
  );

  const userProductsFoodPreferences = useMemo(
    () =>
      Object.keys(foodPreferencesData)
        .filter(key => foodPreferencesData[key].isEnabled && foodPreferencesData[key].slug)
        .map(key => foodPreferencesData[key].slug),
    [foodPreferencesData],
  );

  const userHasProductsFoodPreferences = !!userProductsFoodPreferences.length;

  return {
    isUserProductPreferencesDataLoading,
    selectedUserProductPreferencesFetchSuccess,
    userHasProductsFoodPreferences,
    userProductsFoodPreferences,
    userProductsPreferencesString,
  };
};
