import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const DownloadAppModalWrapper = styled(Flexbox)`
  padding: 16px 16px 24px;
  position: relative;
  & > button {
    margin-left: auto;
  }
`;
