import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/CharacterInfo/styles';

export interface ICharacterInfoCardProps {
  tasteTitle: ILocaleText;
  tasteText: ILocaleText;
  icon: string;
}

const CharacterInfo: FC<ICharacterInfoCardProps> = ({ tasteTitle, tasteText, icon }) => (
  <S.CharacterInfoContainer data-testid="CharacterInfo">
    <Image alt="taste-icon" height="50px" objectFit="contain" src={icon} width="50px" />
    <S.ContentWrapper>
      <Text color={STATIC_COLORS.base.black} size="body1" text={tasteTitle} />
      <Text color={STATIC_COLORS.base.black} size="body1" text={tasteText} weight="semibold" />
    </S.ContentWrapper>
  </S.CharacterInfoContainer>
);

export default CharacterInfo;
