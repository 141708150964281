import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const AuthBannerContainer = styled.div`
  width: 100%;
`;

export const AppVariantContentWrapper = styled(Flexbox)`
  height: 120px;
  background-color: #a8d450;
`;

export const TextWrapper = styled.div`
  padding: 16px;
`;
