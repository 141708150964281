import { FC } from 'react';

import { AUTH_BANNERS, TAuthBanner } from '@lib/core/banners/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as LeadGenerationIcon } from '@components/web/src/assets/legacy/b2b/icons/lead_generation_icon.svg';
import { ReactComponent as Bullseye } from '@components/web/src/assets/legacy/b2c/icons/bullseye.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Authentication/AuthBanners/AuthBanner/styles';

interface IProps {
  title?: ILocaleText;
  description?: ILocaleText;
  registrationBannerVariant: TAuthBanner;
}

const AuthBanner: FC<IProps> = ({ title, description, registrationBannerVariant }) => {
  const { registerPage, loginPage } = localeCommon;

  const renderHeaderContent = () => {
    switch (registrationBannerVariant) {
      case AUTH_BANNERS.app:
        return (
          <Flexbox direction="column" gap={0}>
            <S.AppVariantContentWrapper align="center" justify="space-between" padding="0 16px">
              <Text
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={title || registerPage.headerTitleApp}
                weight="semibold"
              />
              <Bullseye />
            </S.AppVariantContentWrapper>
            <S.TextWrapper>
              <Text color={STATIC_COLORS.base.black} size="body1" text={description} />
            </S.TextWrapper>
          </Flexbox>
        );
      case AUTH_BANNERS.experience:
        return (
          <Flexbox direction="column">
            <Flexbox justify="space-between" padding="16px 16px 0">
              <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h4" text={title} weight="semibold" />
              <LeadGenerationIcon />
            </Flexbox>
            <S.TextWrapper>
              <Text color={STATIC_COLORS.base.black} size="body1" text={description} />
            </S.TextWrapper>
          </Flexbox>
        );
      default:
        return (
          <Flexbox direction="column" gap={16} padding="16px">
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h4"
              text={title || loginPage.title}
              weight="semibold"
            />
            <Text color={STATIC_COLORS.base.black} size="body1" text={description || loginPage.subtitleFirst} />
          </Flexbox>
        );
    }
  };
  return <S.AuthBannerContainer data-testid="AuthBanner">{renderHeaderContent()}</S.AuthBannerContainer>;
};

export default AuthBanner;
