import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { actionGetCharacters } from '@lib/core/characters/slices';
import { actionGetEssenceGroups } from '@lib/core/essences/slices/groups';
import { actionGetRecycling } from '@lib/core/productAttributes/slices/recycling';
import { actionGetWellness } from '@lib/core/productAttributes/slices/wellness';
import { actionGetUserProductPreferences } from '@lib/core/products/slices/userProductPreferences';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { actionGetRetailerDetail, actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useRetailerDesignSet } from '@lib/core/service/hooks/useRetailerDesignSet';
import { actionGetServiceInstanceRetailerData } from '@lib/core/service/slices/serviceInstanceSlice';
import { actionGetServiceTermsOptions } from '@lib/core/serviceTerms/slices';
import { useUser } from '@lib/core/users/hooks';
import { actionGetLocationList } from '@lib/core/users/slices/locationList';
import { actionGetProfileOptionsData } from '@lib/core/users/slices/profile';
import { actionGetExploreLocations } from '@lib/tools/explore/slices';
import {
  SERVICE_INSTANCE_PRODUCT_CATEGORY_URL_PARAM,
  SERVICE_INSTANCE_RETAILER_LOCATION_ID_URL_PARAM,
  SERVICE_INSTANCE_RETAILER_LOCATION_SLUG_URL_PARAM,
  SERVICE_INSTANCE_RETAILER_SLUG_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const DataProvider = ({ children }) => {
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();

  const { retailerSlug } = useRetailer();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { retailerLocationId, retailerLocationProducerId } = useRetailerLocation();
  const { isUserAuthenticated } = useUser();
  const { locale } = useApp();

  useEffectSkipFirst(() => {
    if (!retailerSlug || !retailerLocationId) return;
    dispatch(actionGetRetailerDetail({ retailerSlug }));
    dispatch(
      actionGetRetailerLocations({
        retailerLocationId,
        retailerSlug,
      }),
    );
  }, [locale]);

  useEffect(() => {
    if (!retailerSlug) return;
    dispatch(actionGetCharacters());
    dispatch(actionGetEssenceGroups());
    dispatch(actionGetWellness());
    dispatch(actionGetRecycling());
    dispatch(actionGetUserProductPreferences());

    if (!isApplicationKiosk) {
      dispatch(actionGetServiceTermsOptions());
      dispatch(actionGetProfileOptionsData());
    }

    /** Service Instance:
     * The feature works for App
     * Catch and set data for the Service Instance feature */
    const serviceInstanceProductCategory = searchParams.get(SERVICE_INSTANCE_PRODUCT_CATEGORY_URL_PARAM);
    const serviceInstanceRetailerLocationId = searchParams.get(SERVICE_INSTANCE_RETAILER_LOCATION_ID_URL_PARAM);
    const serviceInstanceRetailerSlug = searchParams.get(SERVICE_INSTANCE_RETAILER_SLUG_URL_PARAM);
    const serviceInstanceRetailerLocationSlug = searchParams.get(SERVICE_INSTANCE_RETAILER_LOCATION_SLUG_URL_PARAM);

    if (
      isDesignSetVinhoodApp &&
      serviceInstanceProductCategory &&
      serviceInstanceRetailerLocationId &&
      serviceInstanceRetailerSlug
    ) {
      dispatch(
        actionGetServiceInstanceRetailerData({
          serviceInstanceProductCategory,
          serviceInstanceRetailerLocationId,
          serviceInstanceRetailerLocationSlug,
          serviceInstanceRetailerSlug,
        }),
      );
    }
  }, [locale]);

  useEffect(() => {
    if (isUserAuthenticated && !isApplicationKiosk) {
      dispatch(actionGetLocationList());
      dispatch(actionGetExploreLocations({ producerId: retailerLocationProducerId, retailerSlug }));
    }
  }, [isUserAuthenticated, locale]);

  return children;
};
