import { selectRetailerId, selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import {
  selectAssociatedRetailerLocationSlug,
  selectRetailerDesignSet,
  selectRetailerLocationId,
  selectRetailerLocationSlug,
} from '@lib/core/retailers/selectors/retailerLocation';
import { store } from '@lib/core/service/store';
import { selectAuthToken } from '@lib/core/users/selectors/auth';
import { selectProfileId } from '@lib/core/users/selectors/profile';
import {
  selectKioskUserRetailerLocationSlug,
  selectKioskUserRetailerSlug,
  selectUserId,
  selectUserRoleIsKiosk,
  selectUserSessionId,
} from '@lib/core/users/selectors/user';

export const getRequestCommonParameters = () => {
  const { getState } = store;
  const state = getState();
  const authToken = selectAuthToken(state);
  const userId = selectUserId(state);
  const userIsKioskAdmin = selectUserRoleIsKiosk(state);
  const userSessionId = selectUserSessionId(state);
  const associatedRetailerLocationSlug = selectAssociatedRetailerLocationSlug(state);

  return {
    associatedRetailerLocationSlug,
    authToken,
    profileId: selectProfileId(state),
    retailerDesignSet: selectRetailerDesignSet(state),
    retailerId: selectRetailerId(state),
    retailerLocationID: selectRetailerLocationId(state),
    retailerLocationSlug: userIsKioskAdmin
      ? selectKioskUserRetailerLocationSlug(state)
      : selectRetailerLocationSlug(state),
    retailerSlug: userIsKioskAdmin ? selectKioskUserRetailerSlug(state) : selectRetailerSlug(state),
    userId,
    userIsKioskAdmin,
    userSessionId,
  };
};
