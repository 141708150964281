import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectFeedbackData } from '@lib/core/users/selectors/feedback';
import { selectUserSessionId } from '@lib/core/users/selectors/user';
import { feedbackDataApiUrlCreator } from '@lib/core/users/slices/urls';

export type TFeedback = 1 | 2 | 3 | 4 | 5;

export interface IFeedbackData {
  feedback: TFeedback;
  gprl: TProductInstance;
}

interface IResultPayload {
  results: IFeedbackData[];
}

interface IState {
  data: IFeedbackData[];
  isLoading: boolean;
  error: string;
  lastUpdatedFeedbackId: string;
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: false,
  lastUpdatedFeedbackId: '',
};

export const actionGetFeedbacks = createTypedAsyncThunk(
  'feedback/actionGetFeedbacks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request(feedbackDataApiUrlCreator({}));
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const actionUpdateFeedback = createTypedAsyncThunk(
  'feedback/actionUpdateFeedback',
  async ({ feedback, productId }: { feedback: number; productId: string }, { getState, rejectWithValue }) => {
    const state = getState();
    const userSessionId = selectUserSessionId(state);

    if (!userSessionId) {
      return rejectWithValue({ message: 'User session ID is not provided.' });
    }

    const feedbackData = selectFeedbackData(state);
    const isFeedbackExist = feedbackData.some(item => item.gprl.product.identifier === productId);

    const apiUrl = feedbackDataApiUrlCreator({ productId: isFeedbackExist ? productId : '' });
    const method = { method: isFeedbackExist ? REQUEST_METHODS.PATCH : REQUEST_METHODS.POST };
    const payload = { feedback, ...(isFeedbackExist ? {} : { product: productId }) };

    try {
      return await request(apiUrl, method, payload);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const feedbackSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(actionGetFeedbacks.pending, state => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(actionUpdateFeedback.pending, state => {
        state.error = '';
        state.isLoading = true;
        state.lastUpdatedFeedbackId = '';
      })
      .addCase(actionGetFeedbacks.fulfilled, (state, action: PayloadAction<IResultPayload>) => {
        state.isLoading = false;
        state.data = action.payload.results;
      })
      .addCase(actionUpdateFeedback.fulfilled, (state, action: PayloadAction<IFeedbackData>) => {
        const { payload } = action;
        state.isLoading = false;
        state.lastUpdatedFeedbackId = payload?.gprl?.product?.identifier;

        const feedbackIndex = state.data.findIndex(
          item => item?.gprl?.product?.identifier === payload?.gprl?.product?.identifier,
        );

        if (feedbackIndex !== -1) {
          state.data[feedbackIndex] = payload;
        } else {
          state.data.unshift(payload);
        }
      })
      .addCase(actionGetFeedbacks.rejected, (state, action: { payload: any; error: any; type: string }) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(actionUpdateFeedback.rejected, (state, action: { payload: any; error: any; type: string }) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
  initialState,
  name: 'feedbacks',
  reducers: {},
});

export default feedbackSlice.reducer;
