import { FC } from 'react';
import { useDispatch } from 'react-redux';

import B2CProductCatalogContainer from '@app/web/src/containers/app/Catalog/ProductCatalogContainer';
import WidgetCatalogContainerHOC from '@app/web/src/containers/Catalog/CatalogContainerHOC';
import WidgetExtendedCatalogContainer from '@app/web/src/containers/Catalog/ExtendedCatalogContainer';
import WidgetLimitedCatalogContainer from '@app/web/src/containers/Catalog/LimitedCatalogContainer';

import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import {
  ACTION_PERFORMED_URL_PARAM,
  MIXPANEL_EVENT_SLUG_URL_PARAM,
  ModalTypes,
  REDIRECT_URL_PARAM,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setFidelityCardIdFromKiosk, setShouldHideDownloadAppCard } from '@lib/core/service/slices';
import { MP_ACTION_PERFORMED_CONTEXT, MP_EVENTS } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useModals } from '@lib/tools/modals/hooks';
import RouteUtils from '@lib/tools/routes';
import { EXTENSIVE_CATALOG, FIDELITY_ID_URL_PARAM, LIMITED_CATALOG } from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import CatalogTabs from '@components/web/src/pages/widget/Catalog/CatalogTabs';
import DownloadAppModal from '@components/web/src/templates/Modals/DownloadAppModal/DownloadAppModal';
import EventsModal from '@components/web/src/templates/Modals/Events/EventsModal/EventsModal';

const ProductCatalogContainer: FC = () => {
  const { productCatalog } = localeCommon;

  const dispatch = useDispatch();
  const { isEnableTastePathAddon } = useAddons();
  const { fidelityCardIdFromKiosk } = useApp();
  const { retailerSlug } = useRetailer();
  const { retailerLocationCatalogType, retailerPhysicalAddress } = useRetailerLocation();
  const { isDesignSetVinhoodExperience, isDesignSetVinhoodApp } = useRetailerDesignSet();

  const { closeAllModals, isDownloadAppModalOpened } = useModals();

  const promotionQrCodeURL = RouteUtils.generateRedirectURL({
    [ACTION_PERFORMED_URL_PARAM]: MP_ACTION_PERFORMED_CONTEXT.SCAN,
    [FIDELITY_ID_URL_PARAM]: `${retailerSlug}_${fidelityCardIdFromKiosk}`,
    [MIXPANEL_EVENT_SLUG_URL_PARAM]: MP_EVENTS.SPECIAL_PROMO_DISPLAYED.SLUG,
    [REDIRECT_URL_PARAM]: retailerPhysicalAddress,
  });

  // should be updated for opening Promotion coupon on mobile kiosk
  const openMobileKioskPromotion = () => {
    window.open(promotionQrCodeURL, '_blank');
    dispatch(setFidelityCardIdFromKiosk(''));
  };

  const isLimitedCatalog = retailerLocationCatalogType === LIMITED_CATALOG;
  const isExtendedCatalog = retailerLocationCatalogType === EXTENSIVE_CATALOG;

  if (isDesignSetVinhoodApp) {
    return <B2CProductCatalogContainer />;
  }

  if (isDesignSetVinhoodExperience) {
    const catalogContainer =
      (isLimitedCatalog && <WidgetLimitedCatalogContainer />) ||
      (isExtendedCatalog && <WidgetExtendedCatalogContainer />) ||
      null;

    return (
      <>
        <WidgetCatalogContainerHOC>
          {isEnableTastePathAddon ? <CatalogTabs catalogContainer={catalogContainer} /> : catalogContainer}
        </WidgetCatalogContainerHOC>

        {isDownloadAppModalOpened && (
          <DownloadAppModal
            isModalOpen={isDownloadAppModalOpened}
            hideModal={() => {
              closeAllModals();
              dispatch(setShouldHideDownloadAppCard());
            }}
          />
        )}

        {!!fidelityCardIdFromKiosk && (
          <EventsModal
            withButton
            buttonText={productCatalog.modalBtnShowCoupon}
            description={productCatalog.mobileModalDescription}
            handleClick={openMobileKioskPromotion}
            icon="Bottles"
            isOpen={!!fidelityCardIdFromKiosk}
            modalType={ModalTypes.PromoQrModal}
            title={productCatalog.modalTitle}
            hideModal={() => {
              dispatch(setFidelityCardIdFromKiosk(''));
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default ProductCatalogContainer;
