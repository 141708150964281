import { ReactComponent as RightIcon } from '@components/web/src/assets/legacy/icons/arrow-right.svg';
import { ReactComponent as CircleIcon } from '@components/web/src/assets/legacy/icons/btn_circle_icon.svg';
import { ReactComponent as PlusIcon } from '@components/web/src/assets/legacy/icons/plus-icon.svg';
import * as S from '@components/web/src/atoms/ButtonsGroup/styles';
import {
  IButtonGroupItem,
  IButtonGroupItemPosition,
  IButtonsGroupProps,
  TButtonIconVariants,
} from '@components/web/src/atoms/ButtonsGroup/types';

export const ButtonGroupItem = ({
  icon,
  iconVariant = 'none',
  handleClick,
  id,
  text,
  isActive,
  position,
  ...rest
}: IButtonGroupItem) => {
  const icons: TButtonIconVariants = {
    circle: <CircleIcon />,
    left: <RightIcon style={{ transform: 'rotate(180deg)' }} />,
    none: null,
    plus: <PlusIcon />,
    right: <RightIcon />,
  };

  const renderedIcon = icon || icons[iconVariant];
  return (
    <S.ButtonGroupItem
      $isActive={isActive}
      $position={position}
      data-testid={`ButtonGroupItem-${isActive}-${position}`}
      onClick={() => handleClick(id)}
      {...rest}
    >
      {renderedIcon && <S.Icon>{renderedIcon}</S.Icon>}
      {text && <S.StyledText $isActive={isActive} size="body1" text={text} weight="normal" />}
    </S.ButtonGroupItem>
  );
};

export const ButtonsGroup = ({ itemList, handleClick }: IButtonsGroupProps) => {
  return (
    <S.ButtonsGroup data-testid="ButtonsGroup">
      {itemList?.map((item, idx) => {
        let position: IButtonGroupItemPosition = 'middle';
        if (idx === 0) {
          position = 'left';
        } else if (idx === itemList.length - 1) {
          position = 'right';
        }
        return <ButtonGroupItem key={item.id} handleClick={handleClick} position={position} {...item} />;
      })}
    </S.ButtonsGroup>
  );
};

export default ButtonsGroup;
