import styled, { css } from 'styled-components';

import Btn from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ExperienceCardContainer = styled.div<{ $isResponsive: boolean }>`
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ $isResponsive }) =>
    $isResponsive &&
    css`
      width: 100%;
    `}
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 180px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
`;

export const StyledProductName = styled(Text)`
  min-height: 60px;
  max-height: 60px;
`;

export const PriceRangeWrapper = styled.div`
  margin-top: 4px;

  p {
    margin: 0;
  }
`;

export const Button = styled(Btn)`
  width: 100%;
  margin-top: auto;

  span {
    text-decoration: underline;
  }
`;
