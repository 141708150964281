import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuthFeatureClick } from '@app/web/src/hooks/useAuthFeatureClick';

import { useCommentsHandler } from '@lib/core/comments/hooks/useCommentsHandler';
import { TProductCategory } from '@lib/core/products/types';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';
import useTastePathData from '@lib/core/service/hooks/useTastePathData';
import { useThirdPartyNewsletter } from '@lib/core/service/hooks/useThirdPartyNewsletter';
import { setHideTastePathTooltipModal, setShowTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useFeedback, useProductList, useUser } from '@lib/core/users/hooks';
import { actionGetFeedbacks } from '@lib/core/users/slices/feedbacks';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { setNextTastePathCategory } from '@lib/tools/tastePathProducts/slices/index';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import TastePathPage from '@components/web/src/pages/widget/TastePathPage/TastePathPage';

const TastePathContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isEnableLocationMapAddon,
    isEnableAppBanner,
    isEnableVusionAddon,
    isShowProductLocationAddon,
    isEnableTastePathAddon,
  } = useAddons();

  const { userSessionId, isFetchingUserData, isProfileLoading } = useUser();
  const { locale, productCategory, shouldHideDownloadAppCard, shouldHideTastePathTooltipModal } = useApp();

  const {
    isTastePathProductsLoading,
    tastePathData,
    currentTastePathProduct,
    nextTastePathCategory,
    shouldChangeTastePathCategory,
    isTastePathDone,
    tasteJourneyData,
    shouldDisplaySkipCategoryBtn,
  } = useTastePathData();

  const { handleThirdPartyNewsletter, isShowThirdPartyNewsletter } = useThirdPartyNewsletter();
  const { retailerLocationName, isRetailerLocationLoading } = useRetailerLocation();
  const { isRetailerLoading } = useRetailer();
  const producerName = retailerLocationName || '';
  const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner;
  const { handleUpdateFeedback, feedbackData, lastUpdatedFeedbackId } = useFeedback();
  const { wishlistProductInstanceIds, isWishlistProductListLoading, handleUpdateWishlistProductList } =
    useProductList();

  const { discoveryQuiz } = useDiscoveryQuizData();

  const filteredByCategoryFeedbackData = useMemo(
    () => feedbackData.filter(feedback => feedback.gprl.product.category === productCategory),
    [feedbackData, productCategory],
  );

  useEffect(() => {
    if (shouldChangeTastePathCategory && tastePathData) {
      dispatch(setNextTastePathCategory());
    }
  }, [shouldChangeTastePathCategory, tastePathData]);

  useEffect(() => {
    dispatch(actionGetFeedbacks());
  }, [userSessionId]);

  const navigateToTastePathStartPage = () => {
    dispatch(setShowTastePathStartPage(true));
    navigate(prependBasename(PAGES.vinhood.tastePathStartPart));
  };

  const { handleAuthFeatureClick } = useAuthFeatureClick();

  const {
    commentsList,
    isCommentsLoaded,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
    handleSubmitComment,
  } = useCommentsHandler();

  const isLoading =
    isFetchingUserData ||
    isProfileLoading ||
    isRetailerLoading ||
    isRetailerLocationLoading ||
    isTastePathProductsLoading;

  return (
    <TastePathPage
      commentsList={commentsList}
      discoveryQuiz={discoveryQuiz}
      feedbackData={filteredByCategoryFeedbackData}
      handleAuthFeatureClick={handleAuthFeatureClick}
      handleCloseTooltip={() => dispatch(setHideTastePathTooltipModal())}
      handleSkipTastePathCategory={() => tastePathData && dispatch(setNextTastePathCategory())}
      handleSubmitComment={handleSubmitComment}
      handleThirdPartyNewsletter={handleThirdPartyNewsletter}
      handleUpdateFeedback={handleUpdateFeedback}
      handleUpdateWishlistProductList={handleUpdateWishlistProductList}
      isCommentsLoaded={isCommentsLoaded}
      isEnableLocationMapAddon={isEnableLocationMapAddon}
      isEnableTastePathAddon={isEnableTastePathAddon}
      isEnableVusionAddon={isEnableVusionAddon}
      isNewCommentsDataFetching={isNewCommentsDataFetching}
      isShowProductLocationAddon={isShowProductLocationAddon}
      isShowThirdPartyNewsletter={isShowThirdPartyNewsletter}
      isTastePathDone={isTastePathDone}
      isTastePathProductsLoading={isLoading}
      isWishlistProductListLoading={isWishlistProductListLoading}
      lastUpdatedFeedbackId={lastUpdatedFeedbackId}
      locale={locale}
      navigateToTastePathStartPage={navigateToTastePathStartPage}
      nextTastePathCategory={nextTastePathCategory}
      producerName={producerName}
      productCategory={productCategory as TProductCategory}
      productInstanceData={currentTastePathProduct}
      setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
      shouldDisplaySkipCategoryBtn={shouldDisplaySkipCategoryBtn}
      shouldHideTastePathTooltipModal={shouldHideTastePathTooltipModal}
      shouldShowDownloadAppCard={shouldShowDownloadAppCard}
      tasteJourneyData={tasteJourneyData}
      wishlistProductInstanceIds={wishlistProductInstanceIds}
    />
  );
};

export default TastePathContainer;
