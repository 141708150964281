import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import CharacterInfo, {
  ICharacterInfoCardProps,
} from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import * as S from '@components/web/src/organisms/CharacterInfoGroup/styles';

interface IProps {
  characterInfoList: ICharacterInfoCardProps[];
}

const CharacterInfoGroup: FC<IProps> = ({ characterInfoList }) => {
  const { title } = localeCommon.characterInfoGroup;

  return (
    <S.CharacterInfoContainer data-testid="CharacterInfoGroupContainer">
      <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={title} weight="semibold" />
      <S.InfoContainer>
        {characterInfoList?.map(infoItem => <CharacterInfo key={infoItem.icon} {...infoItem} />)}
      </S.InfoContainer>
    </S.CharacterInfoContainer>
  );
};

export default CharacterInfoGroup;
