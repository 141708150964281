import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ProfileAvatar = styled(Flexbox)`
  width: 94px;
  height: 94px;
  margin-right: 6px;
  border: 4px solid ${STATIC_COLORS.base.white};
  border-radius: 200px;
  box-shadow: ${STATIC_SHADOWS.lg};
`;

export const ProfileAction = styled.div``;

export const ProfileInput = styled.input`
  display: none;
`;

export const ProfileError = styled.div``;
