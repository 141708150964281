import styled from 'styled-components';

import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const RegisterDetailsBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: fit-content;
  padding: 32px 16px;
  text-align: center;
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const CloseIcon = styled(CloseButton)`
  margin: 0 0 -20px auto;
`;

export const StyledText = styled(Text)`
  & > br {
    display: none;
  }
`;

export const AuthOptionsWrapper = styled.div`
  margin: 32px 16px;
`;
