import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const CommentContainer = styled(Flexbox)`
  background: ${STATIC_COLORS.warmGray[100]};
  border-radius: 6px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 5px;
  padding-left: 2px;
  padding-bottom: 8px;
`;
