/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { FC } from 'react';

export interface ICheckbox {
  text?: string;
  id?: string;
  description?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: () => void;
  className?: string;
  label?: any;
}

const Checkbox: FC<ICheckbox> = ({
  text,
  id,
  description,
  isSelected,
  isDisabled = false,
  isRequired = false,
  className = '',
  onChange,
  label,
}) => (
  <div className={classNames('mp-checkbox-container', className)}>
    <input
      checked={isSelected}
      className="custom-checkbox"
      disabled={isDisabled}
      id={id}
      name={text}
      required={isRequired}
      type="checkbox"
      onChange={onChange}
    />
    <label className="text" htmlFor={id}>
      <div className="content-wrapper">
        {text || label}
        {!!description && <p className="description">{description}</p>}
      </div>
    </label>
  </div>
);

export default Checkbox;
