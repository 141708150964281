import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import {
  BEER_PRICE_RANGE,
  DEFAULT_PRICE_RANGE,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_WINE,
  WINE_PRICE_RANGE,
} from '@lib/tools/shared/helpers/consts';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  originalPrice: number;
  currencySymbolValue: string;
  productCategory?: TProductCategory;
  customPriceRange?: number[];
}

const PriceRange: FC<IProps> = ({ originalPrice, currencySymbolValue, productCategory = '', customPriceRange }) => {
  if (originalPrice <= 0 || originalPrice === null) {
    return null;
  }

  const priceRangeGray = '#979eac';
  const priceRangeBlack = '#101828';

  const generateRanges = (breakpoints: number[]) => {
    return breakpoints.map((breakpoint, index) => ({
      colors: [...Array(index + 1).fill(priceRangeBlack), ...Array(4 - index - 1).fill(priceRangeGray)],
      maxPrice: breakpoint,
    }));
  };

  const getPriceRangeColors = (
    productCategoryForPriceRange: TProductCategory,
    price: number,
    customRange?: number[],
  ): string[] => {
    let ranges;

    if (productCategoryForPriceRange) {
      switch (productCategoryForPriceRange) {
        case PRODUCT_CATEGORY_WINE:
          ranges = generateRanges(WINE_PRICE_RANGE);
          break;
        case PRODUCT_CATEGORY_BEER:
          ranges = generateRanges(BEER_PRICE_RANGE);
          break;
        default:
          ranges = generateRanges(DEFAULT_PRICE_RANGE);
          break;
      }
    } else if (customRange) {
      ranges = generateRanges(customRange);
    } else {
      ranges = generateRanges(DEFAULT_PRICE_RANGE);
    }

    const selectedRange = ranges.find(range => price <= range.maxPrice) || ranges[ranges.length - 1];

    return selectedRange.colors;
  };

  const fontColors = getPriceRangeColors(productCategory, originalPrice, customPriceRange);

  return (
    <Flexbox align="center" gap={0} justify="flex-end">
      {fontColors.map((color, index) => (
        <Text key={`${color}-${index}`} color={color} size="subtitle1" text={currencySymbolValue} weight="semibold" />
      ))}
    </Flexbox>
  );
};

export default PriceRange;
