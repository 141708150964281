import { Dispatch, FC, SetStateAction } from 'react';

import { IPreference } from '@app/native/src/models/UserFoodPreferences.model';
import CharacterMapScrollerContainer from '@app/web/src/containers/CharacterMapScrollerContainer';

import { TCharacter } from '@lib/core/characters/types';
import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  JOURNAL_PLACES_VARIANT,
  JOURNAL_PRODUCT_VARIANT,
  PRODUCT_CATEGORY_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ICharacterAdjectiveItemData } from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import RegistrationBanner from '@components/web/src/components/Authentication/RegistrationBanner/RegistrationBanner';
import { TSwiperData } from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';
import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import ProfileProgressBar from '@components/web/src/organisms/Cards/MyTasteProfileCard/ProfileProgressBar/ProfileProgressBar';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import CharacterAdjectivesGroup from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import CharacterInfoGroup from '@components/web/src/organisms/CharacterInfoGroup/CharacterInfoGroup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import BuyerPrinciples from '@components/web/src/templates/TasteId/TasteIdPage/BuyerPrinciples/BuyerPrinciples';
import { JournalSwiper } from '@components/web/src/templates/TasteId/TasteIdPage/JournalSwiper/JournalSwiper';
import RedirectFooter from '@components/web/src/templates/TasteId/TasteIdPage/RedirectFooter/RedirectFooter';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/styles';

interface IProps {
  locale: string;
  characterAdjectiveItemsData: ICharacterAdjectiveItemData[];
  characterAdjectiveModalData: TSwiperData[];
  characterData: TCharacter;
  productCategory: TProductCategory;
  feedbackData: IFeedbackData[];
  wishlistProductInstanceIds: string[];
  characterDetailCardsData: IReadMoreCardProps[];
  characterInfoCardsData: ICharacterInfoCardProps[];
  discoveryQuiz: IDiscoveryQuiz;
  isTasteIdResultPageAddon?: boolean;
  isWishlistProductListLoading?: boolean;
  isUserAuthenticated?: boolean;
  isProductInstanceInWishlist?: boolean;
  userPreferences: IPreference[];
  storeType: TRetailerLocationStoreType;
  productSwiperData?: TProductInstance[];
  profileProgress: number;
  locationSwiperData?: ILocation[];
  isLocationListLoading: boolean;
  handleUpdateLocationList: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
  navigateToJournalPage?: () => void;
  handleNavigateClick?: (link: string, params?: { [key: string]: string }) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string, productName?: string) => void;
  isCommentsLoaded: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  shouldHideComment?: boolean;
}

const TasteIdPage: FC<IProps> = ({
  locale,
  characterAdjectiveItemsData,
  characterAdjectiveModalData,
  characterData,
  productCategory,
  characterDetailCardsData,
  characterInfoCardsData,
  isTasteIdResultPageAddon = false,
  isUserAuthenticated = false,
  isWishlistProductListLoading = false,
  isLocationListLoading,
  feedbackData,
  storeType,
  discoveryQuiz,
  wishlistProductInstanceIds,
  isProductInstanceInWishlist,
  userPreferences,
  productSwiperData,
  profileProgress,
  locationSwiperData,
  handleUpdateWishlistProductList,
  handleUpdateFeedback,
  handleNavigateClick,
  handleUpdateLocationList,
  isCommentsLoaded,
  commentsList,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
  shouldHideComment,
}) => {
  const { completeProfile } = localeCommon.tasteId;

  return (
    <S.TasteIdPageContainer data-testid={`TasteIdPage-${productCategory}`}>
      {profileProgress < 100 && (
        <S.ProfileProgressBarWrapper>
          <ProfileProgressBar
            progress={profileProgress}
            title={completeProfile}
            variant="detached"
            onClick={() =>
              handleNavigateClick(PAGES.vinhood.tasteId.tasteIdSettings, {
                [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
              })
            }
          />
        </S.ProfileProgressBarWrapper>
      )}
      <CharacterAdjectivesGroup
        characterAdjectiveItemsData={characterAdjectiveItemsData}
        characterAdjectiveModalData={characterAdjectiveModalData}
        characterData={characterData}
        isUserAuthenticated={isUserAuthenticated}
        productCategory={productCategory}
      />
      <S.CharacterDetailsSection data-testid="CharacterDetailsSection">
        {characterDetailCardsData?.map(({ description, cardTitle, title, minimumCharacters }, idx) => (
          <ReadMoreCard
            key={idx}
            cardTitle={cardTitle}
            cardTitleLocaleIndex={{ productCategory }}
            description={description}
            minimumCharacters={minimumCharacters}
            productCategory={productCategory}
            title={title}
          />
        ))}
      </S.CharacterDetailsSection>
      <CharacterInfoGroup characterInfoList={characterInfoCardsData} />
      {isUserAuthenticated && !isTasteIdResultPageAddon && (
        <>
          <BuyerPrinciples
            navigateToTagsPage={() => handleNavigateClick(PAGES.vinhood.tasteId.tasteIdTags)}
            productCategory={productCategory}
            userPreferences={userPreferences}
          />
          <JournalSwiper
            shouldShowFeedbackTooltip
            commentsList={commentsList}
            discoveryQuiz={discoveryQuiz}
            feedbackData={feedbackData}
            handleEmptyStateNavigate={url => handleNavigateClick(url)}
            handleNavigateClick={handleNavigateClick}
            handleSubmitComment={handleSubmitComment}
            handleUpdateFeedback={handleUpdateFeedback}
            handleUpdateWishlistProductList={handleUpdateWishlistProductList}
            isCommentsLoaded={isCommentsLoaded}
            isNewCommentsDataFetching={isNewCommentsDataFetching}
            isProductInstanceInWishlist={isProductInstanceInWishlist}
            isWishlistProductListLoading={isWishlistProductListLoading}
            locale={locale}
            productCategory={productCategory}
            setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
            shouldHideComment={shouldHideComment}
            storeType={storeType}
            swiperData={productSwiperData}
            variant={JOURNAL_PRODUCT_VARIANT}
            wishlistProductInstanceIds={wishlistProductInstanceIds}
          />
          {isUserAuthenticated && (
            <JournalSwiper
              commentsList={commentsList}
              discoveryQuiz={discoveryQuiz}
              handleEmptyStateNavigate={url => handleNavigateClick(url)}
              handleNavigateClick={handleNavigateClick}
              handleSubmitComment={handleSubmitComment}
              handleUpdateLocationList={handleUpdateLocationList}
              isCommentsLoaded={isCommentsLoaded}
              isLocationListLoading={isLocationListLoading}
              isNewCommentsDataFetching={isNewCommentsDataFetching}
              productCategory={productCategory}
              setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              shouldHideComment={shouldHideComment}
              swiperData={locationSwiperData}
              variant={JOURNAL_PLACES_VARIANT}
            />
          )}
        </>
      )}
      <CharacterMapScrollerContainer characterName={characterData?.name} productCategory={productCategory} />
      {!isUserAuthenticated && isTasteIdResultPageAddon && (
        <S.BannerWrapper>
          <RegistrationBanner
            handleNavigateToRegistrationPage={() => handleNavigateClick(PAGES.vinhood.registration)}
          />
        </S.BannerWrapper>
      )}
      {isUserAuthenticated && !isTasteIdResultPageAddon && <RedirectFooter handleNavigateClick={handleNavigateClick} />}
    </S.TasteIdPageContainer>
  );
};

export default TasteIdPage;
