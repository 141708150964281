import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useComments } from '@lib/core/comments/hooks/useComments';
import { actionCreateComment, actionGetComments, actionUpdateComment } from '@lib/core/comments/slices/comments';
import { ICommentHandleSubmit } from '@lib/core/comments/types';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

export const useCommentsHandler = () => {
  const dispatch = useDispatch();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { isCommentCreated, isCommentUpdated, commentsList, isCommentsLoading } = useComments();

  const isCommentsLoaded = isCommentsLoading || isCommentUpdated || isCommentCreated;
  const [isNewCommentsDataFetching, setIsNewCommentsDataFetching] = useState(false);
  const handleSubmitComment = ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => {
    if (isCommentInList) {
      dispatch(actionUpdateComment({ identifier: commentFromList?.identifier, updateComment: comment }));
    } else {
      dispatch(actionCreateComment({ newComment: comment, productIdentifier: productId }));
    }
    setIsNewCommentsDataFetching(true);
  };

  useEffect(() => {
    if (isNewCommentsDataFetching && (!isCommentUpdated || !isCommentCreated)) {
      dispatch(actionGetComments());
    }
  }, [isCommentCreated, isNewCommentsDataFetching, isCommentUpdated]);
  return {
    commentsList,
    handleSubmitComment,
    isCommentsLoaded,
    isDesignSetVinhoodApp,
    isNewCommentsDataFetching,
    setIsNewCommentsDataFetching,
  };
};
