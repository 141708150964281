import { FC } from 'react';

import * as S from '@components/web/src/atoms/Checkboxes&Radio/CheckboxAnswer/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export interface ICheckboxAnswer {
  slug: string;
  name: string;
  isSelected: boolean;
}

interface IProps {
  data: ICheckboxAnswer;
  isResponsive?: boolean;
  handleClick: (slug: string) => void;
}

export const CheckboxAnswer: FC<IProps> = ({ data, isResponsive = false, handleClick }) => {
  const { slug, name, isSelected } = data;

  const textColor = isSelected ? STATIC_COLORS.base.white : STATIC_COLORS.base.black;
  const textWeight = isSelected ? 'semibold' : 'normal';

  return (
    <S.CheckboxAnswer
      $isResponsive={isResponsive}
      $isSelected={isSelected}
      data-testid="CheckboxAnswer"
      onClick={() => handleClick(slug)}
    >
      <S.Icon $isSelected={isSelected} />
      <Text color={textColor} size="body1" text={name} weight={textWeight} />
    </S.CheckboxAnswer>
  );
};
