import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import UserCharacterInfoImage from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoImage';

type Props = { characterData: TCharacter; productCategory: TProductCategory };

const UserCharacterInfoItem: FC<Props> = ({ characterData, productCategory }) => {
  const { name: characterName = '', character_caption: characterCaption = '' } = characterData || {};

  const { productCategories } = localeCommon;

  const { userCharacterInfoItem } = localeApp;

  const missingCaption = {
    [PRODUCT_CATEGORY_BEER]: <LocaleFragment message={userCharacterInfoItem.missingBeerExpereince} />,
    [PRODUCT_CATEGORY_COFFEE]: <LocaleFragment message={userCharacterInfoItem.missingCoffeeExpereince} />,
    [PRODUCT_CATEGORY_WINE]: <LocaleFragment message={userCharacterInfoItem.missingWineExpereince} />,
  };

  return (
    <div className="info-item-container">
      <UserCharacterInfoImage image={characterData?.image} productCategory={productCategory} />
      <div>
        <p className="title">
          {characterName ? (
            <LocaleFragment
              message={userCharacterInfoItem.characterInfoTitle}
              options={{ characterName }}
              variables={{ productCategoryText: productCategories[productCategory] }}
            />
          ) : (
            <LocaleFragment message={userCharacterInfoItem.missingCharacterTitle} />
          )}
        </p>
        <div className="subTitle">{characterCaption || missingCaption[productCategory]}</div>
      </div>
    </div>
  );
};

export default UserCharacterInfoItem;
