/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from '@formatjs/intl';

export const localeKiosk = {
  homePageCards: {
    recipeCard: defineMessages({
      description: {
        defaultMessage:
          'Do you have a **recipe** in mind, to **make a gift**, to **organize an event**? I’ll match you with the perfect product!',
        id: 'vinhood.vinhood_kiosk.homePage.TextSituationalTestLogged',
      },
      btn: {
        defaultMessage: 'Your occasion',
        id: 'vinhood.vinhood_kiosk.homePage.CardButtonTextSituationalTest',
      },
    }),
    tasteTestCard: defineMessages({
      description: {
        defaultMessage:
          '**Take the Taste Test** to let me know what you like and discover **{ productCategory, select, wine {your ideal { productCategoryText }} beer {your ideal { productCategoryText }} coffee {your ideal { productCategoryText }} other{}}!**',
        id: 'vinhood.vinhood_kiosk.homePage.CardTextTasteTest',
      },
      btn: {
        defaultMessage: 'Your taste',
        id: 'vinhood.vinhood_kiosk.homePage.CardButtonTextTasteTest',
      },
    }),
    anonymousCard: defineMessages({
      title: {
        defaultMessage: 'Play as anonymous',
        id: 'vinhood.vinhood_kiosk.homePage.CardTitleNoCard',
      },
      topDescription: {
        defaultMessage: 'Can’t find your card at all? It’ll be for next time.',
        id: 'vinhood.vinhood_kiosk.homePage.CardTextNoCard.topDescription',
      },
      bottomDescription: {
        defaultMessage: 'In the meantime, discover your taste and tips for every occasion.',
        id: 'vinhood.vinhood_kiosk.homePage.CardTextNoCard.bottomDescription',
      },
      btn: {
        defaultMessage: 'Let’s start',
        id: 'vinhood.vinhood_kiosk.homePage.CardButtonTextNoCard',
      },
    }),
    activateLoyaltyCard: defineMessages({
      title: {
        defaultMessage: 'Use your brand loyalty card',
        id: 'vinhood.vinhood_kiosk.homePage.CardTitleYesCard',
      },
      description: {
        defaultMessage:
          'Hey! Would you like to take the taste test and associate your loyalty card with the #Character that will result from the test? We will use the character to suggest products that best suit your taste.',
        id: 'vinhood.vinhood_kiosk.homePage.CardTextYesCard',
      },
      cta: {
        defaultMessage: 'Come on! ||Scan your loyalty card!',
        id: 'vinhood.vinhood_kiosk.homePage.CardButtonTextYesCard',
      },
      modalTitle: {
        defaultMessage: 'Loyalty Card information usage',
        id: 'vinhood.vinhood_kiosk.homePage.ModalTitleTextYesCard',
      },
      modalDescription: {
        defaultMessage:
          'We will use the loyalty card only as a key to recognize you each time you scan it here and to save your choices made on this kiosk. The card will be associated with the #Character resulting from the test.',
        id: 'vinhood.vinhood_kiosk.homePage.ModalDescriptionTextYesCard',
      },
    }),
    characterExperienceCard: defineMessages({
      title: {
        defaultMessage:
          '{productCategory, select, wine {The ideal { productCategoryText } for you} beer {The ideal { productCategoryText } for you} coffee {The ideal { productCategoryText } for you} other {}} is #{ characterName }',
        id: 'vinhood.vinhood_kiosk.homePage.CardTitleYourCharacter',
      },
      description: {
        defaultMessage: `This Character determine a substantial part of your taste experience. Manage it carefully!`,
        id: 'vinhood.vinhood_kiosk.homePage.CardTextYourCharacter',
      },
      btn: {
        defaultMessage: 'View the best products for you',
        id: 'vinhood.vinhood_kiosk.homePage.CardButtonTextYourCharacter',
      },
      testAgain: {
        defaultMessage: 'Does your taste like changing? [linkTo=test-page]Test again.[linkTo=test-page]',
        id: 'vinhood.vinhood_kiosk.homePage.CardTextRedoTasteTest',
      },
      missedCharacterTitle: {
        defaultMessage:
          'Here you’ll find **{productCategory, select, wine {the perfect { productCategoryPluralText }} beer {the perfect { productCategoryPluralText }} coffee {the perfect { productCategoryPluralText }} other {}} for you.**',
        id: 'vinhood.vinhood_kiosk.homePage.TextIntroLogged',
      },
      missedCharacterDescription: {
        defaultMessage: `Why don't you see anything yet? Because you haven't taken the Taste Test!`,
        id: 'vinhood.vinhood_kiosk.homePage.TextExplainLogged',
      },
      missedCharacterBtn: {
        defaultMessage: 'Take your test now',
        id: 'vinhood.vinhood_kiosk.homePage.CardButtonTextTasteTestYesCard',
      },
    }),
    productPropositionCard: defineMessages({
      tipTitle: {
        defaultMessage: 'Product Tip of the Day',
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionTipCardTitle',
      },
      wishlistTitleText: {
        defaultMessage: '{quantity, plural, one {# product saved} other {# products saved}}',
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionWishlistCardTitleText',
      },
      ctaReadMoreBtn: {
        defaultMessage: 'Read more',
        id: 'vinhood.vinhood_kiosk.homePage.ctaReadMoreBtn',
      },
      viewAll: {
        defaultMessage: 'view all',
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionCardViewAll',
      },
      nothingHere: {
        defaultMessage: `Oh no! ||There is nothing here yet!`,
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionCardNothingHere',
      },
      notWorryTakeTest: {
        defaultMessage: `But don't worry. ||As soon as you do the taste test we will be able ||to give you the best advice.`,
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionCardNotWorryTakeTest',
      },
      notWorrySaveProducts: {
        defaultMessage: `But don't worry. ||As soon as you {productCategory, select, wine {save some { productCategoryPluralText }} beer {save some { productCategoryPluralText }} coffee {save some { productCategoryPluralText }} other {}}, ||they'll be waiting for you here.`,
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionCardNotWorrySaveProducts',
      },
      takeTest: {
        defaultMessage: `Take your test now`,
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionCardTakeTest',
      },
      viewCatalog: {
        defaultMessage: `View products catalog`,
        id: 'vinhood.vinhood_kiosk.homePage.productPropositionCardViewCatalog',
      },
    }),
    promotionCard: defineMessages({
      title: {
        defaultMessage: 'Special promo for you',
        id: 'vinhood.vinhood_kiosk.homePage.promotionCard.title',
      },
    }),
  },
  heroSection: defineMessages({
    subTitle: {
      defaultMessage: 'Let me help you find the perfect one!',
      id: 'vinhood.vinhood_kiosk.homePage.PageSubtitle',
    },
    title: {
      defaultMessage:
        'Hey, { productCategory, select, wine {looking for the ideal { productCategoryText }} beer {looking for the ideal { productCategoryText }} coffee {looking for the ideal { productCategoryText }} other {}} for you?',
      id: 'vinhood.vinhood_kiosk.homePage.PageTitle',
    },
    retailerTitle: {
      defaultMessage:
        '{ productCategory, select, beer {The right { productCategoryText } for you is here} other {The right { productCategoryText } for you is here}}',
      id: 'vinhood.vinhood_kiosk.homePage.PageTitleVariant1',
    },
  }),
  characterDetails: defineMessages({
    characterTitle: {
      defaultMessage: 'The ideal products for you have this Character:',
      id: 'vinhood.vinhood_kiosk.characterDetails.ResultIntroText',
    },
  }),
  fidelityDetachButton: defineMessages({
    fidelityCardLabel: {
      defaultMessage: 'Detach my loyalty card',
      id: 'vinhood.vinhood_kiosk.header.detachMyLoyaltyCard',
    },
  }),
  productCatalog: defineMessages({
    productsListHeadText: {
      defaultMessage: 'These are the ones we selected just thinking of you. ',
      id: 'vinhood.vinhood_kiosk.catalog.productsListHeadText',
    },
  }),
  homeQRSection: defineMessages({
    titleMobile: {
      defaultMessage: 'Move on your mobile!',
      id: 'vinhood.vinhood_kiosk.homePage.PrefooterTitleMobile',
    },
    subTitleMobile: {
      defaultMessage: 'Experience the kiosk directly on your smartphone.',
      id: 'vinhood.vinhood_kiosk.homePage.PrefooterTextMobile',
    },
    titleScan: {
      defaultMessage: 'Did you choose a product? ',
      id: 'vinhood.vinhood_kiosk.homePage.PrefooterTitleScan',
    },
    subTitleScan: {
      defaultMessage: 'Use the kiosk’s barcode scanner below to discover interesting **facts** about that product!',
      id: 'vinhood.vinhood_kiosk.homePage.PrefooterTextScan',
    },
  }),
  resultPage: defineMessages({
    btn: {
      defaultMessage:
        '{productCategory, select, wine {See all #{ characterName } { productCategoryPluralText }} beer {See all #{ characterName } { productCategoryPluralText }} coffee {See all #{ characterName } { productCategoryPluralText }} other {}}',
      id: 'vinhood.vinhood_kiosk.resultPage.ResultCtaCatalog',
    },
    scanTitle: {
      defaultMessage: 'Scan your loyalty card to save this result',
      id: 'vinhood.vinhood_kiosk.resultPage.ResultTitleScan',
    },
    scanDescription: {
      defaultMessage: 'Use the kiosk’s barcode scanner below to link this result to your card.',
      id: 'vinhood.vinhood_kiosk.resultPage.ResultTextScan',
    },
  }),
  fidelityCard: defineMessages({
    notFoundFidelityCard: {
      defaultMessage: '**Ooops** ||Sorry, I looked everywhere, but could not ||recognize the barcode you scanned.',
      id: 'vinhood.vinhood_kiosk.loyaltyCard.notFoundLoyaltyCard',
    },
    foundFidelityCard: {
      defaultMessage: 'You did it! We recognized your card. ||Let the experience begin!',
      id: 'vinhood.vinhood_kiosk.loyaltyCard.foundLoyaltyCard',
    },
    alreadyAssociatedFidelityCard: {
      defaultMessage:
        'Taste Match: your new favourite function to discover the affinity between a product and your palate. Do the Taste Test again to unlock it completely!',
      id: 'vinhood.vinhood_kiosk.loyaltyCard.alreadyAssociatedFidelityCard',
    },
    disconnectedFidelityCard: {
      defaultMessage: '**Done!** ||You have disconnected from the kiosk.',
      id: 'vinhood.vinhood_kiosk.loyaltyCard.disconnectedLoyaltyCard',
    },
  }),
  SituationalResultPageText: defineMessages({
    SituationalPageTitleText: {
      defaultMessage: 'The ideal products for you have one these Characters:',
      id: 'vinhood.vinhood_kiosk.situationalResultPage.titleText',
    },
    SituationalPageHeaderText: {
      defaultMessage: 'Our recommendation for your need',
      id: 'vinhood.vinhood_kiosk.situationalResultPage.HeaderText',
    },
  }),
  welcomePageText: defineMessages({
    pageTitle: {
      defaultMessage:
        '{ productCategory, select, other {Find the ideal {productCategoryText} ||for you or your occasion}}',
      id: 'vinhood.vinhood_kiosk.welcomePage.pageTitle',
    },
    pageInfo: {
      defaultMessage: 'powered by **Vinhood**',
      id: 'vinhood.vinhood_kiosk.welcomePage.pageInfo',
    },
    ctaBtn: {
      defaultMessage: 'Start',
      id: 'vinhood.vinhood_kiosk.welcomePage.ctaBtn',
    },
  }),
};
