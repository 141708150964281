import { FC } from 'react';

import { TLanguage } from '@lib/core/retailers/types';

import LanguageItem from '@components/web/src/organisms/LanguageSwitcher/LanguageItem';
import * as S from '@components/web/src/organisms/LanguageSwitcher/LanguageList/styles';

interface IProps {
  filteredLanguagesList: TLanguage[];
  handleSelectLanguage: (code: string) => void;
}

const LanguageList: FC<IProps> = ({ filteredLanguagesList, handleSelectLanguage }) => {
  return (
    <S.LanguageDropdownList isFullWidth align="flex-start" data-testid="LanguageList" direction="column" gap={0}>
      {filteredLanguagesList?.length > 0 &&
        filteredLanguagesList?.map(({ code, language }) => (
          <LanguageItem
            key={code}
            isListItem
            handleSelectLanguage={handleSelectLanguage}
            languageCode={code}
            languageText={language}
          />
        ))}
    </S.LanguageDropdownList>
  );
};

export default LanguageList;
