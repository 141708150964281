import styled from 'styled-components';

import { DEFAULT_COLOR_THEME } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ResultLoadingScreenWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

export const TastePathPageContainer = styled(Flexbox)`
  width: 100%;
  padding-bottom: 32px;
  background-color: ${DEFAULT_COLOR_THEME.secondaryColor['-100']};
`;

export const TastePathContent = styled(Flexbox)`
  gap: 16px;
  width: 100%;
  padding: 16px 16px 0;
`;

export const DescriptionSection = styled.div``;

export const styledButton = styled.button`
  all: unset;
  cursor: pointer;
`;

export const ProductCardSection = styled.div`
  position: relative;
`;

export const TastePathTooltipModal = styled.div`
  position: absolute;
  top: 45px;
  right: 30px;
`;

export const PathProductsListSection = styled(Flexbox)`
  padding: 32px 16px 16px;
`;
