import { MAP_SUB_CATEGORY } from '@lib/tools/shared/helpers/consts';

import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/CharactersMapScroller/CharactersMap/styles';

const CharacterMapCard = ({ lang = 'en', ...rest }) => {
  const { image: characterImage, name: characterName, character_caption: characterCaption, attributes = {} } = rest;
  const characterSubCategory = attributes[`${MAP_SUB_CATEGORY}${lang}`] || '';
  return (
    <S.CharacterMapCardContainer
      isFullWidth
      align="flex-start"
      data-itemid={characterName}
      data-testid={`CharacterMapCard-${characterName}`}
      direction="column"
      gap={10}
      justify="flex-start"
    >
      <S.CharacterMapCardImageContainer justify="center">
        <Image alt="characters product" objectFit="contain" src={characterImage} />
      </S.CharacterMapCardImageContainer>
      <S.CharacterMapCardInfoContainer isFullWidth direction="column" gap={0}>
        <S.UpperCasedText size="body2" text={characterSubCategory} />
        <S.CharacterNameText size="h5" text={characterName} weight="semibold" />
        <Text size="body1" text={characterCaption} />
      </S.CharacterMapCardInfoContainer>
    </S.CharacterMapCardContainer>
  );
};

export default CharacterMapCard;
