import { FC, useMemo } from 'react';

import { currencies } from '@lib/tools/locale/utils/consts';
import {
  FILTER_TYPE_MOOD,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRODUCT_CATEGORY,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import FilterComponent from '@components/web/src/components/Catalog/Filter/FilterComponent/FilterComponent';
import {
  FILTER_VALUE_EXPERIENCES_PRICE_MAX,
  FILTER_VALUE_EXPERIENCES_PRICE_MIN,
} from '@components/web/src/foundations/consts';

type Props = {
  handleClose: () => void;
  handleApply: () => void;
};

const ExperienceFilterContainer: FC<Props> = ({ handleClose, handleApply }) => {
  const { moodPreferencesData = {} } = useTypedSelector(state => state.productPreferences);

  const moodFilterData = useMemo(() => {
    const newData = [];
    Object.keys(moodPreferencesData).forEach(mood => {
      newData.push({
        filterType: FILTER_TYPE_MOOD,
        isActive: true,
        name: moodPreferencesData[mood].name,
        value: moodPreferencesData[mood].slug,
      });
    });
    return newData;
  }, [moodPreferencesData]);

  const filtersDataParsed: any[] = [
    {
      filterType: FILTER_TYPE_PRICE_RANGE,
      listContent: [
        {
          currency: currencies.EUR,
          max: FILTER_VALUE_EXPERIENCES_PRICE_MAX,
          min: FILTER_VALUE_EXPERIENCES_PRICE_MIN,
        },
      ],
    },
    {
      filterType: FILTER_TYPE_MOOD,
      listContent: moodFilterData,
    },
    {
      filterType: FILTER_TYPE_PRODUCT_CATEGORY,
      listContent: [
        {
          filterType: FILTER_TYPE_PRODUCT_CATEGORY,
          isActive: true,
          name: PRODUCT_CATEGORY_WINE,
          value: PRODUCT_CATEGORY_WINE,
        },
        {
          filterType: FILTER_TYPE_PRODUCT_CATEGORY,
          isActive: true,
          name: PRODUCT_CATEGORY_BEER,
          value: PRODUCT_CATEGORY_BEER,
        },
        {
          filterType: FILTER_TYPE_PRODUCT_CATEGORY,
          isActive: true,
          name: PRODUCT_CATEGORY_COFFEE,
          value: PRODUCT_CATEGORY_COFFEE,
        },
      ],
    },
  ];

  return <FilterComponent data={filtersDataParsed} handleApply={handleApply} handleClose={handleClose} />;
};

export { ExperienceFilterContainer };
