import { MapContainer, Popup } from 'react-leaflet';
import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const CustomMapContainer = styled(MapContainer)<{ $mapHeight: number }>`
  position: relative;
  z-index: 1;
  height: ${({ $mapHeight }) => `${$mapHeight}px`};
  width: 100%;
`;

export const MapContainerShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
`;

export const CustomPopup = styled(Popup)`
  .leaflet-popup-content-wrapper {
    width: 162px;
    background-color: ${STATIC_COLORS.gray[500]};

    .leaflet-popup-content {
      margin: 8px 12px;

      & > div {
        display: flex;
        align-items: end;
      }
    }
  }

  .leaflet-popup-tip {
    background-color: ${STATIC_COLORS.gray[500]};
  }
`;
