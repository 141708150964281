import { useNavigate, useParams } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { PAGES } from '@lib/tools/views/urls';

import EdutainmentFormPage from '@components/web/src/pages/app/EdutainmentFormPage/EdutainmentFormPage';

const EdutainmentFormContainer = () => {
  const { typeformLink = '', productCategory = 'wine' } = useParams();
  const navigate = useNavigate();

  const handleBackBtnClick = () => {
    navigate(prependBasename(PAGES.vinhood.edutainment));
  };

  return (
    <EdutainmentFormPage
      handleBackBtnClick={handleBackBtnClick}
      // @ts-ignore: expects correct type from url params
      productCategory={productCategory}
      typeformLink={typeformLink}
    />
  );
};

export default EdutainmentFormContainer;
