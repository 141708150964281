import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import missedBeer from '@components/web/src/assets/legacy/b2c/png/beer_missing_large.png';
import missedCoffee from '@components/web/src/assets/legacy/b2c/png/coffee_missing_large.png';
import missedWine from '@components/web/src/assets/legacy/b2c/png/wine_missing_large.png';
import Button from '@components/web/src/atoms/Buttons/Button';

interface IProps {
  productCategory: TProductCategory;
  handleButtonClick: () => void;
}

const MissedCharacterCard: FC<IProps> = ({ productCategory, handleButtonClick }) => {
  const { productCategories } = localeCommon;
  const { characterCardMissedLarge } = localeApp;

  const imagesSet = {
    [PRODUCT_CATEGORY_BEER]: missedBeer,
    [PRODUCT_CATEGORY_COFFEE]: missedCoffee,
    [PRODUCT_CATEGORY_WINE]: missedWine,
  };

  return (
    <div className="missed-character-card-container">
      <div className={`character-image-wrapper ${productCategory}`}>
        <img alt={`${productCategory} character`} src={imagesSet[productCategory]} />
      </div>
      <div className="content">
        <p className="title">
          <LocaleFragment message={characterCardMissedLarge.titleText} />
        </p>
        <p className="caption">
          <LocaleFragment
            index={{ productCategory }}
            message={characterCardMissedLarge.subtitleText}
            variables={{ productCategoryText: productCategories[productCategory] }}
          />
        </p>
        <p className="description">
          <LocaleFragment message={characterCardMissedLarge.descriptionText} />
        </p>
        <Button size="sm" text={characterCardMissedLarge.buttonText} onClick={handleButtonClick} />
      </div>
    </div>
  );
};

export default MissedCharacterCard;
