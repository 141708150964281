import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { actionSetNewPassword } from '@lib/core/users/slices/auth';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import CompleteResetPasswordPage from '@components/web/src/templates/Login&Registration/CompleteResetPasswordPage/CompleteResetPasswordPage';
import SetNewPasswordPage from '@components/web/src/templates/Login&Registration/SetNewPasswordPage/SetNewPasswordPage';

const SetPasswordContainer = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [isNewPasswordResetSuccess, setIsNewPasswordResetSuccess] = useState(false);

  const handleGoBack = () => {
    navigate(prependBasename(PAGES.vinhood.signIn));
  };

  const onSubmit = values => {
    const { uid, token } = params;
    dispatch(
      actionSetNewPassword({
        password: values.password,
        token,
        uid,
      }),
    ).then(() => {
      setIsNewPasswordResetSuccess(true);
    });
  };

  return isNewPasswordResetSuccess ? (
    <CompleteResetPasswordPage handleGoBack={handleGoBack} />
  ) : (
    <SetNewPasswordPage onSubmitHandler={onSubmit} />
  );
};

export default SetPasswordContainer;
