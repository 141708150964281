import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const HowToTasteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 312px;
  padding: 16px;
  border-radius: 8px;
  background: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.lg};
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
