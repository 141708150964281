import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  BANNER_ICONS,
  BANNER_ID_REDIRECT_EDUTAINMENT,
  BANNER_POSITION_CATALOG,
  BANNER_POSITION_EDUTAINMENT,
  BANNER_POSITION_EMAIL_REGISTRATION,
  BANNER_POSITION_HOME,
  BANNER_POSITION_MULTI_PRODUCT_RESULT,
  BANNER_POSITION_PROFILE_TASTE_PAGE,
  BANNER_POSITION_RECIPE_PAGE,
  BANNER_POSITION_TIP_AND_TRICKS,
  BANNER_STATIC_MULTI_PRODUCT_RESULT,
} from '@lib/core/banners/consts';
import { fetchBannersLists } from '@lib/core/banners/slices/bannersSlice';
import { IBannerDirectory, IBannerId, IStaticBannerDirectory } from '@lib/core/banners/types';
import { DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useProductPreferences } from '@lib/core/service/hooks/useProductPreferences';
import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import UserUtils from '@lib/core/users/utils/users';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { REDIRECT_TO_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { getUniqueItemForToday } from '@lib/tools/shared/helpers/utils';
import { useEffectSkipFirst, useRetailerDesignSet, useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';

type Props =
  | {
      bannerPosition?: IBannerDirectory;
      randomNumberOfBanners?: number;
      returnAllBanners?: boolean;
      bannerId?: IBannerId;
    }
  | {
      bannerPosition?: IStaticBannerDirectory;
      randomNumberOfBanners?: number;
      returnAllBanners?: boolean;
      bannerId?: IBannerId;
    };

// static for home
export const takeToProfileBanner = (completeProfileText: string): TBannerCard => ({
  currentPosition: {
    identifier: 'R00009',
    route: BANNER_POSITION_CATALOG,
  },
  iconType: BANNER_ICONS['finger-print'],
  identifier: 'static00',
  link: {
    identifier: 'RT002',
    route: 'profile/settings',
  },
  text: completeProfileText,
  theme: 'light',
});

// static for home
const takeToTasteBanner = (completeTasteTestText: string): TBannerCard => ({
  currentPosition: {
    identifier: 'R00003',
    route: BANNER_POSITION_HOME,
  },
  iconType: BANNER_ICONS.compass,
  identifier: 'static002',
  link: {
    identifier: 'RT001',
    route: 'quiz/choose/taste',
  },
  text: `**${completeTasteTestText}**`,
  theme: 'dark',
});

// static for recipe
export const recipePageStaticBanner = (recipeBannerText: string): TBannerCard => ({
  currentPosition: {
    identifier: 'R00010',
    route: BANNER_POSITION_RECIPE_PAGE,
  },
  iconType: BANNER_ICONS.compass,
  identifier: 'static003',
  link: {
    identifier: 'RT003',
    route: 'quiz/recipe',
  },
  link_params: `${DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM}=true&${REDIRECT_TO_URL_PARAM}=${PAGES.vinhood.catalog}`,
  text: `**${recipeBannerText}**`,
  theme: 'light',
});

// static for MultiProductResult
export const multiProductResultPageStaticBanner = (multiProductResultBannerText): TBannerCard => ({
  bgColor: 'teal',
  currentPosition: {
    identifier: BANNER_STATIC_MULTI_PRODUCT_RESULT.positionIdentifier,
    route: BANNER_POSITION_MULTI_PRODUCT_RESULT,
  },
  iconType: BANNER_ICONS['finger-print'],
  identifier: BANNER_STATIC_MULTI_PRODUCT_RESULT.identifier,
  link: {
    identifier: BANNER_STATIC_MULTI_PRODUCT_RESULT.linkIndentifier,
    route: BANNER_POSITION_EMAIL_REGISTRATION,
  },
  text: multiProductResultBannerText,
  theme: 'dark',
});

// static for profileTastePage
export const profilePageTasteTabStaticBanner = (
  profilePageTasteTabBannerText: string,
  userHasProductsFoodPreferences,
): TBannerCard => ({
  currentPosition: {
    identifier: 'R00006',
    route: BANNER_POSITION_PROFILE_TASTE_PAGE,
  },
  iconType: BANNER_ICONS.compass,
  identifier: 'static005',
  link: {
    identifier: 'RT003',
    route: userHasProductsFoodPreferences ? 'quiz/multi-product' : 'quiz/choose/taste',
  },
  text: `**${profilePageTasteTabBannerText}**`,
  theme: 'light',
});

export const catalogDownloadAppBanner: TBannerCard = {
  identifier: 'NL0057',
  link: {
    identifier: 'R00019',
    route: 'hint',
  },
  positions: [
    {
      identifier: 'R00014',
      route: 'catalog',
    },
  ],
  text: 'Download app',
};

export const useBanners = ({
  bannerPosition,
  randomNumberOfBanners = 1,
  returnAllBanners = true,
  bannerId,
}: Props): TBannerCard[] => {
  const dispatch = useDispatch();

  const { locale } = useApp();
  const { userDataRaw: userData } = useUser();

  const { isProfileCompleted } = UserUtils.isUserInformationComplete({
    excludeProductPreferencesValue: true,
    userData,
  });
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const { userHasProductsFoodPreferences } = useProductPreferences();

  const bannersRoot = useTypedSelector(store => store.b2cBanners);
  const {
    bannersFetchSuccess,
    [BANNER_POSITION_TIP_AND_TRICKS]: tipAndTricks = [],
    [BANNER_POSITION_EDUTAINMENT]: edutainmentBanners = [],
  } = bannersRoot;

  const { useB2cBannersText } = localeApp;

  useEffect(() => {
    if (bannerPosition !== BANNER_POSITION_MULTI_PRODUCT_RESULT && !bannersFetchSuccess && isDesignSetVinhoodApp) {
      dispatch(fetchBannersLists());
    }
  }, [locale]);

  useEffectSkipFirst(() => {
    dispatch(fetchBannersLists());
  }, [locale]);

  const { publishedTerms } = LocaleUtils;

  let completeProfileText = '';
  let completeTasteTestText = '';
  let recipeBannerText = '';
  let multiProductResultBannerText = '';
  let profilePageTasteTabBannerText = '';

  completeProfileText = publishedTerms[useB2cBannersText?.completeProfileText?.id];
  completeTasteTestText = publishedTerms[useB2cBannersText?.completeTasteTestText?.id];

  const generateHomePageBanners =
    useMemo(() => {
      const [randomBannerOne, randomBannerTwo] = getMultipleUniqueRandomItemsFromArray(
        bannersRoot[BANNER_POSITION_HOME],
        2,
      );

      const finalHomePageBanners = UserUtils.isUserHasCharactersForEachRetailerLocationCategories()
        ? [
            isProfileCompleted ? randomBannerOne : takeToProfileBanner(completeProfileText),
            randomBannerTwo || randomBannerOne,
          ]
        : [
            takeToTasteBanner(completeTasteTestText),
            isProfileCompleted ? randomBannerOne : takeToProfileBanner(completeProfileText),
          ];
      return finalHomePageBanners.filter(banner => banner);
    }, [
      bannersRoot,
      isProfileCompleted,
      UserUtils.isUserHasCharactersForEachRetailerLocationCategories(),
      completeProfileText,
      completeTasteTestText,
    ]) || [];

  const generateRandomBanners = useMemo(() => {
    if (returnAllBanners) {
      return bannersRoot[bannerPosition];
    }

    return getMultipleUniqueRandomItemsFromArray(bannersRoot[bannerPosition], randomNumberOfBanners);
  }, [bannersRoot, randomNumberOfBanners, bannerPosition]);

  const tipAndTricksBanners = useMemo(() => {
    if (tipAndTricks.length) {
      if (returnAllBanners) {
        return tipAndTricks;
      }
      if (randomNumberOfBanners === 1) {
        return getUniqueItemForToday({ itemArray: tipAndTricks });
      }
    }
    return [];
  }, [tipAndTricks]);

  const redirectToEdutainmentBanner = useMemo(() => {
    const homeBanners = bannersRoot[BANNER_POSITION_HOME] || [];
    return homeBanners.filter(banner => banner.identifier === BANNER_ID_REDIRECT_EDUTAINMENT);
  }, [bannersRoot]);

  if (bannerId) {
    switch (bannerId) {
      case BANNER_ID_REDIRECT_EDUTAINMENT: {
        return redirectToEdutainmentBanner;
      }

      default:
        return [];
    }
  }

  switch (bannerPosition) {
    case BANNER_POSITION_HOME: {
      return generateHomePageBanners;
    }

    case BANNER_POSITION_MULTI_PRODUCT_RESULT: {
      multiProductResultBannerText = publishedTerms[useB2cBannersText?.multiProductResultBannerText?.id];
      return [multiProductResultPageStaticBanner(multiProductResultBannerText)];
    }

    case BANNER_POSITION_RECIPE_PAGE: {
      recipeBannerText = publishedTerms[useB2cBannersText?.recipeBannerText?.id];
      return [recipePageStaticBanner(recipeBannerText)];
    }

    case BANNER_POSITION_PROFILE_TASTE_PAGE: {
      profilePageTasteTabBannerText = publishedTerms[useB2cBannersText?.profilePageTasteTabBannerText?.id];
      return [profilePageTasteTabStaticBanner(profilePageTasteTabBannerText, userHasProductsFoodPreferences)];
    }

    case BANNER_POSITION_TIP_AND_TRICKS: {
      return tipAndTricksBanners;
    }

    case BANNER_POSITION_EDUTAINMENT: {
      return edutainmentBanners;
    }
    case BANNER_POSITION_CATALOG: {
      return bannersRoot.catalog;
    }
    default:
      return generateRandomBanners || [];
  }
};
