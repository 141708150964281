import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TProductInstance } from '@lib/core/products/types';
import { parseProductInstance } from '@lib/core/products/utils';
import { HARDCODED_FEEDBACK_DATA } from '@lib/core/service/consts';
import { TFeedback } from '@lib/core/users/slices/feedbacks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { fetchDiscoveryQuizProducts, setDiscoveryQuizProductInstance } from '@lib/tools/discoveryQuiz/slices';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import FeedbackListItem from '@components/web/src/atoms/Feedback/FeedbackListItem/FeedbackListItem';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/FeedbackModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  feedback: TFeedback;
  productInstanceData: TProductInstance;
  hideModal: () => void;
  handleOpenDiscoveryQuizModal: () => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string, productName?: string) => void;
}

const FeedbackModal: FC<IProps> = ({
  isModalOpen,
  feedback,
  productInstanceData,
  hideModal,
  handleOpenDiscoveryQuizModal,
  handleUpdateFeedback,
}) => {
  const dispatch = useDispatch();

  const { ratingModal } = localeCommon;
  const { titleText, descriptionText } = ratingModal;

  const { productName, productId, productProducerName } = parseProductInstance({ productInstanceData });

  const [activeFeedback, setActiveFeedback] = useState(feedback);

  const orderedFeedbacks = Object.values(HARDCODED_FEEDBACK_DATA).sort((a, b) => b.value - a.value);

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="feedback-modal-wrapper">
      <S.FeedbackModalContainer data-testid="FeedbackModalContainer">
        <CloseButton handleClick={hideModal} />
        <S.FeedbackContentWrapper direction="column" gap={16}>
          <S.StyledTitleWrapper direction="column" gap={0}>
            {productName && <Text fontFamily="Fraunces" size="h6" text={productName} weight="semibold" />}
            {productProducerName && <Text size="body2" text={productProducerName} weight="semibold" />}
          </S.StyledTitleWrapper>
          <Flexbox direction="column" gap={16}>
            <Flexbox direction="column" gap={0}>
              <Text color={STATIC_COLORS.green[600]} size="body1" text={titleText} weight="bold" />
              <Text size="body3" text={descriptionText} weight="medium" />
            </Flexbox>
            <Flexbox direction="column" gap={8}>
              {orderedFeedbacks.map((data, index) => (
                <FeedbackListItem
                  key={index}
                  feedback={data.value}
                  isActive={data.value === activeFeedback}
                  onHandleClick={async () => {
                    setActiveFeedback(data.value);
                    await handleUpdateFeedback(data.value, productId, productName);

                    if (!feedback) {
                      dispatch(fetchDiscoveryQuizProducts(productInstanceData));
                      dispatch(setDiscoveryQuizProductInstance(productInstanceData));
                      handleOpenDiscoveryQuizModal();
                    }

                    MixpanelTracker.events.productFeedback(productInstanceData, data.value);

                    hideModal();
                  }}
                />
              ))}
            </Flexbox>
          </Flexbox>
        </S.FeedbackContentWrapper>
      </S.FeedbackModalContainer>
    </ModalWrapper>
  );
};

export default FeedbackModal;
