import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';

import { FooterKiosk } from '@components/web/src/atoms/Footers';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterKiosk/styles';
import { HeaderKiosk } from '@components/web/src/atoms/Headers/HeaderKiosk/HeaderKiosk';

interface IProps {
  children: ReactNode;
  isHomePage: boolean;
  isShowDetachFidelityCard: boolean;
  isShowLanguageSwitcher: boolean;
  retailerLanguages: TLanguage[];
  locale: string;
  handleLogoClick: () => void;
  handleDetachButtonClick: () => void;
}

const HeaderFooterKiosk: FC<IProps> = ({
  children,
  isHomePage,
  isShowDetachFidelityCard,
  isShowLanguageSwitcher,
  retailerLanguages,
  locale,
  handleLogoClick,
  handleDetachButtonClick,
}) => (
  <S.HeaderFooterKioskContainer data-testid="HeaderFooterKiosk" id="root-scroll-element">
    <HeaderKiosk
      handleDetachButtonClick={handleDetachButtonClick}
      handleLogoClick={handleLogoClick}
      isShowDetachFidelityCard={isShowDetachFidelityCard}
      isShowLanguageSwitcher={isShowLanguageSwitcher}
      locale={locale}
      retailerLanguages={retailerLanguages}
    />
    <S.BodyContainer align="stretch" data-testid="BodyContainer" direction="column">
      {children}
    </S.BodyContainer>
    <FooterKiosk isWaveVariant={isHomePage} />
  </S.HeaderFooterKioskContainer>
);

export default HeaderFooterKiosk;
