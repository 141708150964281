import beerAlcohol1 from '@components/web/src/assets/icons/essence/beer_essence_alcohol_content_1.png';
import beerAlcohol2 from '@components/web/src/assets/icons/essence/beer_essence_alcohol_content_2.png';
import beerAlcohol3 from '@components/web/src/assets/icons/essence/beer_essence_alcohol_content_3.png';
import beerAlcohol4 from '@components/web/src/assets/icons/essence/beer_essence_alcohol_content_4.png';
import beerAlcohol5 from '@components/web/src/assets/icons/essence/beer_essence_alcohol_content_5.png';
import beerBitterness1 from '@components/web/src/assets/icons/essence/beer_essence_bitterness_1.png';
import beerBitterness2 from '@components/web/src/assets/icons/essence/beer_essence_bitterness_2.png';
import beerBitterness3 from '@components/web/src/assets/icons/essence/beer_essence_bitterness_3.png';
import beerBitterness4 from '@components/web/src/assets/icons/essence/beer_essence_bitterness_4.png';
import beerBitterness5 from '@components/web/src/assets/icons/essence/beer_essence_bitterness_5.png';
import beerComplexity1 from '@components/web/src/assets/icons/essence/beer_essence_complexity_1.png';
import beerComplexity2 from '@components/web/src/assets/icons/essence/beer_essence_complexity_2.png';
import beerComplexity3 from '@components/web/src/assets/icons/essence/beer_essence_complexity_3.png';
import beerComplexity4 from '@components/web/src/assets/icons/essence/beer_essence_complexity_4.png';
import beerComplexity5 from '@components/web/src/assets/icons/essence/beer_essence_complexity_5.png';
import beerCreaminess1 from '@components/web/src/assets/icons/essence/beer_essence_creaminess_1.png';
import beerCreaminess2 from '@components/web/src/assets/icons/essence/beer_essence_creaminess_2.png';
import beerCreaminess3 from '@components/web/src/assets/icons/essence/beer_essence_creaminess_3.png';
import beerCreaminess4 from '@components/web/src/assets/icons/essence/beer_essence_creaminess_4.png';
import beerCreaminess5 from '@components/web/src/assets/icons/essence/beer_essence_creaminess_5.png';
import coffeeCreaminess1 from '@components/web/src/assets/icons/essence/coffee_essence_creaminess_1.png';
import coffeeCreaminess2 from '@components/web/src/assets/icons/essence/coffee_essence_creaminess_2.png';
import coffeeCreaminess3 from '@components/web/src/assets/icons/essence/coffee_essence_creaminess_3.png';
import coffeeCreaminess4 from '@components/web/src/assets/icons/essence/coffee_essence_creaminess_4.png';
import coffeeCreaminess5 from '@components/web/src/assets/icons/essence/coffee_essence_creaminess_5.png';
import coffeeIntensity1 from '@components/web/src/assets/icons/essence/coffee_essence_intensity_1.png';
import coffeeIntensity2 from '@components/web/src/assets/icons/essence/coffee_essence_intensity_2.png';
import coffeeIntensity3 from '@components/web/src/assets/icons/essence/coffee_essence_intensity_3.png';
import coffeeIntensity4 from '@components/web/src/assets/icons/essence/coffee_essence_intensity_4.png';
import coffeeIntensity5 from '@components/web/src/assets/icons/essence/coffee_essence_intensity_5.png';
import coffeeSweetness1 from '@components/web/src/assets/icons/essence/coffee_essence_sweetness_1.png';
import coffeeSweetness2 from '@components/web/src/assets/icons/essence/coffee_essence_sweetness_2.png';
import coffeeSweetness3 from '@components/web/src/assets/icons/essence/coffee_essence_sweetness_3.png';
import coffeeSweetness4 from '@components/web/src/assets/icons/essence/coffee_essence_sweetness_4.png';
import coffeeSweetness5 from '@components/web/src/assets/icons/essence/coffee_essence_sweetness_5.png';
import wineAcidity1 from '@components/web/src/assets/icons/essence/wine_essence_acidity_1_5.png';
import wineAcidity2 from '@components/web/src/assets/icons/essence/wine_essence_acidity_2_5.png';
import wineAcidity3 from '@components/web/src/assets/icons/essence/wine_essence_acidity_3_5.png';
import wineAcidity4 from '@components/web/src/assets/icons/essence/wine_essence_acidity_4_5.png';
import wineAcidity5 from '@components/web/src/assets/icons/essence/wine_essence_acidity_5_5.png';
import wineRoundness1 from '@components/web/src/assets/icons/essence/wine_essence_roundness_1_5.png';
import wineRoundness2 from '@components/web/src/assets/icons/essence/wine_essence_roundness_2_5.png';
import wineRoundness3 from '@components/web/src/assets/icons/essence/wine_essence_roundness_3_5.png';
import wineRoundness4 from '@components/web/src/assets/icons/essence/wine_essence_roundness_4_5.png';
import wineRoundness5 from '@components/web/src/assets/icons/essence/wine_essence_roundness_5_5.png';
import wineStructure1 from '@components/web/src/assets/icons/essence/wine_essence_structure_1_5.png';
import wineStructure2 from '@components/web/src/assets/icons/essence/wine_essence_structure_2_5.png';
import wineStructure3 from '@components/web/src/assets/icons/essence/wine_essence_structure_3_5.png';
import wineStructure4 from '@components/web/src/assets/icons/essence/wine_essence_structure_4_5.png';
import wineStructure5 from '@components/web/src/assets/icons/essence/wine_essence_structure_5_5.png';

export const BEER_CREAMINESS_ESSENCE_ID = 'BE001';
export const BEER_COMPLEXITY_ESSENCE_ID = 'BE002';
export const BEER_BITTERNESS_ESSENCE_ID = 'BE003';
export const BEER_ALCOHOL_ESSENCE_ID = 'BE004';
export const COFFEE_INTENSITY_ESSENCE_ID = 'CE001';
export const COFFEE_CREAMINESS_ESSENCE_ID = 'CE002';
export const COFFEE_SWEETNESS_ESSENCE_ID = 'CE003';
export const WINE_ROUNDNESS_ESSENCE_ID = 'WE010';
export const WINE_STRUCTURE_ESSENCE_ID = 'WE011';
export const WINE_ACIDITY_ESSENCE_ID = 'WE012';

export const HARDCODED_ESSENCE_DATA = {
  [BEER_ALCOHOL_ESSENCE_ID]: {
    icon: {
      0: beerAlcohol1,
      1: beerAlcohol1,
      2: beerAlcohol2,
      3: beerAlcohol3,
      4: beerAlcohol4,
      5: beerAlcohol5,
    },
    slug: {
      en: 'alcohol_content_en',
      it: 'alcohol_content_it',
    },
  },
  [BEER_BITTERNESS_ESSENCE_ID]: {
    icon: {
      0: beerBitterness1,
      1: beerBitterness1,
      2: beerBitterness2,
      3: beerBitterness3,
      4: beerBitterness4,
      5: beerBitterness5,
    },
    slug: {
      en: 'bitterness_en',
      it: 'bitterness_it',
    },
  },
  [BEER_COMPLEXITY_ESSENCE_ID]: {
    icon: {
      0: beerComplexity1,
      1: beerComplexity1,
      2: beerComplexity2,
      3: beerComplexity3,
      4: beerComplexity4,
      5: beerComplexity5,
    },
    slug: {
      en: 'complexity_en',
      it: 'complexity_it',
    },
  },
  [BEER_CREAMINESS_ESSENCE_ID]: {
    icon: {
      1: beerCreaminess1,
      2: beerCreaminess2,
      3: beerCreaminess3,
      4: beerCreaminess4,
      5: beerCreaminess5,
    },
    slug: {
      en: 'creaminess_en',
      it: 'creaminess_it',
    },
  },
  [COFFEE_CREAMINESS_ESSENCE_ID]: {
    icon: {
      1: coffeeCreaminess1,
      2: coffeeCreaminess2,
      3: coffeeCreaminess3,
      4: coffeeCreaminess4,
      5: coffeeCreaminess5,
    },
    slug: {
      en: 'creaminess_en',
      it: 'creaminess_it',
    },
  },
  [COFFEE_INTENSITY_ESSENCE_ID]: {
    icon: {
      1: coffeeIntensity1,
      2: coffeeIntensity2,
      3: coffeeIntensity3,
      4: coffeeIntensity4,
      5: coffeeIntensity5,
    },
    slug: {
      en: 'intensity_en',
      it: 'intensity_it',
    },
  },
  [COFFEE_SWEETNESS_ESSENCE_ID]: {
    icon: {
      0: coffeeSweetness1,
      1: coffeeSweetness1,
      2: coffeeSweetness2,
      3: coffeeSweetness3,
      4: coffeeSweetness4,
      5: coffeeSweetness5,
    },
    slug: {
      en: 'sweetness_en',
      it: 'sweetness_it',
    },
  },
  [WINE_ACIDITY_ESSENCE_ID]: {
    icon: {
      0: wineAcidity1,
      1: wineAcidity1,
      2: wineAcidity2,
      3: wineAcidity3,
      4: wineAcidity4,
      5: wineAcidity5,
    },
    slug: {
      en: 'acidity_en',
      it: 'acidity_it',
    },
  },
  [WINE_ROUNDNESS_ESSENCE_ID]: {
    icon: {
      0: wineRoundness1,
      1: wineRoundness1,
      2: wineRoundness2,
      3: wineRoundness3,
      4: wineRoundness4,
      5: wineRoundness5,
    },
    slug: {
      en: 'roundness_en',
      it: 'roundness_it',
    },
  },
  [WINE_STRUCTURE_ESSENCE_ID]: {
    icon: {
      0: wineStructure1,
      1: wineStructure1,
      2: wineStructure2,
      3: wineStructure3,
      4: wineStructure4,
      5: wineStructure5,
    },
    slug: {
      en: 'structure_en',
      it: 'structure_it',
    },
  },
};
// Typeform ROOT
export const TYPEFORM_DOMAIN_ROOT = 'https://tastecharacter.typeform.com/to';

export const EXPERIENCES_MIN_PRICE_QUERY = 'price__gt';
export const EXPERIENCES_MAX_PRICE_QUERY = 'price__lt';
export const ACTIVE_PANEL_FILTER_URL_PARAM = 'activePanelFilter';

// Profile page tabs
export const B2C_PROFILE_PAGE_TABS = {
  legal: 'legal',
  settings: 'settings',
  tags: 'tags',
  taste: 'taste',
};

export const ARTICLE_ID_URL_PARAM = 'articleID';
