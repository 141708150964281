import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { PrivacyAndTermsListItem } from '@components/web/src/app/Modals/PrivacyAndTermsListItem';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  isModalOpen: boolean;
  hideModal: () => void;
  shouldOpenModalsUnderHeader?: boolean;
  handleShowBasicServiceTermsModal?: () => void;
};

const PrivacyPolicyModal: FC<Props> = ({
  shouldOpenModalsUnderHeader,
  isModalOpen,
  hideModal,
  handleShowBasicServiceTermsModal = () => {},
}) => {
  const { privacyPolicyModal } = localeApp;
  const {
    headingDescription,
    heading,
    // privacy important
    privacyImportantToUsHeading,
    privacyImportantToUsDesc1,
    privacyImportantToUsDesc2,
    privacyImportantToUsDesc3,
    // data controller
    dataControllerHeading,
    dataControllerDesc1,
    dataControllerDesc2,
    // data we process
    dataWeProcessHeading,
    dataWeProcessDesc1,
    dataWeProcessDesc2,
    dataWeProcessDesc3,
    dataWeProcessDesc4,
    dataWeProcessDesc5,
    // data we collect
    dataWeCollectHeading,
    dataWeCollectSub,
    dataWeCollectDesc1,
    cookiePolicyLinkText,
    dataWeDontProcessHeading,
    dataWeDontProcessDesc1,
    dataWeDontProcessDesc2,
  } = privacyPolicyModal;

  const { whatWeUseDataForHeading } = privacyPolicyModal;
  const { whatWeUseDataForSub } = privacyPolicyModal;
  const { whatWeUseDataForDesc1 } = privacyPolicyModal;
  const { whatWeUseDataForDesc2 } = privacyPolicyModal;
  const { whatWeUseDataForDesc3 } = privacyPolicyModal;
  const { whatWeUseDataForDesc4 } = privacyPolicyModal;
  const { whatWeUseDataForDesc4Continue } = privacyPolicyModal;

  const { decidePersonalDataHeading } = privacyPolicyModal;
  const { decidePersonalDataSub1 } = privacyPolicyModal;
  const { decidePersonalDataSub2 } = privacyPolicyModal;

  const { methodProcessDataHeading } = privacyPolicyModal;
  const { methodProcessDataSub1 } = privacyPolicyModal;
  const { methodProcessDataSub2 } = privacyPolicyModal;
  const { methodProcessDataSub3 } = privacyPolicyModal;

  const { dataSharingContextHeading } = privacyPolicyModal;
  const { dataSharingContextSub1 } = privacyPolicyModal;
  const { dataSharingContextSub2 } = privacyPolicyModal;
  const { dataSharingContextSub3 } = privacyPolicyModal;
  const { dataSharingContextSub4 } = privacyPolicyModal;

  const { retainYourDataHeading } = privacyPolicyModal;
  const { retainYourDataSub1 } = privacyPolicyModal;
  const { retainYourDataSub2 } = privacyPolicyModal;
  const { retainYourDataSub3 } = privacyPolicyModal;
  const { retainYourDataSub4 } = privacyPolicyModal;
  const { retainYourDataDesc1 } = privacyPolicyModal;
  const { retainYourDataDesc2 } = privacyPolicyModal;

  const { automatedDecisionHeading } = privacyPolicyModal;
  const { automatedDecisionSub1 } = privacyPolicyModal;
  const { automatedDecisionSub2 } = privacyPolicyModal;
  const { automatedDecisionSub3 } = privacyPolicyModal;
  const { automatedDecisionSub4 } = privacyPolicyModal;
  const { automatedDecisionSub5 } = privacyPolicyModal;
  const { automatedDecisionSub6 } = privacyPolicyModal;
  const { automatedDecisionSub7 } = privacyPolicyModal;

  const { nonEuDataTransferHeading } = privacyPolicyModal;
  const { nonEuDataTransferSub1 } = privacyPolicyModal;
  const { nonEuDataTransferSub2 } = privacyPolicyModal;
  const { nonEuDataTransferDesc1 } = privacyPolicyModal;
  const { nonEuDataTransferDesc2 } = privacyPolicyModal;

  const { yourRightsHeading } = privacyPolicyModal;
  const { yourRightsSub1 } = privacyPolicyModal;
  const { yourRightsSub2 } = privacyPolicyModal;
  const { yourRightsDesc1 } = privacyPolicyModal;
  const { yourRightsDesc2 } = privacyPolicyModal;
  const { yourRightsDesc3 } = privacyPolicyModal;
  const { yourRightsDesc4 } = privacyPolicyModal;
  const { yourRightsDesc5 } = privacyPolicyModal;
  const { yourRightsDesc6 } = privacyPolicyModal;
  const { yourRightsDesc7 } = privacyPolicyModal;
  const { yourRightsDesc8 } = privacyPolicyModal;

  const { exerciseRightsHeading } = privacyPolicyModal;
  const { exerciseRightsSub } = privacyPolicyModal;

  const { endDescription } = privacyPolicyModal;

  const privacyImportantToUsDescription = [
    privacyImportantToUsDesc1,
    privacyImportantToUsDesc2,
    privacyImportantToUsDesc3,
  ];

  const dataWeProcessPoints = [
    dataWeProcessDesc1,
    dataWeProcessDesc2,
    dataWeProcessDesc3,
    dataWeProcessDesc4,
    dataWeProcessDesc5,
  ];

  const dataWeDontProcessPoints = [dataWeDontProcessDesc1, dataWeDontProcessDesc2];

  const retainYourDataPoints = [retainYourDataDesc1, retainYourDataDesc2];

  const nonEuDataTransferPoints = [nonEuDataTransferDesc1, nonEuDataTransferDesc2];

  const yourRightsPoints = [
    yourRightsDesc1,
    yourRightsDesc2,
    yourRightsDesc3,
    yourRightsDesc4,
    yourRightsDesc5,
    yourRightsDesc6,
    yourRightsDesc7,
    yourRightsDesc8,
  ];

  return (
    <ModalWrapper
      fullWidthModal
      hideModal={hideModal}
      isOpen={isModalOpen}
      modalClassName={`${shouldOpenModalsUnderHeader ? 'under-header' : ''}`}
    >
      <div className="privacy-policy-modal-container">
        <div className="headingContainer">
          <div className="title">
            <LocaleFragment message={heading} />
          </div>
          <p className="description">
            <LocaleFragment message={headingDescription} />
          </p>
        </div>
        <section>
          <div className="heading">
            <LocaleFragment message={privacyImportantToUsHeading} />
          </div>
          <div className="description-container">
            {privacyImportantToUsDescription.map((description, index) => (
              <span key={`privacyImportantDesc-${index}`}>
                <LocaleFragment message={description} />
              </span>
            ))}
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={dataControllerHeading} />
          </div>
          <div className="description-container">
            <p className="mdDescription-underline">
              <LocaleFragment message={dataControllerDesc1} />
            </p>
            <span>
              <LocaleFragment message={dataControllerDesc2} />
            </span>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={dataWeProcessHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem
              className="dataWeProcess-container"
              descriptionPoints={dataWeProcessPoints}
              keyName="dataWeProcessPoints"
            />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={dataWeCollectHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={dataWeCollectSub} />{' '}
              <span aria-hidden className="cookiePolicyLink-text" onClick={handleShowBasicServiceTermsModal}>
                <LocaleFragment message={cookiePolicyLinkText} />
              </span>
            </span>

            <PrivacyAndTermsListItem descriptionPoints={[dataWeCollectDesc1]} keyName="dataWeProcessPoints" />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={dataWeDontProcessHeading} />
          </div>
          <div className="description-container">
            <PrivacyAndTermsListItem descriptionPoints={dataWeDontProcessPoints} keyName="dataWeDontProcessPoints" />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={whatWeUseDataForHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={whatWeUseDataForSub} />
            </span>
            <ul>
              <li>
                <LocaleFragment message={whatWeUseDataForDesc1} />
              </li>
              <li>
                <LocaleFragment message={whatWeUseDataForDesc2} />
              </li>
              <li>
                <LocaleFragment message={whatWeUseDataForDesc3} />
              </li>
              <li>
                <LocaleFragment message={whatWeUseDataForDesc4} /> (
                <span
                  aria-hidden
                  className="cookiePolicyLink-text text-capitalize"
                  onClick={handleShowBasicServiceTermsModal}
                >
                  <LocaleFragment message={cookiePolicyLinkText} />
                </span>
                ) <LocaleFragment message={whatWeUseDataForDesc4Continue} />
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={decidePersonalDataHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={decidePersonalDataSub1} />
            </span>
            <p className="mdDescription-underline">
              <LocaleFragment message={decidePersonalDataSub2} />
            </p>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={methodProcessDataHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={methodProcessDataSub1} />
            </span>
            <span>
              <LocaleFragment message={methodProcessDataSub2} />
            </span>
            <span>
              <LocaleFragment message={methodProcessDataSub3} />
            </span>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={dataSharingContextHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={dataSharingContextSub1} />
            </span>
            <span>
              <LocaleFragment message={dataSharingContextSub2} />
            </span>
            <span>
              <LocaleFragment message={dataSharingContextSub3} />
            </span>
            <p className="mdDescription-underline-green">
              <LocaleFragment message={dataSharingContextSub4} />
            </p>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={retainYourDataHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={retainYourDataSub1} />
            </span>
            <PrivacyAndTermsListItem descriptionPoints={retainYourDataPoints} keyName="retainYourDataPoints" />
            <span>
              <LocaleFragment message={retainYourDataSub2} />
            </span>
            <span>
              <LocaleFragment message={retainYourDataSub3} />
            </span>
            <span>
              <LocaleFragment message={retainYourDataSub4} />
            </span>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={automatedDecisionHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={automatedDecisionSub1} />
            </span>
            <span>
              <LocaleFragment message={automatedDecisionSub2} />
            </span>
            <span>
              <LocaleFragment message={automatedDecisionSub3} />
            </span>
            <span>
              <LocaleFragment message={automatedDecisionSub4} />
            </span>
            <span>
              <LocaleFragment message={automatedDecisionSub5} />
            </span>
            <span>
              <LocaleFragment message={automatedDecisionSub6} />
            </span>
            <span>
              <LocaleFragment message={automatedDecisionSub7} />
            </span>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={nonEuDataTransferHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={nonEuDataTransferSub1} />
            </span>
            <span>
              <LocaleFragment message={nonEuDataTransferSub2} />
            </span>
            <PrivacyAndTermsListItem descriptionPoints={nonEuDataTransferPoints} keyName="nonEuDataTransferPoints" />
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={yourRightsHeading} />
          </div>
          <div className="description-container">
            <span>
              <LocaleFragment message={yourRightsSub1} />
            </span>
            <PrivacyAndTermsListItem descriptionPoints={yourRightsPoints} keyName="yourRightsPoints" />
            <p className="mdDescription-underline-green">
              <LocaleFragment message={yourRightsSub2} />
            </p>
          </div>
        </section>
        <section>
          <div className="heading">
            <LocaleFragment message={exerciseRightsHeading} />
          </div>
          <div className="description-container">
            <p className="mdDescription-underline-green">
              <LocaleFragment message={exerciseRightsSub} />
            </p>
          </div>
        </section>
        <div className="privacy-divider" />
        <section>
          <div className="description-container">
            <span>
              <LocaleFragment message={endDescription} />
            </span>
          </div>
        </section>
      </div>
    </ModalWrapper>
  );
};

export default PrivacyPolicyModal;
