import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

export interface ISectionHeaderProps {
  sectionTitle: ILocaleText;
  titleLocaleOptions?: Record<string, string>;
  descriptionLocaleOptions?: Record<string, string>;
  sectionDescription: ILocaleText;
}

const SectionHeader: FC<ISectionHeaderProps> = ({
  sectionTitle,
  sectionDescription,
  titleLocaleOptions,
  descriptionLocaleOptions,
}) => {
  return (
    <div className="section-header-container">
      <p className="title">
        <LocaleFragment message={sectionTitle} options={titleLocaleOptions} />
      </p>
      <p className="subtitle">
        <LocaleFragment message={sectionDescription} options={descriptionLocaleOptions} />
      </p>
    </div>
  );
};

export default SectionHeader;
