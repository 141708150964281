import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRetailer } from '@lib/core/retailers/hooks';
import { B2C_MODALS } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setServiceLocale } from '@lib/core/service/slices';
import {
  actionToggleUserProductPreferencesValue,
  actionUpdateUserProductPreferences,
} from '@lib/core/service/slices/productPreferencesSlice';
import { useUser } from '@lib/core/users/hooks';
import { actionUpdateProfileData } from '@lib/core/users/slices/profile';
import { actionGetUserData, actionUpdateUserData, actionUploadUserPicture } from '@lib/core/users/slices/user';
import { useModals } from '@lib/tools/modals/hooks';
import { resetProductSwiperState } from '@lib/tools/productsSwiper/slices/index';
import { EXPOSURE_PREFERENCES } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { ModalsHOC } from '@components/web/src/templates/Modals/ModalsHOC';
import SettingsPage from '@components/web/src/templates/TasteId/TasteIdSettingsPage/TasteIdSettingsPage';
import { IFormData } from '@components/web/src/templates/TasteId/TasteIdSettingsPage/UserInfoForm';

type TPreferenceSubmitValue = {
  key: string;
  isEnabled: boolean;
};

const TasteIdSettingsPageContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { openModal } = useModals();
  const { locale } = useApp();
  const { retailerLanguages } = useRetailer();
  const {
    handleResetUserData,
    isUploadingProfilePicture,
    userFirstName,
    userLastName,
    userEmail,
    userImage,
    profileCountry,
    profileGenderSlug,
    profileBirthYear,
    profileCountryOptions,
    profileGenderOptions,
  } = useUser();
  const foodPreferencesState = useTypedSelector(state => state.productPreferences.foodPreferencesData);

  const formData: IFormData = {
    birthYear: profileBirthYear?.toString() ?? '',
    country: profileCountry ?? '',
    email: userEmail ?? '',
    firstName: userFirstName ?? '',
    gender: profileGenderSlug ?? '',
    lastName: userLastName ?? '',
  };

  const [userImageUpdateError, setUserImageUpdateError] = useState('');

  const handleUserImageUpdate = (base64ImageData: string) =>
    dispatch(actionUploadUserPicture(base64ImageData)).then(() => {
      dispatch(actionGetUserData());
    });

  const handleFormSubmit = (data: IFormData) => {
    dispatch(
      actionUpdateProfileData({
        birth_year: data.birthYear && Number(data.birthYear),
        country: data.country,
        gender: data.gender,
      }),
    );

    dispatch(
      actionUpdateUserData({
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
      }),
    );
  };

  const handleLanguageChange = (language: string) => {
    dispatch(actionUpdateProfileData({ language })).then(() => {
      dispatch(setServiceLocale(language));
      dispatch(resetProductSwiperState());
    });
  };

  const handleUpdateUserFoodPreferences = (value: TPreferenceSubmitValue) => {
    const { isEnabled, key } = value;
    dispatch(actionToggleUserProductPreferencesValue({ exposure: EXPOSURE_PREFERENCES, slug: key }));
    dispatch(actionUpdateUserProductPreferences({ exposure: EXPOSURE_PREFERENCES, isEnabled, slug: key }));
  };

  const onToggleClick = (optionKey: string) => {
    const changedPreferences = {
      isEnabled: !foodPreferencesState[optionKey].isEnabled,
      key: optionKey,
    };
    handleUpdateUserFoodPreferences(changedPreferences);
  };

  const handleLogout = () => handleResetUserData();

  const handleBackBtnClick = () => navigate(-1);

  return (
    <ModalsHOC>
      <>
        <SettingsPage
          currentLanguage={locale}
          foodPreferencesState={foodPreferencesState}
          formData={formData}
          handleBackBtnClick={handleBackBtnClick}
          handleFormSubmit={handleFormSubmit}
          handleLanguageChange={handleLanguageChange}
          handleUserImageUpdate={handleUserImageUpdate}
          handleUserImageUpdateError={setUserImageUpdateError}
          profileCountryOptions={profileCountryOptions}
          profileGenderOptions={profileGenderOptions}
          supportedLanguages={retailerLanguages}
          userImage={userImage}
          userImageUpdateError={userImageUpdateError}
          onExitBtnClick={handleLogout}
          onManageBtnClick={() => openModal(B2C_MODALS.MANAGE_MODAL)}
          onPreferencesHintBtnClick={() => openModal(B2C_MODALS.FOOD_PREFERENCES_MODAL)}
          onToggleClick={onToggleClick}
        />

        {isUploadingProfilePicture && <Spinner />}
      </>
    </ModalsHOC>
  );
};

export default TasteIdSettingsPageContainer;
