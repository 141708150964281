/* eslint-disable sort-keys-fix/sort-keys-fix */
import classNames from 'classnames';
import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';

interface IProps {
  handleGoBack: () => void;
  isApplicationKiosk: boolean;
}

const PrivacyPolicyTab: FC<IProps> = ({ handleGoBack, isApplicationKiosk }) => {
  const { privacyPolicyPage } = localeWidget;

  const {
    mainTitle,
    mainDescription,
    // privacy
    privacyImportantTitle,
    privacyImportantParagraph1,
    privacyImportantParagraph2,
    privacyImportantParagraph3,
    privacyImportantParagraph4,
    privacyImportantParagraph5,
    privacyImportantParagraph6,
    privacyImportantParagraph7,
    privacyImportantItem1,
    privacyImportantItem2,
    privacyImportantItem3,
    privacyImportantItem4,
    privacyImportantItem5,
    privacyImportantItem6,
    // data controller
    dataControllerTitle,
    dataControllerParagraph1,
    dataControllerParagraph2,
    // data process
    dataProcessMainTitle,
    dataProcessTitle,
    dataProcessItem1,
    dataProcessItem2,
    dataProcessSubItem1,
    dataProcessSubItem2,
    dataProcessParagraph1,
    // data collection
    dataCollectTitle,
    dataCollectParagraph1,
    dataNotProcessTitle,
    dataNotProcessItem1,
    dataNotProcessItem2,
    dataUseForTitle,
    dataUseForParagraph1,
    dataUseForItem1,
    dataUseForItem2,
    dataUseForItem3,
    dataUseForItem4,
    dataProvideTitle,
    dataProvideParagraph1,
    dataProvideParagraph2,
    dataProcessingMethodTitle,
    dataProcessingMethodParagraph1,
    dataProcessingMethodParagraph2,
    dataProcessingMethodParagraph3,
    // data context
    dataContextShareTitle,
    dataContextShareParagraph1,
    dataContextShareParagraph2,
    dataContextShareParagraph3,
    dataContextShareParagraph4,
    dataProcessRetainTitle,
    dataProcessRetainParagraph1,
    dataProcessRetainParagraph2,
    dataProcessRetainParagraph3,
    dataProcessRetainParagraph4,
    dataProcessRetainItem1,
    dataProcessRetainItem2,
    // automated process
    automatedProcessTitle,
    automatedProcessParagraph1,
    automatedProcessParagraph2,
    automatedProcessParagraph3,
    automatedProcessParagraph4,
    automatedProcessParagraph5,
    // transfer data
    transferDataTitle,
    transferDataParagraph1,
    transferDataItem1,
    transferDataItem2,
    // rights
    yourRightsTitle,
    yourRightsParagraph1,
    yourRightsItem1,
    yourRightsItem2,
    yourRightsItem3,
    yourRightsItem4,
    yourRightsItem5,
    yourRightsItem6,
    yourRightsItem7,
    yourRightsParagraph2,
    exerciseRightsTitle,
    exerciseRightsParagraph1,
    privacyPolicyParagraph1,
    btnBack,
  } = privacyPolicyPage;

  return (
    <div className={classNames('privacy-policy-tab', { isApplicationKiosk })}>
      {isApplicationKiosk && <KioskCirclesBackground />}

      <p className="main-title">
        <LocaleFragment message={mainTitle} />
      </p>
      <p>
        <LocaleFragment message={mainDescription} />
      </p>
      <div className="section">
        <p className="section-title">
          <LocaleFragment message={privacyImportantTitle} />
        </p>
        <p>
          <LocaleFragment message={privacyImportantParagraph1} />
        </p>
        <p>
          <LocaleFragment message={privacyImportantParagraph2} />
        </p>
        <p>
          <LocaleFragment message={privacyImportantParagraph3} />
        </p>
        <p>
          <LocaleFragment message={privacyImportantParagraph4} />
        </p>
        <p>
          <LocaleFragment message={privacyImportantParagraph5} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={privacyImportantItem1} />
          </li>
          <li>
            <LocaleFragment message={privacyImportantItem2} />
          </li>
          <li>
            <LocaleFragment message={privacyImportantItem3} />
          </li>
          <li>
            <LocaleFragment message={privacyImportantItem4} />
          </li>
        </ul>
        <p>
          <LocaleFragment message={privacyImportantParagraph6} />
        </p>
        <p>
          <LocaleFragment message={privacyImportantParagraph7} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={privacyImportantItem5} />
          </li>
          <li>
            <LocaleFragment message={privacyImportantItem6} />
          </li>
        </ul>
      </div>
      <div className="section">
        <p className="section-title">
          <LocaleFragment message={dataControllerTitle} />
        </p>
        <p>
          <LocaleFragment message={dataControllerParagraph1} />
        </p>
        <p>
          <LocaleFragment message={dataControllerParagraph2} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataProcessMainTitle} />
        </p>
        <p className="section-title">
          <LocaleFragment message={dataProcessTitle} />
        </p>
        <ul>
          <li>
            <p>
              <LocaleFragment message={dataProcessItem1} />
            </p>
          </li>
          <li>
            <p>
              <LocaleFragment message={dataProcessItem2} />
            </p>
          </li>
          <ul>
            <li>
              <LocaleFragment message={dataProcessSubItem1} />
            </li>
            <li>
              <LocaleFragment message={dataProcessSubItem2} />
            </li>
          </ul>
        </ul>
        <p>
          <LocaleFragment message={dataProcessParagraph1} />
        </p>
      </div>
      <div className="section">
        <p className="section-title">
          <LocaleFragment message={dataCollectTitle} />
        </p>
        <p>
          <LocaleFragment message={dataCollectParagraph1} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataNotProcessTitle} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={dataNotProcessItem1} />
          </li>
          <li>
            <LocaleFragment message={dataNotProcessItem2} />
          </li>
        </ul>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataUseForTitle} />
        </p>
        <p>
          <LocaleFragment message={dataUseForParagraph1} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={dataUseForItem1} />
          </li>
          <li>
            <LocaleFragment message={dataUseForItem2} />
          </li>
          <li>
            <LocaleFragment message={dataUseForItem3} />
          </li>
          <li>
            <LocaleFragment message={dataUseForItem4} />
          </li>
        </ul>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataProvideTitle} />
        </p>
        <p>
          <LocaleFragment message={dataProvideParagraph1} />
        </p>
        <p>
          <LocaleFragment message={dataProvideParagraph2} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataProcessingMethodTitle} />
        </p>
        <p>
          <LocaleFragment message={dataProcessingMethodParagraph1} />
        </p>
        <p>
          <LocaleFragment message={dataProcessingMethodParagraph2} />
        </p>
        <p>
          <LocaleFragment message={dataProcessingMethodParagraph3} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataContextShareTitle} />
        </p>
        <p>
          <LocaleFragment message={dataContextShareParagraph1} />
        </p>
        <p>
          <LocaleFragment message={dataContextShareParagraph2} />
        </p>
        <p>
          <LocaleFragment message={dataContextShareParagraph3} />
        </p>
        <p>
          <LocaleFragment message={dataContextShareParagraph4} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={dataProcessRetainTitle} />
        </p>
        <p>
          <LocaleFragment message={dataProcessRetainParagraph1} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={dataProcessRetainItem1} />
          </li>
          <li>
            <LocaleFragment message={dataProcessRetainItem2} />
          </li>
        </ul>
        <p>
          <LocaleFragment message={dataProcessRetainParagraph2} />
        </p>
        <p>
          <LocaleFragment message={dataProcessRetainParagraph3} />
        </p>
        <p>
          <LocaleFragment message={dataProcessRetainParagraph4} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={automatedProcessTitle} />
        </p>
        <p>
          <LocaleFragment message={automatedProcessParagraph1} />
        </p>
        <p>
          <LocaleFragment message={automatedProcessParagraph2} />
        </p>
        <p>
          <LocaleFragment message={automatedProcessParagraph3} />
        </p>
        <p>
          <LocaleFragment message={automatedProcessParagraph4} />
        </p>
        <p>
          <LocaleFragment message={automatedProcessParagraph5} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={transferDataTitle} />
        </p>
        <p>
          <LocaleFragment message={transferDataParagraph1} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={transferDataItem1} />
          </li>
          <li>
            <LocaleFragment message={transferDataItem2} />
          </li>
        </ul>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={yourRightsTitle} />
        </p>
        <p>
          <LocaleFragment message={yourRightsParagraph1} />
        </p>
        <ul>
          <li>
            <LocaleFragment message={yourRightsItem1} />
          </li>
          <li>
            <LocaleFragment message={yourRightsItem2} />
          </li>
          <li>
            <LocaleFragment message={yourRightsItem3} />
          </li>
          <li>
            <LocaleFragment message={yourRightsItem4} />
          </li>
          <li>
            <LocaleFragment message={yourRightsItem5} />
          </li>
          <li>
            <LocaleFragment message={yourRightsItem6} />
          </li>
          <li>
            <LocaleFragment message={yourRightsItem7} />
          </li>
        </ul>
        <p>
          <LocaleFragment message={yourRightsParagraph2} />
        </p>
      </div>
      <div className="section">
        <p className="section-main-title">
          <LocaleFragment message={exerciseRightsTitle} />
        </p>
        <p>
          <LocaleFragment message={exerciseRightsParagraph1} />
        </p>
      </div>
      <div className="divider" />
      <p>
        <LocaleFragment message={privacyPolicyParagraph1} />
      </p>
      <BackButton className="previous-cta" handleClick={handleGoBack} size="sm" text={btnBack} />
    </div>
  );
};

export default PrivacyPolicyTab;
