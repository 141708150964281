import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LEGAL_TAB_SLUG_REPLACEMENT, isApplicationKiosk } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { PAGES } from '@lib/tools/views/urls';

import LegalPage from '@components/web/src/pages/LegalPage/LegalPage';

const LegalPageContainer: FC = () => {
  const navigate = useNavigate();
  const { legalTabSlug } = useParams();

  const onBackButtonClick = () => {
    navigate(-1);
  };

  const onTabClick = (tabCode: string) => {
    if (tabCode !== legalTabSlug) {
      navigate(prependBasename(PAGES.vinhood.legal.tabs.replace(LEGAL_TAB_SLUG_REPLACEMENT, tabCode)));
    }
  };

  return (
    <LegalPage
      defaultTab={legalTabSlug}
      handleGoBack={onBackButtonClick}
      isApplicationKiosk={isApplicationKiosk}
      onTabClick={onTabClick}
    />
  );
};

export default LegalPageContainer;
