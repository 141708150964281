import Button from '@components/web/src/atoms/Buttons/Button';
import * as S from '@components/web/src/atoms/Tooltips/styles';
import { Tooltip, TooltipContent, TooltipTrigger } from '@components/web/src/atoms/Tooltips/TooltipElement';
import { TooltipBodyProps, TooltipComponentProps } from '@components/web/src/atoms/Tooltips/types';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const TooltipBody = ({ variant = 'dark', title, description }: TooltipBodyProps) => {
  const titleTextColor = variant === 'light' ? STATIC_COLORS.gray['700'] : STATIC_COLORS.base.white;
  const descriptionTextColor = variant === 'light' ? STATIC_COLORS.gray['500'] : STATIC_COLORS.base.white;

  return (
    <S.Tooltip $isDescription={!!description} $variant={variant} data-testid={`Tooltip-${variant}`}>
      <Text color={titleTextColor} size="body2" text={title} weight="semibold" />
      {description && <Text color={descriptionTextColor} size="body2" text={description} />}
    </S.Tooltip>
  );
};

/**
 * Renders a Tooltip component with a trigger and content.
 *
 * @param {ReactNode} trigger - The trigger element that will display the tooltip when hovered or clicked.
 * By default Primary Button is used
 * @param {string} variant - The variant of the tooltip. Can be 'light', 'dark', or 'green'. Defaults to 'light'.
 * @param {ILocaleText} title - The title of the tooltip.
 * @param {ILocaleText} description - The description of the tooltip.
 * @param {boolean} isAlwaysOpen - Determines whether the tooltip should always be open. Defaults to false.
 * @param {Placement} placement - The placement of the tooltip. Defaults to 'top-start'.
 * @returns {ReactNode} - The rendered Tooltip component.
 */
const TooltipComponent = ({
  trigger,
  isVariantTasteId = false,
  variant = 'light',
  title,
  description,
  isAlwaysOpen = false,
  placement = 'top-start', // will auto-adjust on the available side
  triggerClassName,
  contentClassName,
  isShowOnHover = true,
}: TooltipComponentProps) => {
  const triggerComponent = trigger || <Button text="Tooltip Trigger" />;
  const isShowTooltip = !!title || !!description;

  const getColor = colorVariant => {
    switch (colorVariant) {
      case 'light':
        return STATIC_COLORS.base.white;
      case 'green':
        return STATIC_COLORS.green['600'];
      case 'dark':
      case 'gradient':
        return STATIC_GRADIENTS.primaryGradient;
      default:
        return STATIC_COLORS.gray['500'];
    }
  };

  return (
    <Tooltip
      isVariantTasteId={isVariantTasteId}
      placement={placement}
      {...(isAlwaysOpen ? { open: true } : {})}
      data-testid={`Tooltip-${variant}-${placement}-${isAlwaysOpen}`}
      isShowOnHover={isShowOnHover}
    >
      <TooltipTrigger className={triggerClassName}>{triggerComponent}</TooltipTrigger>
      {isShowTooltip && (
        <TooltipContent className={contentClassName} color={getColor(variant)}>
          <TooltipBody description={description} title={title} variant={variant} />
        </TooltipContent>
      )}
    </Tooltip>
  );
};

export default TooltipComponent;
