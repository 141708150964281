import { FC, ReactNode } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as BeerIcon } from '@components/web/src/assets/legacy/icons/tab_beer_icon.svg';
import { ReactComponent as CoffeeIcon } from '@components/web/src/assets/legacy/icons/tab_coffee_icon.svg';
import { ReactComponent as WineIcon } from '@components/web/src/assets/legacy/icons/tab_wine_icon.svg';
import * as S from '@components/web/src/atoms/Tabs/TabButton/styles';

export type TTabIcon = 'wine' | 'beer' | 'coffee';

export interface ITab {
  tabText: ILocaleText;
  isActive?: boolean;
  tabCode: string;
  children?: ReactNode;
  isHiddenTab?: boolean;
  tabIcon?: TTabIcon;
  onTabClick?(code: string): void;
}

const TabButton: FC<ITab> = ({ isActive, tabText, tabCode, tabIcon, onTabClick }) => {
  const currentIcon = {
    beer: <BeerIcon />,
    coffee: <CoffeeIcon />,
    wine: <WineIcon />,
  }[tabIcon];

  return (
    <S.StyledTabButton
      $isActive={isActive}
      $tabIcon={tabIcon}
      data-testid={`TabButton-${isActive}`}
      onClick={() => onTabClick(tabCode)}
    >
      {currentIcon && <S.Icon $isActive={isActive}>{currentIcon}</S.Icon>}

      <S.StyledText $isActive={isActive} fontFamily="Montserrat" size="body1" text={tabText} />
    </S.StyledTabButton>
  );
};

export default TabButton;
