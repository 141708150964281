import { useSelector } from 'react-redux';

import {
  selectCurrentTastePathCategoryId,
  selectIsTastePathProductsLoading,
  selectNextTastePathCategoryId,
  selectTastePathData,
} from '@lib/tools/tastePathProducts/selectors/index';

export const useTastePathProducts = () => ({
  currentTastePathCategoryId: useSelector(selectCurrentTastePathCategoryId),
  isTastePathProductsLoading: useSelector(selectIsTastePathProductsLoading),
  nextTastePathCategoryId: useSelector(selectNextTastePathCategoryId),
  tastePathData: useSelector(selectTastePathData),
});
