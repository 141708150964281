import { FC } from 'react';

import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BottomOverlay from '@components/web/src/app/OverlayBackground/BottomOverlay';

type IProps = {
  backgroundImage: string;
  titleText: string;
  descriptionText: string;
  btnText: string;
  handleCloseClick?: () => void;
};

const OnBoardingCard: FC<IProps> = ({ backgroundImage, titleText, descriptionText, btnText, handleCloseClick }) => {
  return (
    <div className="onboarding-card">
      <BottomOverlay backgroundImage={backgroundImage} />
      <div className="content">
        <p className="title">
          <LocaleFragment message={titleText} />
        </p>
        <p className="description">
          <LocaleFragment message={descriptionText} />
        </p>
        <button onClick={handleCloseClick}>
          <LocaleFragment message={btnText} />
        </button>
      </div>
    </div>
  );
};

export default OnBoardingCard;
