import styled from 'styled-components';

import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';

export const SignUpExtraInfoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CloseIcon = styled(CloseButton)`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
`;

export const AuthOptionsWrapper = styled.div`
  margin: 32px 16px;
`;
