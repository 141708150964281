import styled from 'styled-components';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const QuizSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 44px 16px;
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const Icon = styled.img`
  width: 158px;
  height: 148px;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const StyledText = styled(Text)`
  text-transform: lowercase;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  button {
    flex: 1;
  }
`;
