import styled, { css } from 'styled-components';

import Btn from '@components/web/src/atoms/Buttons/Button';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ExplorePageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary-100);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 16px;
  width: 100%;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  img {
    height: 67px;
    width: fit-content;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  & > img {
    height: 171px;
    width: fit-content;
  }
`;

export const LocationImage = styled.div<{ $image: string }>`
  width: 100%;
  height: 171px;
  border-radius: 5px;
  overflow: hidden;
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center;
`;

export const Contact = styled.div<{ $isAddressContact: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    height: auto;
  }

  span {
    ${({ $isAddressContact }) =>
      !$isAddressContact &&
      css`
        text-transform: lowercase;
        text-decoration: underline;
      `}
  }
`;

export const ProductSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  & > span {
    text-align: center;
  }
`;

export const ExperienceSection = styled.div<{ $isSingleCard: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
  padding: ${({ $isSingleCard }) => ($isSingleCard ? '0 16px' : '0 0 0 16px')};

  .swiper-wrapper {
    > *:last-child {
      margin-inline-end: 16px;
    }
  }

  .hidden-pagination-block {
    height: 40px;
  }
`;

export const Button = styled(Btn)`
  width: 100%;
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px 32px;
`;

export const ListSubtitle = styled(Text)`
  text-transform: capitalize;
`;
