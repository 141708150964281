import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';

import RecipeItem, { IB2CRecipe } from '@components/web/src/components/Recipe/RecipeItem/RecipeItem';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

type Props = {
  recipes: IB2CRecipe[];
  isRecipesLoading: boolean;
  productCategory: TProductCategory;
  isDesignSetVinhoodApp: boolean;
  isApplicationKiosk?: boolean;
};

const RecipeResult: FC<Props> = ({
  isApplicationKiosk,
  recipes,
  isRecipesLoading,
  productCategory,
  isDesignSetVinhoodApp,
}) => {
  return (
    <Flexbox
      isFullWidth
      className="recipe-search-result-container"
      data-testid="RecipeResult"
      direction="column"
      gap={8}
    >
      {isRecipesLoading
        ? Array.from({ length: isApplicationKiosk ? 14 : 5 }).map((_, idx) => (
            <RecipeItem
              key={idx.toString()}
              isLoading
              isApplicationKiosk={isApplicationKiosk}
              isDesignSetVinhoodApp={isDesignSetVinhoodApp}
              productCategory={productCategory}
            />
          ))
        : recipes?.map(recipe => (
            <RecipeItem
              key={recipe.identifier}
              data={recipe}
              isApplicationKiosk={isApplicationKiosk}
              isDesignSetVinhoodApp={isDesignSetVinhoodApp}
              productCategory={productCategory}
            />
          ))}
    </Flexbox>
  );
};

export default RecipeResult;
