import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as QuestionMarkIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_questionmark.svg';

interface IProps {
  title?: ILocaleText;
  subTitle: ILocaleText;
  onHintBtnClick?: () => void;
}

const ProfileHintHeader: FC<IProps> = ({ title, subTitle, onHintBtnClick }) => (
  <div className="profile-hint-header-container">
    <div className="text-container">
      {title && (
        <p className="title">
          <LocaleFragment message={title} />
        </p>
      )}
      {subTitle && (
        <p className="description">
          <LocaleFragment message={subTitle} />
        </p>
      )}
    </div>
    {onHintBtnClick && <QuestionMarkIcon className="cursor-pointer" onClick={onHintBtnClick} />}
  </div>
);

export default ProfileHintHeader;
