/* eslint-disable no-unreachable */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { IRequestParams, getProductData } from '@lib/core/products/utils';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { fetchProducts } from '@lib/core/service/slices/kioskRootSlice';
import { selectFidelityCardId } from '@lib/core/users/selectors/fidelityCard';
import { selectWishlistProductInstanceIds } from '@lib/core/users/selectors/productList';
import { selectUserCharacters } from '@lib/core/users/selectors/user';
import { isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { IProductTip, IUserProductTip } from '@lib/tools/productTip/types/interfaces';

import { KIOSK_PRODUCT_TIP_MAX_PRODUCT } from '@components/web/src/kiosk/consts';

const initialState: IProductTip = {
  currentProductTip: {
    currentProductTipData: null,
    isCurrentProductTipLoading: false,
    requestError: '',
  },
  usersProductTipsStorage: {},
};

const createProductTipForStorage = (state, productTipId): IUserProductTip => {
  const currentDate = moment().format('DD.MM.YYYY');
  const fidelityCardId = selectFidelityCardId(state);
  const userCharacters = selectUserCharacters(state);
  const currentUserCharacterID =
    userCharacters.find(character => isCharacterByServiceProductCategory(character)).identifier || '';
  return {
    [fidelityCardId]: {
      lastProductTipDate: currentDate,
      productTipCharacter: currentUserCharacterID,
      productTipId,
    },
  };
};

export const fetchAndCreateNewProductTip = createTypedAsyncThunk(
  'productTip/fetchAndCreateNewProductTip',
  async (requestParams: IRequestParams, thunkAPI) => {
    const { getState } = thunkAPI;
    const state = getState();
    const wishlistProductInstanceIds = selectWishlistProductInstanceIds(state);

    try {
      const products = await fetchProducts(requestParams, true, KIOSK_PRODUCT_TIP_MAX_PRODUCT);
      const productTipData = products[0];

      const productTipId = productTipData?.identifier || '';
      const isProductInstanceInWishlist = isProductInstanceInWishlistFilter(wishlistProductInstanceIds, productTipData);

      if (isProductInstanceInWishlist) {
        return fetchAndCreateNewProductTip();
      }

      const productTipForStorage = createProductTipForStorage(state, productTipId);

      return { productTipData, productTipForStorage };
    } catch (error: any) {
      throw new Error(error);
    }
  },
);

export const fetchProductTipData = createTypedAsyncThunk(
  'productTip/fetchProductTipData',
  async (productId: string, thunkAPI) => {
    const { getState } = thunkAPI;
    const state = getState();

    try {
      const productTipData = await getProductData({ productId });
      const productTipForStorage = createProductTipForStorage(state, productId);

      return { productTipData, productTipForStorage };
    } catch (error: any) {
      throw new Error(error);
    }
  },
);

const productTipSlice = createSlice({
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(fetchAndCreateNewProductTip.pending, fetchProductTipData.pending), state => {
        state.currentProductTip = { ...state.currentProductTip, isCurrentProductTipLoading: true };
      })
      .addMatcher(isAnyOf(fetchAndCreateNewProductTip.fulfilled, fetchProductTipData.fulfilled), (state, action) => {
        const { productTipData, productTipForStorage } = action.payload;

        state.currentProductTip = {
          ...state.currentProductTip,
          currentProductTipData: productTipData,
          isCurrentProductTipLoading: false,
        };

        state.usersProductTipsStorage = {
          ...state.usersProductTipsStorage,
          ...productTipForStorage,
        };
      })
      .addMatcher(isAnyOf(fetchAndCreateNewProductTip.rejected, fetchProductTipData.rejected), (state, action) => {
        const errorMessage = action.payload?.errorMessage || action.error?.message;

        state.currentProductTip = {
          ...initialState.currentProductTip,
          isCurrentProductTipLoading: false,
          requestError: errorMessage,
        };
      });
  },
  initialState,
  name: 'productTip',
  reducers: {
    resetCurrentProductTip(state) {
      state.currentProductTip = initialState.currentProductTip;
    },
  },
});

export const { resetCurrentProductTip } = productTipSlice.actions;
export default productTipSlice.reducer;
