import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as CrosssIcon } from '@components/web/src/assets/icons/cross_icon.svg';
import { ReactComponent as PlusIcon } from '@components/web/src/assets/icons/plus_icon.svg';
import * as S from '@components/web/src/atoms/Badge/styles';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export type TBadgeSize = 'sm' | 'md' | 'lg' | 'xl';
export type TBadgeVariant = 'primary' | 'secondary';

export type TStyledBadgeContainer = {
  $size: TBadgeSize;
  $variant: TBadgeVariant;
  $isProductCategoryType?: boolean;
  $productCategory?: TProductCategory;
};

interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text?: string;
  slug?: string;
  variant?: TBadgeVariant;
  icon?: string;
  size?: TBadgeSize;
  handleCloseClick?: (tagSlug: string) => void;
  isProductCategoryType?: boolean;
  productCategory?: TProductCategory;
  handleBadgeClick?: () => void;
}

const Badge: FC<IProps> = ({
  text = '',
  slug,
  variant = 'primary',
  icon = '',
  handleCloseClick,
  size = 'sm',
  isProductCategoryType = false,
  productCategory = '',
  handleBadgeClick,
  ...rest
}) => {
  const {
    commonMessages: { startToAdd },
  } = localeCommon;

  return (
    <S.BadgeContainer
      $isProductCategoryType={isProductCategoryType}
      $productCategory={productCategory}
      $size={size}
      $variant={variant}
      data-testid={`Badge-${size}-${variant}-${isProductCategoryType}-${productCategory}`}
      onClick={() => handleBadgeClick && handleBadgeClick()}
      {...rest}
    >
      {icon ? (
        <Image alt={text} height="20px" objectFit="contain" src={icon} width="20px" />
      ) : (
        <Text size="body2" text={isProductCategoryType ? startToAdd : text} weight="medium" />
      )}
      {!isProductCategoryType && handleCloseClick && (
        <S.Icon
          $isProductCategoryType={isProductCategoryType}
          $variant={variant}
          onClick={() => handleCloseClick && handleCloseClick(slug)}
        >
          <CrosssIcon />
        </S.Icon>
      )}
      {isProductCategoryType && (
        <S.Icon $isProductCategoryType={isProductCategoryType} $variant={variant}>
          <PlusIcon />
        </S.Icon>
      )}
    </S.BadgeContainer>
  );
};

export default Badge;
