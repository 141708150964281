import { TCharacter, TCharactersBySubCategory } from '@lib/core/characters/types';
import { IParsedCharacter } from '@lib/core/characters/types/views';
import { isCharacterByProductCategory } from '@lib/core/characters/utils/filters';
import { TProductCategory } from '@lib/core/products/types';

export const parseCharacterDetails = ({ character }): IParsedCharacter => {
  const {
    product_category: characterProductCategory,
    name,
    character_caption: characterCaption,
    related_essences: relatedCharacterEssences = [],
    character_description: characterDescription = '',
    image: characterImage,
    technical_caption: technicalCaption,
    technical_description: technicalDescription,
    identifier,
  } = character || {};
  const sortedRelatedCharacterEssences = [...relatedCharacterEssences]?.sort((a, b) => a.position - b.position) || [];

  return {
    characterCaption,
    characterDescription,
    characterId: identifier,
    characterImage,
    characterName: name,
    characterProductCategory,
    sortedRelatedCharacterEssences,
    technicalCaption,
    technicalDescription,
  };
};

/**
 * @returns object contains product category as first level key, which also contains JSONs
 * with sub-categories as second-level keys
 */
export const getCharactersBySubCategory = (
  productCategory: TProductCategory,
  characters: TCharacter[],
): TCharactersBySubCategory => {
  let charactersBySubCategory = {};

  const currentCategoryCharacters = characters.filter(character =>
    isCharacterByProductCategory(character, productCategory),
  );
  const charactersSubCategories = new Set(currentCategoryCharacters.map(item => item.character_type));
  charactersSubCategories.forEach(productSubCategory => {
    charactersBySubCategory = {
      ...charactersBySubCategory,
      [productSubCategory]: currentCategoryCharacters.filter(item => item.character_type === productSubCategory),
    };
  });

  return charactersBySubCategory;
};

export const charactersByProductCategory = (charactersArray: TCharacter[]) => {
  const userCharacters = {};
  charactersArray.forEach(characterData => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { product_category = '' } = characterData || {};
    if (product_category) {
      userCharacters[product_category] = characterData;
    }
  });
  return userCharacters;
};
