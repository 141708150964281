import { TokenResponse } from '@react-oauth/google';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { AppleLoginProps } from 'react-apple-login';
import { animated, useTransition } from 'react-spring';

import { AUTH_BANNERS } from '@lib/core/banners/consts';
import { ENV_RECAPTCHA_SITE_KEY } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import ReCaptchaContainer from '@lib/tools/views/web/containers/reCaptchaContainer';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import AuthBanner from '@components/web/src/organisms/Authentication/AuthBanners/AuthBanner/AuthBanner';
import LoginForm from '@components/web/src/organisms/Authentication/LoginEmailForm/LoginEmailForm';
import SocialConnector from '@components/web/src/organisms/Authentication/SocialConnector/SocialConnector';
import { LoginFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import * as S from '@components/web/src/templates/Login&Registration/LoginPage/styles';

interface IProps {
  isEmailAuthorization: boolean;
  isCaptchaDisabled: boolean;
  isDesignSetVinhoodExperience: boolean;
  errors: Record<string, string> | string;
  recaptchaRef: Record<string, any>;
  isSocialAuthTesting?: boolean;
  showTimer?: boolean;
  setShowTimer?: Dispatch<SetStateAction<boolean>>;
  handleGoBack: () => void;
  handleLogin: (recaptchaValue: string) => void;
  handleForgotPasswordClick: () => void;
  handleFormSubmit: (data: LoginFormInputObject) => void;
  handleEmailAuthButtonClick: () => void;
  responseApple: (response: AppleLoginProps) => void;
  responseFacebook: (response: ISocialLoginFacebook) => void;
  responseGoogle: (response: TokenResponse) => void;
}

const LoginPage: FC<IProps> = ({
  isEmailAuthorization,
  isCaptchaDisabled,
  isDesignSetVinhoodExperience,
  errors,
  recaptchaRef,
  handleGoBack,
  handleFormSubmit,
  handleEmailAuthButtonClick,
  handleForgotPasswordClick,
  handleLogin,
  responseApple,
  responseFacebook,
  responseGoogle,
  showTimer = false,
  isSocialAuthTesting,
  setShowTimer,
}) => {
  const [, setIsBtnDisabled] = useState(ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled);
  const [time, setTime] = useState(60);

  const { commonMessages, loginPage } = localeCommon;

  useEffect(() => {
    let timer;
    if (showTimer && time > 0) {
      timer = setInterval(() => setTime(prevTime => prevTime - 1), 1000);
    } else {
      setShowTimer?.(false);
      setTime(60);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [time, showTimer]);

  const transitions = useTransition(isEmailAuthorization, {
    config: {
      duration: 300,
    },
    enter: {
      opacity: 1,
      y: '0%',
    },
    from: { opacity: 0, y: '-10%' },
    leave: { opacity: 0, y: '-8%' },
  });

  const buttonTransitions = useTransition(isEmailAuthorization, {
    config: {
      duration: 250,
    },
    enter: { y: '0%' },
    exitBeforeEnter: true,
    from: {
      y: isEmailAuthorization ? '-30%' : '0%',
    },
    leave: { y: !isEmailAuthorization ? '-300%' : '0%' },
  });

  return (
    <S.AuthPageContainer>
      <ReCaptchaContainer
        handleValue={handleLogin}
        isDisabled={!!ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled}
        recaptchaRef={recaptchaRef}
        setIsBtnDisabled={setIsBtnDisabled}
      />
      <AuthBanner
        description={loginPage.subtitleFirst}
        registrationBannerVariant={isDesignSetVinhoodExperience ? AUTH_BANNERS.plain : AUTH_BANNERS.app}
      />
      <S.Content>
        <SocialConnector
          handleEmailAuthButtonClick={handleEmailAuthButtonClick}
          isEmailAuthorization={isEmailAuthorization}
          isSocialAuthTesting={isSocialAuthTesting}
          responseApple={responseApple}
          responseFacebook={responseFacebook}
          responseGoogle={responseGoogle}
        />
        {transitions((style, isOpen) => (
          <>
            {isOpen && (
              <animated.div style={style}>
                <LoginForm
                  errors={errors}
                  handleForgotPasswordClick={handleForgotPasswordClick}
                  handleFormSubmit={handleFormSubmit}
                  showTimer={showTimer}
                  time={time}
                />
              </animated.div>
            )}
          </>
        ))}
        {buttonTransitions(style => (
          <animated.div className="btn-wrapper" style={{ ...style }}>
            <BackButton className="go-back-btn" handleClick={handleGoBack} size="sm" text={commonMessages.goBackBtn} />
          </animated.div>
        ))}
      </S.Content>
    </S.AuthPageContainer>
  );
};

export default LoginPage;
