import { useSelector } from 'react-redux';

import {
  selectCommentsData,
  selectIsCommentCreated,
  selectIsCommentUpdated,
  selectIsCommentsLoading,
} from '@lib/core/comments/selectors/comments';

export const useComments = () => {
  const commentsList = useSelector(selectCommentsData);
  const isCommentsLoading = useSelector(selectIsCommentsLoading);
  const isCommentUpdated = useSelector(selectIsCommentUpdated);
  const isCommentCreated = useSelector(selectIsCommentCreated);

  return {
    commentsList,
    isCommentCreated,
    isCommentUpdated,
    isCommentsLoading,
  };
};
