import cn from 'classnames';
import { ButtonHTMLAttributes, CSSProperties, FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ArrowIcon from '@components/web/src/shared/Icons/ArrowIcon';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  customArrowColor?: string;
  style?: CSSProperties;
  text: ILocaleText;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  handleClick: () => void;
  arrowSize?: 'long' | 'short';
}

const BackButton: FC<IProps> = ({
  handleClick,
  className,
  size = 'sm',
  customArrowColor,
  text,
  style,
  arrowSize = 'long',
}) => (
  <button className={cn(className, `back-button`, size)} style={style} type="button" onClick={handleClick}>
    <ArrowIcon fillColor={customArrowColor} size={arrowSize} />
    <Text color={STATIC_COLORS.base.black} text={text} />
  </button>
);

export default BackButton;
