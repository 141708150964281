import { styled } from 'styled-components';

import { STATIC_SHADOWS } from '@components/web/src/foundations';

export const HeaderFooterWidgetContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--color-secondary-100);
  box-shadow: ${STATIC_SHADOWS.xl};
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const BodyContainer = styled.div<{ $isFixedBody: boolean }>`
  flex: 1;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  ${({ $isFixedBody }) =>
    $isFixedBody &&
    `overflow-y: hidden;
  }`}
`;
