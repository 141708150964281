import { css, styled } from 'styled-components';

export const FooterWidgetAnonymousContainer = styled.div<{ $isSticky?: boolean }>`
  ${({ $isSticky }) =>
    $isSticky &&
    css`
      position: sticky !important;
      bottom: 0;
      left: 0;
      z-index: 9998;
    `}

  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: var(--color-primary-800);
`;

export const Link = styled.button<{ $isHidden?: boolean }>`
  all: unset;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
  text-align: center;
  padding: 0 8px;
  cursor: pointer;
`;
