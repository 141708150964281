import { FC, ReactElement, useEffect } from 'react';

import { B2C_MODALS } from '@lib/core/service/consts';
import { SERVICE_TERMS_CATEGORIES } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { useUser } from '@lib/core/users/hooks';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

import { BasicServiceTermsModal } from '@components/web/src/templates/Modals/BasicServiceTermsModal/BasicServiceTermsModal';
import CookiePolicyModal from '@components/web/src/templates/Modals/CookiePolicyModal/CookiePolicyModal';
import FoodPreferencesModal from '@components/web/src/templates/Modals/FoodPreferencesModal/FoodPreferencesModal';
import { GeneralServiceTermsModal } from '@components/web/src/templates/Modals/GeneralServiceTermsModal/GeneralServiceTermsModal';
import InterestsModal from '@components/web/src/templates/Modals/InterestsModal/InterestsModal';
import ManageModal from '@components/web/src/templates/Modals/ManageModal/ManageModal';
import PrivacyPolicyModal from '@components/web/src/templates/Modals/PrivacyPolicyModal/PrivacyPolicyModal';
import TermsConditionsModal from '@components/web/src/templates/Modals/TermsConditionsModal/TermsConditionsModal';

type Props = {
  shouldOpenModalsUnderHeader?: boolean;
  children: ReactElement;
};

export const ModalsHOC: FC<Props> = ({ children, shouldOpenModalsUnderHeader }) => {
  const {
    closeAllModals,
    openModal,
    isBasicServiceTermsModalOpened,
    isCookiePolicyModalOpened,
    isFoodPreferencesModalOpened,
    isInterestsModalOpened,
    isLegalPolicyModalOpened,
    isManageModalOpened,
    isGeneralServiceTermsModalOpened,
    isPrivacyPolicyModalOpened,
  } = useModals();

  const {
    updateServiceTerms,
    serviceTermsByCategory: {
      cookie: { is_selected: isUserAllowedUseLocalStorage, is_always_active: isTechnicalAgreementAlwaysActive } = {},
    },
  } = useServiceTerms();
  const { userId } = useUser();

  const {
    b2cModalsHoc: { deactivateAccountTitle, deactivateAccountDesc, deleteAccountTitle, deleteAccountDesc },
  } = localeApp;

  const accountOptions = {
    deactivateaccount: {
      category: 'deactivateaccount',
      is_always_active: false,
      is_selected: false,
      text: deactivateAccountDesc,
      title: deactivateAccountTitle,
    },
    deleteaccount: {
      category: 'deleteaccount',
      is_always_active: false,
      is_selected: false,
      text: deleteAccountDesc,
      title: deleteAccountTitle,
    },
  };

  useEffect(() => {
    if (!isUserAllowedUseLocalStorage && !userId) {
      openModal(MODALS.TERMS_OF_SERVICE_BASIC_MODAL);
    }
  }, []);

  const handleRejectOptionalBasicServiceTerms = () => {
    updateServiceTerms([{ category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true }]);
  };

  const handleOpenGeneralServiceTermsModal = () => {
    closeAllModals();
    openModal(MODALS.TERMS_OF_SERVICE_GENERAL_MODAL);
  };

  const handleGeneralServiceTermsModalGoBackBtn = () => {
    closeAllModals();
    if (!isTechnicalAgreementAlwaysActive) openModal(MODALS.TERMS_OF_SERVICE_BASIC_MODAL);
  };

  const handleSaveGeneralServiceTerms = (values: IParsedServiceTerm[]) => {
    updateServiceTerms(values).then(() => closeAllModals());
  };
  const handleAcceptAllBasicServiceTerms = () => {
    updateServiceTerms([
      { category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true },
      { category: SERVICE_TERMS_CATEGORIES.profiling, is_selected: true },
    ]).then(() => closeAllModals());
  };
  const handleManageModalConfirmButton = values => {
    console.info(values);
  };

  const handleShowBasicServiceTermsModal = () => {
    openModal(B2C_MODALS.COOKIE_POLICY_MODAL);
  };

  return (
    <>
      <>
        {isLegalPolicyModalOpened && (
          <TermsConditionsModal
            hideModal={closeAllModals}
            isModalOpen={isLegalPolicyModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isPrivacyPolicyModalOpened && (
          <PrivacyPolicyModal
            handleShowBasicServiceTermsModal={handleShowBasicServiceTermsModal}
            hideModal={closeAllModals}
            isModalOpen={isPrivacyPolicyModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isCookiePolicyModalOpened && (
          <CookiePolicyModal
            hideModal={closeAllModals}
            isModalOpen={isCookiePolicyModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isBasicServiceTermsModalOpened && (
          <BasicServiceTermsModal
            handleAcceptAllBasicServiceTerms={handleAcceptAllBasicServiceTerms}
            handleOpenGeneralServiceTermsModal={handleOpenGeneralServiceTermsModal}
            handleRejectOptionalBasicServiceTerms={handleRejectOptionalBasicServiceTerms}
            isModalOpen={isBasicServiceTermsModalOpened}
          />
        )}
        {isGeneralServiceTermsModalOpened && (
          <GeneralServiceTermsModal
            handleGoBack={handleGeneralServiceTermsModalGoBackBtn}
            handleSaveGeneralServiceTerms={handleSaveGeneralServiceTerms}
            hideModal={closeAllModals}
            isModalOpen={isGeneralServiceTermsModalOpened}
            shouldOpenModalsUnderHeader={shouldOpenModalsUnderHeader}
          />
        )}
        {isFoodPreferencesModalOpened && (
          <FoodPreferencesModal hideModal={closeAllModals} isModalOpen={isFoodPreferencesModalOpened} />
        )}
        {isInterestsModalOpened && <InterestsModal hideModal={closeAllModals} isModalOpen={isInterestsModalOpened} />}
        {isManageModalOpened && (
          <ManageModal
            accountOptions={accountOptions}
            handleCancelButton={closeAllModals}
            handleConfirmButton={handleManageModalConfirmButton}
            hideModal={closeAllModals}
            isModalOpen={isManageModalOpened}
          />
        )}
      </>
      <>{children}</>
    </>
  );
};
