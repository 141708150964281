import { Dispatch, FC, SetStateAction } from 'react';

import { isCommentExists } from '@lib/core/comments/filters';
import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import noteIcon from '@components/web/src/assets/icons/comment/NoteIcon.svg';
import noteIconFill from '@components/web/src/assets/icons/comment/NoteIconFill.svg';
import * as S from '@components/web/src/atoms/Comment/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import CommentModal from '@components/web/src/templates/Modals/CommentModal/CommentModal';

interface IProps {
  handleAuthFeatureClick?: () => void;
  isCommentsLoaded: boolean;
  isNewCommentsDataFetching: boolean;
  setIsNewCommentsDataFetching: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
  productId: string;
  commentsList: IComment[];
}

const Comment: FC<IProps> = ({
  productId,
  handleAuthFeatureClick,
  isCommentsLoaded,
  handleSubmitComment,
  isNewCommentsDataFetching,
  setIsNewCommentsDataFetching,
  commentsList,
}) => {
  const commentFromList = commentsList?.find(commentFromArray => isCommentExists(commentFromArray, productId));
  const isCommentInList = commentsList?.some(commentFromArray => isCommentExists(commentFromArray, productId));
  const [isCommentModalOpen, { open: openModal, close: closeModal }] = useDisclosure();
  const handleClick = () => {
    if (handleAuthFeatureClick) {
      handleAuthFeatureClick();
    } else {
      openModal();
    }
  };

  const { note } = localeCommon.comment;

  return (
    <>
      <S.CommentButton data-testid="Comment" onClick={handleClick}>
        <Image alt="Comment icon" height="20px" src={isCommentInList ? noteIconFill : noteIcon} width="25px" />
        <Text color={STATIC_COLORS.teal['600']} size="body3" text={note} weight="medium" />
      </S.CommentButton>

      {isCommentModalOpen && (
        <CommentModal
          commentFromList={commentFromList}
          handleSubmitComment={handleSubmitComment}
          isCommentInList={isCommentInList}
          isCommentModalOpen={isCommentModalOpen}
          isCommentsLoaded={isCommentsLoaded}
          isNewCommentsDataFetching={isNewCommentsDataFetching}
          productId={productId}
          setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default Comment;
