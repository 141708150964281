import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { B2C_EXPOSURE_TAGS } from '@lib/core/service/consts';
import {
  actionToggleUserProductPreferencesValue,
  actionUpdateUserProductPreferences,
} from '@lib/core/service/slices/productPreferencesSlice';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import TagsModal from '@components/web/src/templates/Modals/TagsModal/TagsModal';
import TagsPage from '@components/web/src/templates/TasteId/TasteIdTagsPage/TasteIdTagsPage';

const TasteIdTagsPageContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isTagsModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  const tagsPreferencesState = useTypedSelector(state => state.productPreferences.tagsPreferencesData);

  // ! Check what happened to TTagSubmitValue for typing `value`
  const handleUpdateUserTagsPreferences = value => {
    const { isEnabled, key } = value;
    dispatch(actionToggleUserProductPreferencesValue({ exposure: B2C_EXPOSURE_TAGS, slug: key }));
    dispatch(actionUpdateUserProductPreferences({ exposure: B2C_EXPOSURE_TAGS, isEnabled, slug: key }));
  };

  const onToggleClick = (optionKey: string) => {
    const changedPreferences = {
      isEnabled: !tagsPreferencesState[optionKey].isEnabled,
      key: optionKey,
    };
    handleUpdateUserTagsPreferences(changedPreferences);
  };

  const handleBackBtnClick = () => {
    navigate(-1);
  };

  return (
    <>
      <TagsPage
        handleBackBtnClick={handleBackBtnClick}
        tagsPreferencesState={tagsPreferencesState}
        onTagsInfoClick={openModal}
        onToggleClick={onToggleClick}
      />
      {isTagsModalOpen && (
        <TagsModal hideModal={closeModal} isModalOpen={isTagsModalOpen} tagsPreferencesState={tagsPreferencesState} />
      )}
    </>
  );
};

export default TasteIdTagsPageContainer;
