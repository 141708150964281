import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const CharacterInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 40px 8px 16px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 50px;
  box-shadow: ${STATIC_SHADOWS.sm};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;
