import styled from 'styled-components';

export const WishlistContainer = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35.1px;
  padding: 0;
  background-color: transparent;
  border: none;

  &:active {
    color: transparent !important;
    background-color: transparent !important;
    border: none !important;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent !important;
    border: none !important;
  }
`;
