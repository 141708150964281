import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BannerSkeleton from '@components/web/src/app/Skeleton/BannerSkeleton';
import LongCardSkeleton from '@components/web/src/app/Skeleton/LongCardSkeleton';

const HomePageSkeleton = () => {
  const {
    homePageSkeleton: { mainHeaderText, experienceHeadingText, productSwiperText },
  } = localeApp;

  return (
    <div className="vh-home-page-skeleton">
      <div className="main-header-section">
        <div className="myhome-header">
          <LocaleFragment message={mainHeaderText} />
        </div>
      </div>
      <BannerSkeleton />
      <BannerSkeleton />
      <div className="main-section">
        <div className="heading-section">
          <div className="title">
            <LocaleFragment message={productSwiperText} />
          </div>
        </div>
        <div className="card-section">
          <LongCardSkeleton />
        </div>
        <div className="heading-section">
          <div className="title">
            <LocaleFragment message={experienceHeadingText} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSkeleton;
