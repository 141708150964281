import { FC } from 'react';

import { HARDCODED_FEEDBACK_DATA } from '@lib/core/service/consts';
import { TFeedback } from '@lib/core/users/slices/feedbacks';

import * as S from '@components/web/src/atoms/Feedback/FeedbackListItem/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IItem {
  feedback: TFeedback;
  isActive: boolean;
}

interface IProps extends IItem {
  onHandleClick: () => void;
}

const FeedbackListItem: FC<IProps> = ({ feedback, isActive, onHandleClick }) => (
  <S.FeedbackListItemContainer data-testid="FeedbackListItem" onClick={() => onHandleClick()}>
    <S.FeedbackIcon $isActive={isActive}>
      <Image alt="feedback" maxHeight="20px" maxWidth="20px" src={HARDCODED_FEEDBACK_DATA[feedback].icon.activeIcon} />
    </S.FeedbackIcon>
    <Text
      color={STATIC_COLORS.teal[isActive ? 900 : 600]}
      size="body1"
      text={HARDCODED_FEEDBACK_DATA[feedback].text}
      weight="medium"
    />
  </S.FeedbackListItemContainer>
);

export default FeedbackListItem;
