import { FC, useEffect, useState } from 'react';

import { IParsedServiceTerm, IServiceTermCategory } from '@lib/core/serviceTerms/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { IModalOption } from '@components/web/src/app/Modals/modalTypes';
import Button from '@components/web/src/atoms/Buttons/Button';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import Spinner from '@components/web/src/components/GenericSpinner/GenericSpinner';
import { GeneralTermsCheckboxesComponent } from '@components/web/src/templates/Modals/GeneralTermsCheckboxesComponent/GeneralTermsCheckboxesComponent';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  serviceTerms: Partial<Record<IServiceTermCategory, IParsedServiceTerm>>;
  isLoading?: boolean;
  hideModal: () => void;
  handleConfirmButton?: () => void;
  handleSaveServiceTerms?: (serviceTerms: IParsedServiceTerm[]) => void;
}

export const FullServiceTermsModal: FC<IProps> = ({
  isModalOpen,
  serviceTerms,
  isLoading = false,
  hideModal,
  handleSaveServiceTerms,
  handleConfirmButton,
}) => {
  const [serviceTermsState, setServiceTermsState] = useState(serviceTerms);
  const { cookie, marketing, profiling } = serviceTermsState;
  useEffect(() => {
    if (isModalOpen) {
      setServiceTermsState(serviceTerms);
    }
  }, [isModalOpen]);

  const handleChangeServiceTerm = ({ category }: IParsedServiceTerm | IModalOption) => {
    setServiceTermsState({
      ...serviceTermsState,
      [category]: {
        ...serviceTermsState[category],
        is_selected: !serviceTermsState[category]?.is_selected,
      },
    });
  };

  const handleOnSave = () => {
    handleSaveServiceTerms([cookie, marketing, profiling]);
  };

  const handleOnCloseOrCancel = () => {
    setServiceTermsState({});
    hideModal();
  };

  const {
    preferencesModal: {
      modalHeaderTitle,
      modalHeaderDescription,
      privacyTitle,
      privacyOptionDescription,
      cookieTitle,
      buttonConfirmText,
    },
  } = localeApp;
  return (
    <ModalWrapper
      fullWidthModal
      hideModal={handleOnCloseOrCancel}
      isOpen={isModalOpen}
      modalClassName="preferences-modal-container"
    >
      <ProfileSectionHeader description={modalHeaderDescription} title={modalHeaderTitle} />
      <div className="content-wrapper">
        <div className="content-section">
          <p className="header-text">
            <LocaleFragment message={cookieTitle} />
          </p>
          <GeneralTermsCheckboxesComponent options={[cookie, profiling]} onToggle={handleChangeServiceTerm} />
        </div>
        <div className="content-section">
          <p className="header-text">
            <LocaleFragment message={privacyTitle} />{' '}
          </p>
          <p className="privacy-description-text">
            <LocaleFragment message={privacyOptionDescription} />{' '}
          </p>
          <GeneralTermsCheckboxesComponent options={[marketing]} onToggle={handleChangeServiceTerm} />
          <Button handleClick={handleConfirmButton} size="sm" text={buttonConfirmText} onClick={handleOnSave} />
        </div>
      </div>
      {isLoading && <Spinner />}
    </ModalWrapper>
  );
};
