import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  LEGAL_PAGE_COOKIE_POLICY_TAB,
  LEGAL_PAGE_PRIVACY_POLICY_TAB,
  LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
} from '@lib/tools/shared/helpers/consts';

import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { B2B_MOBILE_RESOLUTION_MAX_VALUE } from '@components/web/src/kiosk/consts';
import CookiePolicyTab from '@components/web/src/pages/LegalTabs/CookiePolicyTab/CookiePolicyTab';
import PrivacyPolicyTab from '@components/web/src/pages/LegalTabs/PrivacyPolicyTab/PrivacyPolicyTab';
import TermsConditionsTab from '@components/web/src/pages/LegalTabs/TermsConditionsTab/TermsConditionsTab';

interface IProps {
  handleGoBack: () => void;
  onTabClick: (tabCode: string) => void;
  isApplicationKiosk: boolean;
  defaultTab: string;
}

const LegalPage: FC<IProps> = ({
  handleGoBack,
  onTabClick,
  isApplicationKiosk,
  defaultTab = LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
}) => {
  const { commonMessages } = localeCommon;

  const isMobileResolution = useMediaQuery({ maxWidth: B2B_MOBILE_RESOLUTION_MAX_VALUE });

  const tabsData = [
    {
      children: <TermsConditionsTab handleGoBack={handleGoBack} isApplicationKiosk={isApplicationKiosk} />,
      isHiddenTab: false,
      tabCode: LEGAL_PAGE_TERMS_AND_CONDITIONS_TAB,
      tabText: commonMessages.termsAndConditionsTitle,
    },
    {
      children: <PrivacyPolicyTab handleGoBack={handleGoBack} isApplicationKiosk={isApplicationKiosk} />,
      isHiddenTab: false,
      tabCode: LEGAL_PAGE_PRIVACY_POLICY_TAB,
      tabText: commonMessages.privacyPolicyTitle,
    },
    {
      children: <CookiePolicyTab handleGoBack={handleGoBack} isApplicationKiosk={isApplicationKiosk} />,
      isHiddenTab: !isMobileResolution && isApplicationKiosk,
      tabCode: LEGAL_PAGE_COOKIE_POLICY_TAB,
      tabText: commonMessages.cookiePolicyTitle,
    },
  ];

  return (
    <div className="legal-page-container">
      <Tabs
        active={defaultTab}
        gradientVariant={isApplicationKiosk ? 'default-down' : 'custom-down'}
        tabs={tabsData}
        onTabClick={onTabClick}
      />
    </div>
  );
};

export default LegalPage;
