import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import headerImage from '@components/web/src/assets/legacy/b2b/widget/header_taste_path_image.jpeg';

const TastePathEnd: FC = () => {
  const { tastePathPage } = localeWidget;

  return (
    <div className="taste-path-end-container">
      <div className="image-wrapper">
        <img alt="header" src={headerImage} />
      </div>
      <div className="content">
        <p className="title">
          <LocaleFragment message={tastePathPage.titleEnd} />
        </p>
        <p className="description">
          <LocaleFragment message={tastePathPage.descriptionEnd} />
        </p>
      </div>
    </div>
  );
};

export default TastePathEnd;
