import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import ideaIcon from '@components/web/src/assets/icons/idea.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/TastingTip/styles';

interface IProps {
  text: ILocaleText;
}

export const TastingTip: FC<IProps> = ({ text }) => {
  const { tastingTipTitle } = localeWidget.ageAndGenderPages;

  return (
    <S.TastingTip data-testid="TastingTip">
      <img alt="idea icon" src={ideaIcon} />
      <Flexbox direction="column" gap={4}>
        <Text color={STATIC_COLORS.base.white} size="body1" text={tastingTipTitle} weight="semibold" />
        <Text color={STATIC_COLORS.base.white} size="body1" text={text} />
      </Flexbox>
    </S.TastingTip>
  );
};
