import React from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import * as S from '@components/web/src/atoms/Feedback/Feedback/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface FeedbackTooltipProps {
  infoText: ILocaleText;
  isShowTooltip: boolean;
  isAnimated: boolean;
}

const FeedbackTooltip: React.FC<FeedbackTooltipProps> = ({ infoText, isShowTooltip, isAnimated }) => {
  return (
    <>
      {isShowTooltip ? (
        <S.FeedbackTooltipContainer $isAnimated={isAnimated} data-testid="FeedbackTooltip">
          <Text color={STATIC_COLORS.base.white} size="body2" text={infoText} weight="semibold" />
          <S.Arrow />
        </S.FeedbackTooltipContainer>
      ) : null}
    </>
  );
};

export default FeedbackTooltip;
