import styled, { css, keyframes } from 'styled-components';

const rotateScale = keyframes`
  0% {
    opacity: 1;
    transform: rotate(-30deg);
  }
  50% {
    opacity: 1;
    transform: rotate(0deg);
  }
  75% {
    opacity: 1;
    transform: rotate(360deg);
  }
  85% {
    opacity: 0.7;
    transform: rotate(360deg);
  }
  100% {
    visibility: hidden;
    opacity: 0.5;
    transform: rotate(360deg);
  }
`;

const elasticFadeIn = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }
  25% {
    visibility: visible;
    opacity: 1;
    transform: scale(0.42);
  }
  50% {
    visibility: visible;
    opacity: 1;
    transform: scale(0.42);
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`;

const scaleSplash = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
    transform: scale(0.42);
  }
  25% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  50% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  100% {
    visibility: visible;
    opacity: 0;
    transform: scale(1);
  }
`;

export const WishlistAnimationContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  overflow: visible;
  cursor: pointer;
  background-color: transparent;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      width: 24px;
      height: 24px;
    `}
`;

export const RotateScale = styled.div<{ $isActive: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      position: absolute;
      top: -1px;
      transform-origin: center;
      animation: ${rotateScale} 0.8s linear forwards;
    `}
`;

export const FadeIn = styled.div<{ $isActive: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      position: absolute;
      top: -1px;
      opacity: 0;
      transform-origin: center;
      animation: ${elasticFadeIn} 0.8s forwards;
      animation-delay: 0.75s;
    `}
`;

export const ScaleAndSplash = styled.div<{ $isActive: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      position: absolute;
      margin: 1px 2px 0 0;
      opacity: 0;
      transform-origin: center;
      animation: ${scaleSplash} 0.7s linear forwards;
      animation-delay: 1.3s;
    `}
`;
