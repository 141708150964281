import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const AuthorizationGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  p {
    margin: 0;
  }
`;

export const GroupWrapper = styled.div<{ $errorType: string }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  text-align: center;

  border-color: ${({ $errorType }) => ($errorType ? STATIC_COLORS.error[600] : 'none')};
  padding-inline: ${({ theme }) => theme?.isExperienceRootElementType && 0};
`;
