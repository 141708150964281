import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const RedirectLink = styled(Flexbox)`
  cursor: pointer;
`;

export const Icon = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
`;

export const StyledRedirectText = styled(Text)`
  text-decoration: underline;
`;
