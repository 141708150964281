import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { isApplicationPmi } from '@lib/core/service/consts';
import { useComms } from '@lib/tools/comms/hooks';
import { initComms, isAppInIframe } from '@lib/tools/comms/utils';

const CommsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { isHostHandshakeApproved } = useComms();

  /**
   * * Setup postMessage listener
   */
  useEffect(() => {
    // Listen for messages from hosting libraries
    if (isAppInIframe) {
      initComms(dispatch);
    }
  }, []);

  // ! Support VH handshake.
  return isAppInIframe || (isApplicationPmi && isHostHandshakeApproved) || !isAppInIframe ? children : null;
};

export default CommsProvider;
