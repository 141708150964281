import React, { FC } from 'react';

import * as S from '@components/web/src/atoms/TestAnswer/TestAnswerAnimation/styles';

interface IProps {
  isActive: boolean;
  duration?: number;
  scale?: number;
}

const TestAnswerAnimation: FC<IProps> = ({ isActive, duration = 3, scale = 1 }) => {
  // Calculating scaling factor based on original duration (3 seconds)
  const scaleFactor = duration / 3;

  return (
    <S.QuizAnimationContainer
      data-testid={`quiz-answer-animation-${isActive}-${duration}-${scale}`}
      scale={scale}
      style={{ '--scale-factor': scaleFactor } as React.CSSProperties}
    >
      <S.RotateAndShrinkIcon $isActive={isActive} />
      <S.SplashIcon $isActive={isActive} />
      <S.TealIcon isTeal $isActive={isActive} />
    </S.QuizAnimationContainer>
  );
};

export default TestAnswerAnimation;
