import { useNavigate } from 'react-router-dom';

import { AUTH_BANNERS, TAuthBanner } from '@lib/core/banners/consts';
import { TProductCategory } from '@lib/core/products/types';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { PAGES } from '@lib/tools/views/urls';

export interface IAuthFeatureParams {
  registrationBannerVariant: TAuthBanner;
  productCategory?: TProductCategory;
  adjectiveCharacterIdToMove?: string;
  correctQuizAnswer?: string;
}

export const useAuthFeatureClick = () => {
  const navigate = useNavigate();

  const { isUserAuthenticated } = useUser();

  const handleAuthFeatureClick = isUserAuthenticated
    ? null
    : (params?: IAuthFeatureParams) => {
        const bannerParams = { registrationBannerVariant: AUTH_BANNERS.authFeature, ...params };

        return navigate(prependBasename(PAGES.vinhood.registration), {
          state: { bannerParams },
        });
      };

  return { handleAuthFeatureClick };
};
