import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { charactersByProductCategory } from '@lib/core/characters/utils';
import { useUserQuiz, useUserQuizType } from '@lib/core/quizzes/hooks';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA, PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { actionGetClientIp } from '@lib/core/service/slices/technical/clientIp';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useUser } from '@lib/core/users/hooks';
import { selectFeedbackData } from '@lib/core/users/selectors/feedback';
import {
  selectWishlistProductInstanceIds,
  selectWishlistProductListLoading,
} from '@lib/core/users/selectors/productList';
import initTrackers from '@lib/tools/dat';
import { useLogging } from '@lib/tools/dat/hooks';
import MixpanelTracker, { MixpanelExtras, useDataChangedEffect } from '@lib/tools/dat/mixpanel';
import { USER_PRODUCT_PREFERENCES, USER_PRODUCT_PREFERENCES_FOOD_DATA } from '@lib/tools/shared/helpers/consts';
import { useEffectSkipFirst, useTypedSelector } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

/**
 * This HOC can be used to set listeners on required keys in the store.
 */
const DatProvider = ({ children }) => {
  const {
    userCharacters,
    userLastName,
    userFirstName,
    userEmail: email,
    userGroups,
    userId,
    profileCountry,
    profileGenderSlug,
    profileBirthYear,
    mixpanelUserDidIdentify: mixPanelDidIdentify,
    isUserAuthenticated,
  } = useUser();
  const dispatch = useTypedDispatch();

  const { productCategory } = useApp();
  const { retailerId } = useRetailer();
  const { serviceTermsByCategory } = useServiceTerms();
  const { profiling } = serviceTermsByCategory;

  const foodPreferences = useTypedSelector(state => state.productPreferences?.[USER_PRODUCT_PREFERENCES_FOOD_DATA]);
  const { isClientIpBlocked, isClientIpLoading } = useTechnical();
  const { isLoggingEnabled, mixPanelState } = useLogging();

  const feedbackData = useSelector(selectFeedbackData);

  const isWishlistProductListLoading = useSelector(selectWishlistProductListLoading);
  const wishlistProductInstanceIds = useSelector(selectWishlistProductInstanceIds);

  const tagsPreferences = useTypedSelector(state => state.productPreferences?.[B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA]);

  const { userQuizType, isUserQuizComplete, userQuizState } = useUserQuiz();
  const { isUserQuizTypeTaste } = useUserQuizType();

  const shouldTrack = useCallback(
    /**
     * Determines whether a tracking method should be invoked based on 3 factors:
     * - Mixpanel library availability
     * - User identification status
     * - Whether the method should trigger even if the user is not identified
     *
     * @param setSuperPropsIfAnonymous - Whether the tracking method should set super properties regardless of the
     * user's identification status.
     */
    (setSuperPropsIfAnonymous: boolean) => {
      return !!(mixPanelState && (mixPanelDidIdentify || setSuperPropsIfAnonymous));
    },
    [mixPanelState, mixPanelDidIdentify],
  );

  // Store the return value of shouldTrack in a state variable
  const [trackIfAnonymous, setTrackIfAnonymous] = useState(shouldTrack(true));
  const [trackIfIdentified, setTrackIfIdentified] = useState(shouldTrack(false));
  // const wishlistDataChanged = useRef(false);

  useEffect(() => {
    if (!mixPanelState && !isClientIpLoading) {
      dispatch(actionGetClientIp()).then(() => initTrackers());
    } else if (retailerId && productCategory !== PRODUCT_CATEGORY_NONE) {
      // * Set super properties and entry point when the retailerId and productCategory are available
      MixpanelExtras.initializeSuperProperties();
      MixpanelTracker.events.entryPoint();
    }
  }, [retailerId, productCategory, mixPanelState]);

  useEffectSkipFirst(() => {
    if (userQuizType) {
      const { foodPreferencesData: foodPreferencesDataFromLocalStorage } =
        JSON.parse(localStorage.getItem(USER_PRODUCT_PREFERENCES)) || {};

      MixpanelTracker.events.testStarted(
        userQuizState.data,
        isUserQuizTypeTaste ? foodPreferencesDataFromLocalStorage : null,
      );
    }
  }, [userQuizType]);

  useEffectSkipFirst(() => {
    if (isUserQuizComplete) MixpanelTracker.events.testCompleted(userQuizState.data);
  }, [isUserQuizComplete]);

  useEffect(() => {
    // Update the state variables whenever shouldTrack's return value changes
    setTrackIfAnonymous(shouldTrack(true));
    setTrackIfIdentified(shouldTrack(false));
  }, [shouldTrack]);

  useEffect(() => {
    // Handle mixpanel if user logs out from the app
    if (mixPanelState && !isUserAuthenticated) {
      MixpanelTracker.events.signOut();
    }
  }, [mixPanelState, isUserAuthenticated]);

  useEffect(() => {
    // Handle mixpanel identification of the user when they authenticate or
    // start a new session when already authenticated
    if (mixPanelState && isUserAuthenticated && userId) {
      MixpanelTracker.profile.handleUserIdentity(userId);
    }
  }, [mixPanelState, isUserAuthenticated, userId]);

  useEffect(() => {
    // Anonymous user made a choice for profiling option
    if (trackIfAnonymous && profiling) {
      MixpanelTracker.profile.updateAnonymousProfilingOption(profiling);
    }
  }, [trackIfAnonymous, profiling]);

  useEffect(() => {
    // User privacy settings have been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserPrivacyOptions(Object.values(serviceTermsByCategory));
    }
  }, [trackIfIdentified, serviceTermsByCategory]);

  useEffect(() => {
    // Auth user data have been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserPersonalInfo(email, userFirstName, userLastName);
    }
  }, [trackIfIdentified, email, userFirstName, userLastName]);

  useEffect(() => {
    // Anon or auth profile data have been updated
    if (trackIfAnonymous || trackIfIdentified) {
      MixpanelTracker.profile.updateUserSociodemographicData(profileGenderSlug, profileBirthYear, profileCountry);
    }
  }, [trackIfAnonymous, trackIfIdentified, profileGenderSlug, profileBirthYear, profileCountry]);

  useDeepCompareEffect(() => {
    // User characters have been updated
    if (trackIfAnonymous) {
      MixpanelTracker.profile.updateUserCharacters(charactersByProductCategory(userCharacters));
    }
  }, [trackIfAnonymous, mixPanelDidIdentify, userCharacters]);

  useDeepCompareEffect(() => {
    // User preferences have been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserPreferences(foodPreferences, tagsPreferences);
    }
  }, [trackIfIdentified, foodPreferences, tagsPreferences]);

  // User product wishlist has been updated
  useDataChangedEffect({
    data: wishlistProductInstanceIds,
    deps: [trackIfIdentified, wishlistProductInstanceIds, isWishlistProductListLoading],
    effect: () => MixpanelTracker.profile.updateUserProductWishlist(wishlistProductInstanceIds),
    trackCondition: trackIfIdentified && !isWishlistProductListLoading,
  });

  useDeepCompareEffect(() => {
    // User product feedback list has been updated
    if (trackIfIdentified) {
      MixpanelTracker.profile.updateUserProductFeedback(feedbackData);
    }
  }, [trackIfIdentified, feedbackData]);

  useEffect(() => {
    // Enable or disable tracking based on user IP, DevTools state or user group
    if (mixPanelState) {
      MixpanelExtras.switchTrackingOnOff(isClientIpBlocked || isLoggingEnabled, userGroups);
    }
  }, [mixPanelState, isClientIpBlocked, userGroups, isLoggingEnabled]);

  return children;
};

export default DatProvider;
