import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { BANNER_POSITION_EXPERIENCES } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { fetchExperiencesLists } from '@lib/core/experiences/slices/experienceCatalogSlice';
import { EXPERIENCE_TYPE_REPLACEMENT } from '@lib/core/service/consts';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useTypedSelector } from '@lib/tools/views/hooks/useTypedSelector';
import { PAGES } from '@lib/tools/views/urls';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import { PRODUCT_CATEGORY_QUERY_KEY } from '@components/web/src/foundations/consts';
import ExperienceCatalog from '@components/web/src/templates/Catalogs/App/ExperienceCatalog/ExperienceCatalog';

const ExperienceCatalogContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    experiencesData: { results: experiencesLists },
    experiencesTypes,
  } = useTypedSelector(state => state.experienceCatalog);

  const productCategory = new URLSearchParams(search).get(PRODUCT_CATEGORY_QUERY_KEY);

  useEffect(() => {
    dispatch(fetchExperiencesLists({ productCategory }));
  }, [productCategory]);

  const experienceSectionData = useMemo(() => {
    const data = [];

    experiencesTypes.forEach(experienceType => {
      const experienceTypeFilter = (experience: IB2CExperience) =>
        experience.experience_type.identifier === experienceType.identifier;

      const filteredListOfExperiences = experiencesLists.filter(experienceTypeFilter);

      if (filteredListOfExperiences.length) {
        data.push({
          experienceTypeId: experienceType.identifier,
          experiences: filteredListOfExperiences,
          sectionTitle: experienceType.name,
        });
      }
    });

    return data;
  }, [experiencesLists, experiencesTypes]);

  const banners = useBanners({
    bannerPosition: BANNER_POSITION_EXPERIENCES,
    returnAllBanners: true,
  });

  const getBanner: () => TBannerCard = () => getMultipleUniqueRandomItemsFromArray(banners, 1)[0];

  const topBanner = useMemo(() => getBanner(), [banners]);

  const handleBannerClick = (route, linkParams) =>
    navigate(`${prependBasename(`/${route}/`)}${linkParams ? `?${linkParams}` : ''}`);

  const navigateToExperienceSearch = experienceTypeId =>
    navigate(prependBasename(PAGES.vinhood.experienceSearch.replace(EXPERIENCE_TYPE_REPLACEMENT, experienceTypeId)));

  useEffect(() => {
    MixpanelTracker.events.experienceGenericCatalogView();
  }, []);

  return (
    <ExperienceCatalog
      experienceSectionData={experienceSectionData}
      handleBannerClick={handleBannerClick}
      navigateToExperienceSearch={navigateToExperienceSearch}
      topBanner={topBanner}
    />
  );
};

export default ExperienceCatalogContainer;
