import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { ReactComponent as ArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/bannerdropdownwhite.svg';
import UserCharacterInfoItem from '@components/web/src/organisms/Cards/MyTasteProfileCard/UserCharacterInfoItemSection/UserCharacterInfoItem';

type Props = {
  characters: Record<TProductCategory, TCharacter>;
  onClick: () => void;
};

const UserCharacterInfoItemSection: FC<Props> = ({ characters, onClick }) => {
  return (
    <div aria-hidden className="user-character-info-item-section-container" onClick={onClick}>
      <div className="characters-container">
        <UserCharacterInfoItem characterData={characters?.wine} productCategory={PRODUCT_CATEGORY_WINE} />
        <UserCharacterInfoItem characterData={characters?.beer} productCategory={PRODUCT_CATEGORY_BEER} />
        <UserCharacterInfoItem characterData={characters?.coffee} productCategory={PRODUCT_CATEGORY_COFFEE} />
      </div>
      <div>
        <ArrowIcon />
      </div>
    </div>
  );
};

export default UserCharacterInfoItemSection;
