import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { IB2CArticle } from '@app/native/src/interfaces/article';
import { ARTICLE_ID_URL_PARAM } from '@app/web/src/helpers/consts';

import { fetchArticleLists } from '@lib/core/articles/slices/articleCatalogSlice';
import { BANNER_POSITION_ARTICLES } from '@lib/core/banners/consts';
import { useBanners } from '@lib/core/banners/hooks';
import { useApp } from '@lib/core/service/hooks';
import { getMultipleUniqueRandomItemsFromArray, prependBasename } from '@lib/core/service/utils';
import { useTypedSelector } from '@lib/tools/views/hooks/useTypedSelector';
import { PAGES } from '@lib/tools/views/urls';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import ArticleCatalog from '@components/web/src/templates/Catalogs/App/ArticleCatalog/ArticleCatalog';

const ArticleCatalogContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { locale } = useApp();

  const { articlesTypes = [], articlesData: { results: articleLists = [] } = {} } = useTypedSelector(
    state => state.articleCatalog,
  );

  const articleSectionData = useMemo(() => {
    const data = [];

    articlesTypes.forEach(articleType => {
      const articleTypeFilter = (article: IB2CArticle) => article.article_type.identifier === articleType.identifier;

      const filteredListOfArticles = articleLists.filter(articleTypeFilter);

      if (filteredListOfArticles.length) {
        data.push({
          articles: filteredListOfArticles.slice(0, 3),
          sectionTitle: articleType.name,
        });
      }
    });

    return data;
  }, [articleLists, articlesTypes]);

  useEffect(() => {
    dispatch(fetchArticleLists());
  }, [locale]);

  const banners = useBanners({
    bannerPosition: BANNER_POSITION_ARTICLES,
    returnAllBanners: true,
  });

  const getBanner: () => TBannerCard = () => getMultipleUniqueRandomItemsFromArray(banners, 1)[0];

  const topBanner = useMemo(() => getBanner(), [banners]);

  const handleBannerClick = (route, linkParams) =>
    navigate(`${prependBasename(`/${route}/`)}${linkParams ? `?${linkParams}` : ''}`);

  const navigateToArticlePage = articleId =>
    navigate(prependBasename(PAGES.vinhood.article, { [ARTICLE_ID_URL_PARAM]: articleId }), {
      state: { previousPage: pathname + search },
    });

  return articleSectionData?.length ? (
    <ArticleCatalog
      articleSectionData={articleSectionData}
      handleBannerClick={handleBannerClick}
      navigateToArticlePage={navigateToArticlePage}
      topBanner={topBanner}
    />
  ) : null;
};

export default ArticleCatalogContainer;
