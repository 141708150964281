import classNames from 'classnames';
import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';

type IJourneyStepsSubCategoryProps = {
  id: number | string;
  isActive: boolean;
  isDone: boolean;
};

export type IJourneyStepsProps = {
  category: string;
  subCategory: IJourneyStepsSubCategoryProps[];
};

type IJourneyMapProps = {
  steps: IJourneyStepsProps[];
  productCategory: TProductCategory;
};

const TasteJourneyMap: FC<IJourneyMapProps> = ({ steps = [], productCategory }) => {
  return (
    <div className="taste-journey-map-container">
      <div className={`step_container ${productCategory}`}>
        {steps.map(step => (
          <div key={step.category} className="step_group">
            <span className="title">{step.category}</span>
            <div className="horizontal-bar">
              <span className={`step_wrapper ${productCategory}`}>
                {step.subCategory.map((item, index) => (
                  <span
                    key={index}
                    className={classNames('step_item', productCategory, {
                      isActive: item.isActive,
                      isDone: item.isDone,
                    })}
                  />
                ))}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TasteJourneyMap;
