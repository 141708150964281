import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

const ProfileSectionHeader = ({ title, description }) => (
  <div className="profile-section-header-container">
    <p className="vh-section-title">
      <LocaleFragment message={title} />
    </p>
    <p className="vh-section-description">
      <LocaleFragment message={description} />
    </p>
  </div>
);

export default ProfileSectionHeader;
