import { TokenResponse, useGoogleLogin } from '@react-oauth/google';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { GOOGLE } from '@lib/tools/shared/helpers/consts';

import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';

type TGoogleLoginButtonProps = {
  isDisabled?: boolean;
  isHidden?: boolean;
  isRegistrationPage: boolean;
  authOptions?: Record<string, any>;
  text?: ILocaleText;
  responseGoogle: (tokenResponse: TokenResponse) => void;
};

const GoogleLoginButton = ({
  isDisabled = false,
  isHidden = false,
  isRegistrationPage,
  authOptions = {},
  responseGoogle,
  text = '',
}: TGoogleLoginButtonProps) => {
  const login = useGoogleLogin({
    onError: () => {
      console.info('Login Failed');
    },
    onSuccess: (tokenResponse: TokenResponse) => {
      responseGoogle(tokenResponse);
    },
  });

  return (
    <AuthorizationButton
      key={GOOGLE}
      handleClick={login}
      isDisabled={isDisabled}
      isHidden={isHidden}
      text={text || (isRegistrationPage ? authOptions.googleSignup : authOptions.googleLogIn)}
      type={GOOGLE}
      variant="secondary"
    />
  );
};

export default GoogleLoginButton;
