import { FC } from 'react';

import * as S from '@components/web/src/kiosk/KioskCirclesBackground/styles';
import BackgroundCircles from '@components/web/src/shared/BackgroundCircles/BackgroundCirlcles';

export type Props = {
  customColor?: string;
};

const KioskCirclesBackground: FC<Props> = ({ customColor = null }) => {
  return (
    <S.CirclesBackgroundContainer data-testid="CirlcesBackgroundContainer">
      <S.CirclesBackground>
        <BackgroundCircles customColor={customColor} />
      </S.CirclesBackground>
    </S.CirclesBackgroundContainer>
  );
};

export default KioskCirclesBackground;
