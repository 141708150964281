import styled from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

export const RegistrationNavigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LinkButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

export const LinkText = styled(Text)`
  text-decoration-line: underline;
  cursor: pointer;
`;
