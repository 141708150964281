import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { ZodType } from 'zod';

import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { IRegisterPageServiceTerms } from '@lib/tools/shared/auths/views/containers/RegistrationContainer';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { RegisterPageServiceTermsCheckboxes } from '@components/web/src/components/Authentication/RegisterPageServiceTermsCheckboxes/RegisterPageServiceTermsCheckboxes';
import InputField from '@components/web/src/components/Input/InputFields';
import {
  AuthFormItemTypes,
  AvaibableInputKeys,
  AvailableInputObjects,
} from '@components/web/src/pages/Authentication/AuthFormInterface';

interface IProps {
  validationSchema: ZodType<AvailableInputObjects>;
  formFields: AuthFormItemTypes<AvaibableInputKeys>[];
  submitBtnText: ILocaleText;
  forgotPasswordBtnText?: ILocaleText;
  isCTAHidden?: boolean;
  isRegistrationPage?: boolean;
  isUserAllowedUseEmailForMarketing?: boolean;
  disableCTA?: boolean;
  handleLinkClick?: () => void;
  handleOnServiceTermToggle?: (term: IParsedServiceTerm) => void;
  onSubmitHandler: (data: AvailableInputObjects) => void;
  registerPageServiceTerms?: IRegisterPageServiceTerms;
}

const GenericAuthForm: FC<IProps> = ({
  validationSchema,
  formFields,
  submitBtnText = '',
  forgotPasswordBtnText = '',
  isRegistrationPage = false,
  isCTAHidden = false,
  disableCTA = false,
  handleLinkClick,
  onSubmitHandler,
  handleOnServiceTermToggle,
  registerPageServiceTerms,
}) => {
  const [disableButton, setDisableButton] = useState(() => disableCTA);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<AvailableInputObjects>({ mode: 'onChange', resolver: zodResolver(validationSchema) });

  useEffect(() => {
    if (disableButton) {
      watch((_, { name }) => name && setDisableButton(false));
    }
  }, [disableButton]);

  const { isValid } = useFormState({ control });
  const onSubmit: SubmitHandler<AvailableInputObjects> = data => {
    setDisableButton(true);
    onSubmitHandler({ ...data });
  };

  const isDisabledSubmitBtn =
    !isValid || (isRegistrationPage && !registerPageServiceTerms?.policy?.is_selected) || disableButton;

  return (
    <form
      noValidate
      autoComplete="off"
      className={cn('generic-auth-form-container', { isRegistrationPage })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="input-fields">
        {formFields?.map(({ name, label, type, hint, ...rest }) => (
          <InputField
            key={name}
            hint={hint}
            label={label}
            name={name}
            type={type}
            {...register(name as keyof AvailableInputObjects)}
            autoComplete="false"
            errors={errors}
            {...rest}
          />
        ))}
      </div>

      {isRegistrationPage && (
        <RegisterPageServiceTermsCheckboxes
          handleOnServiceTermToggle={handleOnServiceTermToggle}
          registerPageServiceTerms={registerPageServiceTerms}
        />
      )}
      {forgotPasswordBtnText && (
        <Button
          className="forgot-password-btn"
          size="sm"
          text={forgotPasswordBtnText}
          variant={VH_VARIANTS.LINK}
          onClick={handleLinkClick}
        />
      )}

      {!isCTAHidden && (
        <Button disabled={isDisabledSubmitBtn} text={submitBtnText} type="submit" variant={VH_VARIANTS.PRIMARY} />
      )}
    </form>
  );
};

export default GenericAuthForm;
