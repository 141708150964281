import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const TastingTip = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-radius: 16px;
  background-color: ${STATIC_COLORS.gray[800]};
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
`;
