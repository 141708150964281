import styled, { css } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${STATIC_COLORS.base.white};

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      flex-direction: row;
      align-items: flex-end;
      box-shadow: ${STATIC_SHADOWS.lg};
    `}
`;

export const FeedbackWrapper = styled(Flexbox)`
  width: fit-content;
`;

export const ProductImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 290px;
  overflow: hidden;
  background: ${STATIC_GRADIENTS.warmGradientReversed()};

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-width: 550px;
      max-width: 550px;
      height: 590px;
      background: ${STATIC_COLORS.base.white};
    `}
`;

export const ProductImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      height: 550px;
    `}
`;

export const TopPromoIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -47px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 20px;
  padding: 3px 0;
  background-color: ${STATIC_COLORS.base.red};
  transform: rotate(-45deg);

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      top: 19px;
      left: -33px;
      height: 39px;
      padding: 10px 0;
    `}

  span {
    text-transform: uppercase;
  }
`;

export const PromoText = styled(Text)`
  color: ${STATIC_COLORS.base.white};

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      font-size: 18px;
      line-height: 28px;
    `}
`;

export const TagsWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-end;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      bottom: 32px;
      left: 32px;
    `}

  img {
    width: 30px;
    height: 30px;

    ${({ theme }) =>
      theme?.isKioskRootElementType &&
      css`
        width: 44px;
        height: 44px;
      `}
  }
`;

export const ImageCtaWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  padding: 14px 0;
  margin-right: 16px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      position: absolute;
      top: 158px;
      left: 32px;
      display: block;
      height: 0;
      padding: 0;
      margin: 0;
    `}
`;

export const ProductContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  padding: 16px 16px 32px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 4px;
      padding: 0 32px 32px 0;
    `}
`;

export const CharacterNameText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  color: ${STATIC_COLORS.green['600']};
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0;

  & > div {
    width: 100%;
    padding: 0;
  }
`;

export const PriceAndFormatWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      height: 30px;
    `}
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const PriceText = styled(Text)`
  white-space: nowrap;
  color: ${STATIC_COLORS.base.black};
`;

export const PriceDashText = styled(Text)`
  text-decoration-line: line-through;
  white-space: nowrap;
  color: ${STATIC_COLORS.gray['400']};
`;

export const CtaWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -6px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 40px;
      margin-top: -4px;
    `}

  a,
  button {
    width: 100%;
  }

  button {
    margin-top: 24px;
  }
`;

export const QrContainer = styled.div`
  display: none;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 24px;
    `}
`;

export const QrText = styled(Text)`
  display: block;
  width: 56px;
`;
