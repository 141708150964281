import { forwardRef } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { parseCharacterDetails } from '@lib/core/characters/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/CharacterDetails/styles';
import CharacterModal from '@components/web/src/templates/Modals/CharacterModal/CharacterModal';

interface IProps {
  locale?: string;
  character?: TCharacter;
  userFirstName: string;
}

const CharacterDetails = forwardRef<HTMLDivElement, IProps>(({ locale, character, userFirstName }, ref) => {
  const { characterName, characterImage, characterDescription, characterProductCategory, characterId } =
    parseCharacterDetails({
      character,
    });

  const {
    productCategories,
    productCard: { ctaGiveItBtn },
    characterDetailMessages: { userCharacterTitleTextB2B, characterTitleTextB2B },
  } = localeCommon;

  const [isCharacterModalOpen, { open: openCharacterModal, close: closeCharacterModal }] = useDisclosure({
    defaultIsOpen: false,
  });

  const titleText = userFirstName ? userCharacterTitleTextB2B : characterTitleTextB2B;

  return (
    <S.CharacterDetailsContainer
      ref={ref}
      $productCategory={characterProductCategory}
      data-testid={`CharacterDetails-${characterProductCategory}`}
    >
      <S.CharacterDetailsWrapper direction="row" gap={0}>
        <S.CharacterImageWrapper align="center" direction="column" gap={0} justify="center">
          <S.CharacterImage alt="character-image" height="155px" src={characterImage} width="85px" />
        </S.CharacterImageWrapper>
        <S.CharacterDetailsContent direction="column" gap={0}>
          <S.ProductSummary align="flex-start" direction="column" gap={0}>
            <Text
              localeOptions={{ productCategory: characterProductCategory, userFirstName }}
              size="body2"
              text={titleText}
              weight="normal"
              localeVariables={{
                productCategoryText: productCategories[characterProductCategory],
              }}
            />
            <Text fontFamily="Fraunces" size="h4" text={`#${characterName}`} weight="semibold" />
          </S.ProductSummary>
          <S.CharacterDescriptionWrapper>
            <Text linesLimit={4} size="body1" text={characterDescription} />
          </S.CharacterDescriptionWrapper>

          <S.CtaButton
            type="button"
            onClick={() => {
              MixpanelTracker.events.characterDescriptionClick({ productCharacterID: characterId });

              openCharacterModal();
            }}
          >
            <Text size="body1" text={ctaGiveItBtn} weight="semibold" />
          </S.CtaButton>
          {isCharacterModalOpen && (
            <CharacterModal
              character={character}
              hideModal={closeCharacterModal}
              isModalOpen={isCharacterModalOpen}
              locale={locale}
            />
          )}
        </S.CharacterDetailsContent>
      </S.CharacterDetailsWrapper>
    </S.CharacterDetailsContainer>
  );
});

export default CharacterDetails;
