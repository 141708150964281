import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CharacterAdjectiveContainer = styled.button`
  all: unset;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: ${STATIC_SHADOWS.md};
`;

export const StyledText = styled(Text)`
  text-wrap: nowrap;
`;

export const AdjectiveText = styled(Text)<{ $productCategory: string }>`
  text-wrap: nowrap;
  ${({ $productCategory }) => `text-shadow:
    -1px -1px 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    0 -1px 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    1px -1px 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    1px 0 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    1px 1px 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    0 1px 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    -1px 1px 0 ${STATIC_COLORS.productColors[$productCategory][500]},
    -1px 0 0 ${STATIC_COLORS.productColors[$productCategory][500]}};`};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  -webkit-text-stroke-color: ${STATIC_COLORS.base.white};
  -webkit-text-stroke-width: medium;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
