import styled, { css } from 'styled-components';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { TProgressBarCardVariant } from '@components/web/src/organisms/Cards/MyTasteProfileCard/ProfileProgressBar/ProfileProgressBar';

export const ProfileProgressBarContainer = styled.div<{ $variant: TProgressBarCardVariant }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  cursor: pointer;

  ${({ $variant }) =>
    $variant === 'detached' &&
    css`
      padding: 12px 16px;
      background: ${STATIC_GRADIENTS.primaryGradient};
      border-radius: 10px;
    `}
`;

export const LeftSectionWrapper = styled.div<{ $variant: TProgressBarCardVariant }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 90%;
  color: $white;

  ${({ $variant }) =>
    $variant === 'detached' &&
    css`
      flex: 1;
    `}

  .mp-progress-bar-container {
    min-width: 100%;
    max-width: 100%;

    .progress-bar {
      max-width: 100%;
    }
  }
`;
