import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useApp } from '@lib/core/service/hooks';
import { useUser } from '@lib/core/users/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { CHARACTERS_URL_PARAM } from '@lib/tools/shared/helpers/consts';

import CharacterDetailsSkeleton from '@components/web/src/components/Skeleton/CharacterDetailsSkeleton/CharacterDetailsSkeleton';
import CharacterDetailsComponent from '@components/web/src/organisms/CharacterDetails/CharacterDetails';
import * as S from '@components/web/src/pages/widget/Catalog/styles';

const CatalogContainerHOC = ({ children }) => {
  const { isFetchingUserData, userFirstName } = useUser();
  const [isScrolled, setIsScrolled] = useState(false);
  const [characterDetailsHeight, setCharacterDetailsHeight] = useState(0);
  const touchStartYRef = useRef(0);
  const characterDetailsRef = useRef<HTMLDivElement>(null);

  const { locale } = useApp();
  const { productCategory } = useApp();

  const [searchParams] = useSearchParams();

  const { characters } = useCharacters();
  const characterStringFromUrl: string | null = searchParams.get(CHARACTERS_URL_PARAM);
  const { userCharactersIds } = useUser();
  const { isFilterOpened } = useCatalogFilter();

  const translatedUserCharacterData = characters
    .filter(character => isCharacterByIdentifiers(character, userCharactersIds))
    .find(character => isCharacterByServiceProductCategory(character));

  const isShowCharacterDetailsSkeleton = isFetchingUserData && !characterStringFromUrl;
  const isShowCharacterDetailsComponent =
    !isFetchingUserData && !isFilterOpened && !characterStringFromUrl && translatedUserCharacterData;

  useEffect(() => {
    const container = document.getElementById('root-scroll-element');

    const handleWheel = event => {
      if (event.deltaY < 0) {
        setIsScrolled(true);
      }
    };

    const handleTouchMove = event => {
      const touchCurrentY = event.touches[0].clientY;
      if (touchCurrentY > touchStartYRef.current) {
        setIsScrolled(true);
      }
    };
    container?.addEventListener('wheel', handleWheel);
    container?.addEventListener('touchmove', handleTouchMove);

    return () => {
      container?.removeEventListener('wheel', handleWheel);
      container?.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (characterDetailsRef.current) {
      setCharacterDetailsHeight(characterDetailsRef.current.clientHeight);
    }
  }, [isScrolled]);

  return (
    <S.CatalogContainerHoc data-testid={`CatalogContainerHOC-${productCategory}`}>
      {isShowCharacterDetailsSkeleton && <CharacterDetailsSkeleton productCategory={productCategory} />}
      {isShowCharacterDetailsComponent && (
        <S.CharacterDetailsWrapper $height={isScrolled ? characterDetailsHeight : 116}>
          <CharacterDetailsComponent
            ref={characterDetailsRef}
            character={translatedUserCharacterData}
            locale={locale}
            userFirstName={userFirstName}
          />
        </S.CharacterDetailsWrapper>
      )}
      {children}
    </S.CatalogContainerHoc>
  );
};

export default CatalogContainerHOC;
