import { FC, Fragment } from 'react';

import { IPreferenceExposure } from '@app/native/src/models/UserFoodPreferences.model';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as QuestionMarkIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_questionmark.svg';
import ProfileSectionHeader from '@components/web/src/atoms/Headers/ProfileSectionHeader/ProfileSectionHeader';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/TasteId/TasteIdTagsPage/styles';

interface IProps {
  onTagsInfoClick: () => void;
  onToggleClick: (optionKey: string) => void;
  tagsPreferencesState: IPreferenceExposure;
  handleBackBtnClick?: () => void;
}

const TasteIdTagsPage: FC<IProps> = ({ tagsPreferencesState, onToggleClick, onTagsInfoClick, handleBackBtnClick }) => {
  const {
    tagsTabContainer: { headingTitle, headingDescription, subHeadingTitle, subHeadingDescription },
  } = localeApp;

  const { back } = localeCommon.commonMessages;

  return (
    <S.TagsTabContainer data-testid="TagsTabContainer">
      <S.GoBackButton arrowSize="short" handleClick={handleBackBtnClick} size="sm" text={back} />
      <ProfileSectionHeader description={headingDescription} title={headingTitle} />
      <Flexbox gap={4} margin="32px 0 16px">
        <Flexbox direction="column" gap={0}>
          <S.TitleText color={STATIC_COLORS.base.black} size="body1" text={subHeadingTitle} weight="semibold" />
          <Text color={STATIC_COLORS.base.black} size="body1" text={subHeadingDescription} />
        </Flexbox>
        <S.Icon onClick={onTagsInfoClick}>
          <QuestionMarkIcon />
        </S.Icon>
      </Flexbox>
      <Flexbox direction="column" gap={20}>
        {Object.keys(tagsPreferencesState)?.map((optionKey, index) => {
          const shouldDisplayDivider = Object.keys(tagsPreferencesState).length === index + 1;
          return (
            <Fragment key={optionKey}>
              {shouldDisplayDivider && <div className="divider" />}
              <ToggleButton
                isToggled={tagsPreferencesState[optionKey].isEnabled}
                text={tagsPreferencesState[optionKey].name}
                onToggle={() => onToggleClick(optionKey as string)}
              />
            </Fragment>
          );
        })}
      </Flexbox>
    </S.TagsTabContainer>
  );
};

export default TasteIdTagsPage;
