import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { feedbackFilter, isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import infoIcon from '@components/web/src/assets/legacy/b2b/icons/info_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import TasteJourneyMap, {
  IJourneyStepsProps,
} from '@components/web/src/components/TastePath/TasteJourneyMap/TasteJourneyMap';
import TastePathEnd from '@components/web/src/components/TastePath/TastePathEnd/TastePathEnd';
import TastePathProductItem from '@components/web/src/components/TastePath/TastePathProductItem/TastePathProductItem';
import Tooltip from '@components/web/src/components/Tooltip/Tooltip';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import * as S from '@components/web/src/pages/widget/TastePathPage/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  locale: string;
  producerName?: string;
  productInstanceData: TProductInstance;
  productCategory: TProductCategory;
  isTastePathProductsLoading: boolean;
  isTastePathDone: boolean;
  tasteJourneyData: IJourneyStepsProps[];
  nextTastePathCategory: string;
  wishlistProductInstanceIds?: string[];
  isWishlistProductListLoading?: boolean;
  feedbackData: IFeedbackData[];
  lastUpdatedFeedbackId: string;
  isEnableLocationMapAddon: boolean;
  isEnableVusionAddon: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isShowProductLocationAddon: boolean;
  shouldHideTastePathTooltipModal: boolean;
  shouldDisplaySkipCategoryBtn: boolean;
  isEnableTastePathAddon: boolean;
  isShowThirdPartyNewsletter: boolean;
  shouldShowDownloadAppCard?: boolean;
  handleThirdPartyNewsletter: (value: boolean) => void;
  navigateToTastePathStartPage: () => void;
  handleSkipTastePathCategory: () => void;
  handleCloseTooltip: () => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleUpdateFeedback: (feedback: TFeedback, productId: string, productName?: string) => void;
  handleAuthFeatureClick: () => void;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

const TastePathPage: FC<IProps> = ({
  producerName,
  locale,
  productInstanceData,
  productCategory,
  isTastePathProductsLoading,
  isTastePathDone,
  tasteJourneyData,
  nextTastePathCategory,
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  feedbackData,
  lastUpdatedFeedbackId,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  shouldHideTastePathTooltipModal,
  shouldDisplaySkipCategoryBtn = false,
  discoveryQuiz,
  isEnableTastePathAddon,
  isShowThirdPartyNewsletter,
  shouldShowDownloadAppCard,
  handleThirdPartyNewsletter,
  navigateToTastePathStartPage,
  handleSkipTastePathCategory,
  handleCloseTooltip,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  handleAuthFeatureClick,
  isCommentsLoaded,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
  commentsList,
}) => {
  const { character } = productInstanceData || {};
  const technicalCaption = character?.techCaption || '';
  const productStyle = character?.type?.identifier || '';

  const { tastePathPage } = localeWidget;

  const { btnText, alreadyTasted, tooltipModalTitle, tooltipModalSubTitle } = tastePathPage;

  const { publishedTerms } = LocaleUtils;

  const buttonText = `${publishedTerms[btnText.id]} ${nextTastePathCategory}`;

  const randomDescriptionText = useMemo(() => {
    if (productStyle && productCategory && productCategory !== PRODUCT_CATEGORY_COFFEE) {
      const randomIndex = Math.floor(Math.random() * 3) + 1;
      return publishedTerms[tastePathPage[`description${productStyle}${productCategory}${randomIndex}`]?.id];
    }
    return '';
  }, [productStyle, productInstanceData, productCategory]);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (nextTastePathCategory) {
      setAnimate(true);
    }
  }, [nextTastePathCategory]);

  const cardAnimation = useSpring({
    config: {
      friction: 30,
      tension: 300,
    },
    onRest: () => setAnimate(false),
    opacity: animate ? 0 : 1,
    transform: `translateY(${animate ? 35 : 0}px)`,
  });

  // without delaying displaying the product we break animation
  const [delayedProduct, setDelayedProduct] = useState(productInstanceData);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (productInstanceData) {
        setDelayedProduct(productInstanceData);
      }
    }, 400);

    return () => clearTimeout(timer);
  }, [productInstanceData]);

  return (
    <>
      <S.TastePathPageContainer direction="column">
        {isTastePathDone ? (
          <TastePathEnd />
        ) : (
          <S.TastePathContent direction="column">
            <Flexbox align="center" justify="space-between">
              <Text
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={tastePathPage.title}
                weight="semibold"
              />

              <S.styledButton onClick={navigateToTastePathStartPage}>
                <Image alt="info icon" src={infoIcon} />
              </S.styledButton>
            </Flexbox>
            {tasteJourneyData && <TasteJourneyMap productCategory={productCategory} steps={tasteJourneyData} />}
            <S.DescriptionSection>
              <Text color={STATIC_COLORS.base.black} size="body1" text={randomDescriptionText} />
              <br />
              <Text
                color={STATIC_COLORS.productColors[productCategory][500]}
                size="body1"
                text={technicalCaption}
                weight="medium"
              />
            </S.DescriptionSection>
            <animated.div style={cardAnimation}>
              <S.ProductCardSection>
                {!shouldHideTastePathTooltipModal && productInstanceData && (
                  <Tooltip
                    className="taste-path-tooltip-modal"
                    description={tooltipModalSubTitle}
                    handleCloseClick={handleCloseTooltip}
                    title={tooltipModalTitle}
                  />
                )}
                <ProductCard
                  isResponsive
                  commentsList={commentsList}
                  discoveryQuiz={discoveryQuiz}
                  feedback={feedbackFilter(feedbackData, delayedProduct)}
                  handleAuthFeatureClick={handleAuthFeatureClick}
                  handleSubmitComment={handleSubmitComment}
                  handleUpdateFeedback={handleUpdateFeedback}
                  handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                  isCommentsLoaded={isCommentsLoaded}
                  isEnableLocationMapAddon={isEnableLocationMapAddon}
                  isEnableTastePathAddon={isEnableTastePathAddon}
                  isEnableVusionAddon={isEnableVusionAddon}
                  isLoading={isTastePathProductsLoading}
                  isNewCommentsDataFetching={isNewCommentsDataFetching}
                  isShowProductLocationAddon={isShowProductLocationAddon}
                  isWishlistProductListLoading={isWishlistProductListLoading}
                  locale={locale}
                  mixpanelPositionContext={MP_POSITION_CONTEXT.TASTE_PATH_PAGE}
                  productInstanceData={delayedProduct}
                  setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
                  storeType="events"
                  isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                    wishlistProductInstanceIds,
                    delayedProduct,
                  )}
                />
              </S.ProductCardSection>
            </animated.div>
            {shouldDisplaySkipCategoryBtn && (
              <Button
                size="sm"
                text={buttonText}
                variant={VH_VARIANTS.LIGHT}
                handleClick={() => {
                  MixpanelTracker.events.skipCharacterType(productStyle);
                  handleSkipTastePathCategory();
                }}
              />
            )}
          </S.TastePathContent>
        )}

        {isShowThirdPartyNewsletter && (
          <ThirdPartyNewsletter
            handleThirdPartyNewsletter={handleThirdPartyNewsletter}
            producerName={producerName}
            withMarginTop={shouldShowDownloadAppCard}
          />
        )}

        {!!feedbackData.length && (
          <S.PathProductsListSection direction="column" gap={8}>
            <Text color={STATIC_COLORS.green['600']} size="body1" text={alreadyTasted} weight="semibold" />

            {feedbackData.map(({ feedback, gprl }, idx) => (
              <TastePathProductItem
                key={idx}
                feedback={feedback}
                handleUpdateFeedback={handleUpdateFeedback}
                isFeedbackModalDisabled={isTastePathDone}
                isUpdatedItem={gprl.product.identifier === lastUpdatedFeedbackId}
                productInstanceData={gprl}
              />
            ))}
          </S.PathProductsListSection>
        )}
      </S.TastePathPageContainer>
    </>
  );
};

export default TastePathPage;
