import styled, { keyframes } from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

const fadeIn = (value: string) => keyframes`
  from {
    opacity: 0;
    transform: translateY(${value});
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ProductsSection = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 0 16px 32px;
  margin-top: -115px;
`;

export const AnimatedWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: 0 auto;
  opacity: 0;
  animation: ${fadeIn('20px')} 1000ms cubic-bezier(0.68, -0.4, 0.36, 1.6) 500ms forwards;

  img {
    height: 100px;
    width: 300px;
    object-fit: contain;
  }
`;

export const ProductCardWrapper = styled.div`
  width: 100%;
`;

export const CenteredText = styled(Text)`
  text-align: center;
`;
