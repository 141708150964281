import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';

import beerHeader from '@components/web/src/assets/legacy/icons/product_card_header_beer.svg';
import coffeeHeader from '@components/web/src/assets/legacy/icons/product_card_header_coffee.svg';
import wineHeader from '@components/web/src/assets/legacy/icons/product_card_header_wine.svg';
import * as S from '@components/web/src/atoms/ProductCardElements/ProductCardTopBanner/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

type IProductCardTopBannerProps = {
  productCategory: TProductCategory;
  characterName?: string;
  technicalCaption?: string;
};

const ProductCardTopBanner: FC<IProductCardTopBannerProps> = ({ productCategory, characterName, technicalCaption }) => {
  const productCategorySketchImageUrl = {
    beer: beerHeader,
    coffee: coffeeHeader,
    wine: wineHeader,
  };

  const headerIconImgUrl = productCategorySketchImageUrl[productCategory];

  return (
    <S.CharacterInfoCard
      $productCategory={productCategory}
      align="center"
      data-testid={`ProductCardTopBanner-${productCategory}-${characterName}`}
      justify="space-between"
    >
      <S.ProductHeader align="flex-start" data-testid="ProductHeader" direction="column" gap={4} justify="center">
        {characterName && (
          <Text
            color={STATIC_COLORS.productColors[productCategory]['200']}
            fontFamily="Fraunces"
            size="h5"
            text={`#${characterName}`}
            weight="semibold"
          />
        )}
        {technicalCaption && (
          <S.TechnicalCaption
            color={STATIC_COLORS.base.white}
            linesLimit={2}
            size="body2"
            text={technicalCaption}
            weight="medium"
          />
        )}
      </S.ProductHeader>
      <S.ProductImage>
        <Image alt="character_image" height="100%" objectFit="contain" src={headerIconImgUrl} width="100%" />
      </S.ProductImage>
    </S.CharacterInfoCard>
  );
};

export default ProductCardTopBanner;
