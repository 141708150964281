import { useEffect, useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const EqualElementsHeightForEach = (elementClassName: string) => {
  const isDesktopResolution = useMediaQuery({ minWidth: 1025 });
  const isTabletResolution = useMediaQuery({ maxWidth: 1024, minWidth: 768 });
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [resizeFlag, setResizeFlag] = useState<boolean>(false);
  const elementClass = `[class~=${elementClassName}]`;
  const elements = document.querySelectorAll<HTMLElement>(elementClass);

  const resizeCallback = () => {
    setMaxHeight(0);
    setResizeFlag(prev => !prev);
  };

  useEffect(() => {
    elements.forEach(element => {
      element.style.minHeight = `${maxHeight}px`;
    });
  }, [maxHeight]);

  useEffect(() => {
    let height = 0;

    elements.forEach(element => {
      const currentHeight = element.clientHeight;
      height = currentHeight && currentHeight > height ? currentHeight : height;
    });

    if (height !== maxHeight && height) {
      setMaxHeight(height);
    }
  }, [elements, resizeFlag]);

  useEffect(() => {
    if (isDesktopResolution || isTabletResolution || isMobileResolution) {
      resizeCallback();
    }
  }, [isDesktopResolution, isMobileResolution, isTabletResolution]);

  useLayoutEffect(() => {
    resizeCallback();
  }, []);
};

export const useEqualElementsHeight = (elementClassNames: string[]) => {
  elementClassNames.forEach(elementClassName => EqualElementsHeightForEach(elementClassName));
};
