import { FC, useMemo } from 'react';

import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import {
  CheckboxAnswer,
  ICheckboxAnswer,
} from '@components/web/src/atoms/Checkboxes&Radio/CheckboxAnswer/CheckboxAnswer';
import BackButton from '@components/web/src/components/Button/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { TastingTip } from '@components/web/src/organisms/Cards/TastingTip/TastingTip';
import * as S from '@components/web/src/templates/TestFlowsPages/GenderPage/styles';

interface IProps {
  genderOptions: ICheckboxAnswer[];
  isDisabledBtn: boolean;
  handleGenderOptionClick: (id: string) => void;
  handleContinueClick: () => void;
  handleSkipClick: () => void;
  handleGoBack: () => void;
}

export const GenderPage: FC<IProps> = ({
  genderOptions,
  isDisabledBtn,
  handleGenderOptionClick,
  handleContinueClick,
  handleSkipClick,
  handleGoBack,
}) => {
  const {
    genderTitle,
    skipBtn,
    continueBtn,
    randomGenderTastingTip1,
    randomGenderTastingTip2,
    randomGenderTastingTip3,
    randomGenderTastingTip4,
    randomGenderTastingTip5,
    randomGenderTastingTip6,
  } = localeWidget.ageAndGenderPages;

  const tastingTipText: ILocaleText = useMemo(
    () =>
      getMultipleUniqueRandomItemsFromArray(
        [
          randomGenderTastingTip1,
          randomGenderTastingTip2,
          randomGenderTastingTip3,
          randomGenderTastingTip4,
          randomGenderTastingTip5,
          randomGenderTastingTip6,
        ],
        1,
      )[0],
    [],
  );

  return (
    <S.GenderPageContainer data-testid="GenderPage">
      <S.Title color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={genderTitle} weight="semibold" />
      <S.GendersWrapper>
        {genderOptions?.map((data, idx) => (
          <CheckboxAnswer key={idx} isResponsive data={data} handleClick={handleGenderOptionClick} />
        ))}
      </S.GendersWrapper>
      <S.CtaWrapper>
        <Button
          disabled={isDisabledBtn}
          fontSize="subtitle2"
          handleClick={handleContinueClick}
          size="md"
          text={continueBtn}
        />
        <Button fontSize="body1" handleClick={handleSkipClick} size="sm" text={skipBtn} variant={VH_VARIANTS.LINK} />
      </S.CtaWrapper>
      <Flexbox direction="column" gap={16}>
        <TastingTip text={tastingTipText} />
        <BackButton handleClick={handleGoBack} size="sm" text={localeCommon.commonMessages.goBackBtn} />
      </Flexbox>
    </S.GenderPageContainer>
  );
};
