import { FC } from 'react';

import { SERVICE_TERMS_CATEGORIES } from '@lib/core/serviceTerms/consts';
import { localeApp } from '@lib/tools/locale/source/web/app';

import { IModalOption } from '@components/web/src/app/Modals/modalTypes';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/ModalOptionItem/styles';

interface IProps {
  data: IModalOption;
  isManageModalVariant?: boolean;
  onToggle?: (option: IModalOption) => void;
  hideToggleText?: boolean;
}

const ModalOptionItem: FC<IProps> = ({ data, isManageModalVariant = false, hideToggleText = false, onToggle }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { is_always_active, is_selected, text, title } = data;
  const checkToggle = is_always_active || is_selected || false;

  const { modalOptionItem } = localeApp;

  const { toggleEnabledText } = modalOptionItem;
  const { toggleDisabledText } = modalOptionItem;
  const { alwaysActiveText } = modalOptionItem;

  const findToggleTextValue = () => {
    if (is_always_active) return alwaysActiveText;
    return checkToggle ? toggleEnabledText : toggleDisabledText;
  };

  return (
    <S.ModalOptionContainer data-testid="ModalOptionItem">
      <Flexbox direction={isManageModalVariant ? 'row' : 'column'} gap={0}>
        {!!title && <Text size="subtitle1" text={title} weight={isManageModalVariant ? 'bold' : 'semibold'} />}
        {data.category === SERVICE_TERMS_CATEGORIES.thirdParty ? null : (
          <S.ToggleWrapper>
            <ToggleButton
              isVariantModalOption
              isDisabled={is_always_active}
              isToggled={checkToggle || is_always_active}
              text={hideToggleText ? null : findToggleTextValue()}
              textPosition="left"
              onToggle={() => onToggle(data)}
            />
          </S.ToggleWrapper>
        )}
      </Flexbox>
      {!!text && <Text size="body2" text={text} />}
    </S.ModalOptionContainer>
  );
};

export default ModalOptionItem;
