import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import checkCircle from '@components/web/src/assets/icons/icon_check_circle_green.svg';
import Button from '@components/web/src/atoms/Buttons/Button';

export type ILegalCardProps = {
  buttonText: ILocaleText;
  cardDescription: ILocaleText;
  cardTitle: ILocaleText;
  onBtnClickHandler?: () => void;
};

const LegalCard: FC<ILegalCardProps> = ({ buttonText, cardDescription, cardTitle, onBtnClickHandler }) => (
  <div className="legal-card-container">
    <div className="content-wrapper">
      <img alt="icon" className="icon" src={checkCircle} />
      <div className="text-wrapper">
        <p className="title">
          <LocaleFragment message={cardTitle} />
        </p>
        <p className="description">
          <LocaleFragment message={cardDescription} />
        </p>
      </div>
    </div>
    <Button size="sm" text={buttonText} onClick={onBtnClickHandler} />
  </div>
);

export default LegalCard;
