import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

const selectComments = (state: RootState) => state?.comments;

const selectCommentsData = createSelector([selectComments], comments => comments.data);

const selectIsCommentsLoading = createSelector([selectComments], comments => comments.isLoading);

const selectIsCommentUpdated = createSelector([selectComments], comments => comments.isCommentUpdate);

const selectIsCommentCreated = createSelector([selectComments], comments => comments.isCommentCreated);

export { selectCommentsData, selectIsCommentCreated, selectIsCommentsLoading, selectIsCommentUpdated };
